/*
  Format / prepare all organization data safely
  Params: {} (obj)(required)
  Return: {} (obj)
*/
import { xConsole } from './xConsole'

const formatAllOrganizationData = (obj: any) => {
  // Default return
  const defaults: { [key: string]: string | number | Array<any> } = {
    id: '',
    organizationName: '',
  }
  // Rules: check types by Object.prototype.toString.call
  interface IRules {
    [key: string]: string
  }
  const rules: IRules = {
    id: '[object String]',
    organizationName: '[object String]',
  }
  try {
    // Convert old Firebase data to new Structure
    if (obj.title) {
      obj.organizationName = obj.title
    }

    // Verify
    Object.entries(obj).forEach(([key, val]: [string, any]) => {
      if (key in defaults && key in rules) {
        if (Object.prototype.toString.call(val) === rules[key]) {
          defaults[key] = val
        }
      }
    })
    return defaults
  } catch (error) {
    xConsole().error(error as Error, 'formatAllOrganizationData.ts (Main)')
    return defaults
  }
}

export default formatAllOrganizationData
