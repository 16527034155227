import { auth } from '../../api/firebase'
import { signInWithCustomToken } from 'firebase/auth'
import { xConsole } from '../../plugins/helpers/xConsole'

import { IApiResponse } from '../../types'

async function login(token: string): Promise<IApiResponse> {
  try {
    await signInWithCustomToken(auth, token)
    return { statusText: 'SUCCESS', status: 200 } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'login.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default login
