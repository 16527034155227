/*
  Get online status
  Params: lastSeen(number)(required), timeOffset(number), customStatus(string)
  Return: status(string)
*/

import { IStatusName } from '../../types'
import { xConsole } from './xConsole'

const getOnlineStatus = (lastSeen: number, timeOffset: number = 0, customStatus?: IStatusName) => {
  let status: IStatusName = 'offline'
  const minLasttimeOnline = 60 * 1000
  try {
    const isOnline = Date.now() + (timeOffset - lastSeen) < minLasttimeOnline
    if (!customStatus || customStatus === 'online') {
      if (isOnline) {
        // Compare date now with lastSeen in 60 secs
        status = 'online'
      }
    } else {
      if (isOnline) {
        status = customStatus
      }
    }
  } catch (error) {
    xConsole().error(error as Error, 'getOnlineStatus.ts (Main)')
  }
  return status
}

export default getOnlineStatus
