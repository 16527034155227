import 'react-native-get-random-values'
import React, { useEffect, useState } from 'react'
import { LogBox, Platform, Text, TextInput } from 'react-native'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import store, { persistor } from './src/store'
import AppNavigator, { navigationRef } from './src/navigation/AppNavigator'
import { NavigationContainer, DefaultTheme } from '@react-navigation/native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import * as Updates from 'expo-updates'
import moment from 'moment'

// Theme
import { useFonts } from 'expo-font'
import { ThemeProvider, createTheme } from '@rneui/themed'
import { StatusBar } from 'expo-status-bar'
import theme from './src/config/themes'

// Firebase
import { auth } from './src/api/firebase'
import { onAuthStateChanged } from 'firebase/auth'
import logout from './src/api/auth/logout'

// Store
import { fetchUser, checkIsHelper } from './src/slices/userSlice'

// Helpers
import {
  requestNotificationPermission,
  onNotificationReceived,
  onNotificationOpenedApp,
  deleteNotificationToken,
} from './src/plugins/helpers/notifications'
import { xConsole } from './src/plugins/helpers/xConsole'

// Components
import DataRefresher from './src/components/DataRefresher'
import ToastMain from './src/components/toasts/Main'
import ToastLoading from './src/components/toasts/Loading'
import ToastNewMessage from './src/components/toasts/NewMessage'
import ToastForceToUpdate from './src/components/toasts/ForceToUpdate'
import ToastMaintenance from './src/components/toasts/Maintenance'

// Toast
import Toast from 'react-native-toast-notifications'

// Ignore logs
LogBox.ignoreLogs([
  'AsyncStorage has been extracted from react-native core',
  'Setting a timer',
  'RCTDevLoadingView',
  'no-image',
])

// Variables
const RNEUItheme = createTheme(theme)

// Disallow font scaling
Text.defaultProps = Text.defaultProps || {}
Text.defaultProps.allowFontScaling = false
TextInput.defaultProps = TextInput.defaultProps || {}
TextInput.defaultProps.allowFontScaling = false

const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: theme.colors.bodybg,
  },
}

const App = () => {
  // Set an initializing state whilst Firebase connects
  const [initializing, setInitializing] = useState(true)

  // Load fonts
  const [fontsLoaded] = useFonts({
    FSJoey: require('./src/assets/fonts/FSJoey-Regular.otf'),
    FSJoeyMedium: require('./src/assets/fonts/FSJoey-Medium.otf'),
    FSJoeyBold: require('./src/assets/fonts/FSJoey-Bold.otf'),
  })

  useEffect(() => {
    const subscriber = onAuthStateChanged(auth, async (user) => {
      if (user) {
        await Promise.all([xConsole().setUserId(user.uid)])
        await store.dispatch<any>(fetchUser()) // Get current user data
        store.dispatch<any>(checkIsHelper())
        requestNotificationPermission(user.uid)
      } else {
        await logout(store.getState)
        await deleteNotificationToken()
        store.dispatch({ type: 'CLEAR_ALL' })
      }
      if (initializing) setInitializing(false)
    })
    return subscriber // unsubscribe on unmount
  }, [])

  useEffect(() => {
    const updateApp = async () => {
      const { isAvailable } = await Updates.checkForUpdateAsync()
      if (isAvailable) {
        toast.show('Starter på nytt etter at oppdateringen er fullført…', {
          type: 'main',
          data: {
            title: 'Ny oppdatering!',
            icon: 'success',
          },
        })
        await Updates.fetchUpdateAsync()
        setTimeout(async () => {
          await Updates.reloadAsync()
        }, 3000)
      }
    }
    setTimeout(async () => {
      if (!__DEV__ && Platform.OS !== 'web') {
        updateApp()
      }
    }, 3000)
  }, [])

  useEffect(() => {
    let interval: any
    try {
      interval = setInterval(async () => {
        if (typeof toast !== 'undefined') {
          toast.show('', {
            type: 'forceToUpdate',
            swipeEnabled: false,
            animationDuration: 0,
            duration: 1000000000000,
          })
          clearInterval(interval)
        }
      }, 500)
    } catch (error) {}
  }, [])

  /*
  useEffect(() => {
    let interval: any
    try {
      if (moment.duration(moment().diff(moment('2022-12-09 12:00'))).asMinutes() <= 0) {
        return
      }
      interval = setInterval(async () => {
        if (typeof toast !== 'undefined') {
          toast.show('', {
            type: 'maintenance',
            duration: 30000,
          })
          clearInterval(interval)
        }
      }, 500)
    } catch (error) {}
  }, [])
  */

  // In-App Notifiactions
  useEffect(() => {
    return onNotificationReceived()
  }, [])
  // Notifications (outside)
  useEffect(() => {
    return onNotificationOpenedApp(navigationRef, store)
  }, [])

  if (initializing || !fontsLoaded) {
    return null
  } // Wait for Auth init and fonts load

  return (
    <Provider {...{ store }}>
      <PersistGate loading={null} {...{ persistor }}>
        <ThemeProvider theme={RNEUItheme}>
          <ActionSheetProvider>
            <SafeAreaProvider>
              <NavigationContainer theme={navigationTheme} ref={navigationRef}>
                <AppNavigator />
                <StatusBar style="dark" />
                <DataRefresher navigation={navigationRef} />
              </NavigationContainer>
            </SafeAreaProvider>
          </ActionSheetProvider>
        </ThemeProvider>
      </PersistGate>
      <Toast
        ref={(ref) => ((global as any)['toast'] = ref)}
        placement="top"
        offset={40}
        normalColor={'rgba(40,40,40,0.85)'}
        textStyle={{ fontFamily: 'FSJoey', fontSize: 14, lineHeight: 14 }}
        animationType="zoom-in"
        swipeEnabled={Platform.OS !== 'android'}
        renderType={{
          main: (toast) => <ToastMain toast={toast} />,
          loading: (toast) => <ToastLoading toast={toast} />,
          newMessage: (data) => <ToastNewMessage data={data} navigation={navigationRef} />,
          maintenance: (toast) => <ToastMaintenance toast={toast} />,
          forceToUpdate: (toast) => <ToastForceToUpdate toast={toast} />,
        }}
      ></Toast>
    </Provider>
  )
}

export default App
