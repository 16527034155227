import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Input, Button } from '@rneui/themed'
import { merge } from 'lodash'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { fetchUser } from '../../slices/userSlice'

// Firebase
import { usersCol } from '../../api/firebase'
import { doc, setDoc } from 'firebase/firestore'

// Navigation
import { useNavigation } from '@react-navigation/native'

// Components
import CopyDataButton from '../../components/CopyDataButton'

// Helpers
import { xConsole } from '../../plugins/helpers/xConsole'

// Themes
import theme from '../../config/themes'

// Types
import { IUser } from '../../types'
type SettingsEditInfoProps = {
  newAvatarUrl?: string
}

const SettingsEditInfo = ({ newAvatarUrl }: SettingsEditInfoProps) => {
  const { user, isHelper } = useSelector((state: RootState) => state.user)
  if (!user) {
    return null
  }

  const dispatch = useDispatch()
  const navigation = useNavigation()
  const [userForm, setUserForm] = useState<IUser | null>(null)
  const [isAllowed, setIsAllowed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [validations, setValidations] = useState<Record<string, any>>({
    fullName: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
    title: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
    description: {
      regex: '^.{1,200}$',
      errorMessage: 'Beskrivelsen må være mellom 1 og 200 tegn.',
      isValid: true,
    },
    photoURL: {
      regex: '^.{2,}$',
      errorMessage: 'Feltet er obligatorisk.',
      isValid: true,
    },
  })

  useEffect(() => {
    setUserForm(user)
  }, [])

  useEffect(() => {
    onChangeText('photoURL', newAvatarUrl ? newAvatarUrl : user.photoURL)
  }, [newAvatarUrl])

  const onChangeText = <T extends keyof IUser>(k: T, v: IUser[T]) => {
    if (!userForm) {
      return
    }
    const result: IUser = { ...userForm, ...{ [k]: v } }
    setUserForm(result)

    if (validations[k] !== undefined && typeof v === 'string') {
      const validation = { ...validations[k] }
      const regex = new RegExp(validation.regex)
      const isValid = regex.test(v)
      setValidations(merge(validations, { [k]: { isValid } }))
    }
    setIsAllowed(JSON.stringify(user) !== JSON.stringify(result)) // Compare prev data and form data
  }

  const submit = async () => {
    try {
      const isValid = !Object.values(validations).some((v) => !v.isValid)
      if (isValid && userForm) {
        setIsLoading(true)
        const docRef = doc(usersCol, user.id)
        await setDoc(
          docRef,
          {
            displayName: userForm.fullName,
            subtitle: userForm.title,
            description: userForm.description,
            photoURL: userForm.photoURL,
          },
          { merge: true }
        )
        dispatch(fetchUser())
        setIsAllowed(false)
        navigation.goBack()
        toast.show('Nye profil er lagret.')
      }
    } catch (error) {
      toast.show('Beklager, noe gikk galt.')
      xConsole().error(error as Error, 'EditInfo.tsx (submit)')
    }
    setIsLoading(false)
  }

  const renderLabel = (title: string) => {
    return <Text style={styles.inputLabel}>{title}</Text>
  }

  const showExtras = () => {
    return (!!user.accessLevel && user.accessLevel >= 7) || isHelper
  }

  return (
    <View>
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        errorStyle={styles.inputErrorText}
        label={renderLabel('Navn')}
        value={userForm?.fullName}
        onChangeText={(v) => onChangeText('fullName', v)}
        errorMessage={!validations.fullName.isValid ? validations.fullName.errorMessage : ''}
      />
      {showExtras() && (
        <Input
          containerStyle={styles.mainInputContainer}
          inputContainerStyle={styles.inputContainer}
          inputStyle={styles.input}
          errorStyle={styles.inputErrorText}
          label={renderLabel('Arbeidstittel')}
          value={userForm?.title}
          onChangeText={(v) => onChangeText('title', v)}
          errorMessage={!validations.title.isValid ? validations.title.errorMessage : ''}
        />
      )}
      <Input
        containerStyle={styles.mainInputContainer}
        inputContainerStyle={styles.inputContainer}
        inputStyle={styles.input}
        label={renderLabel('Mobilnummer')}
        value={userForm?.phoneNumber}
        disabled
      />
      {showExtras() && (
        <Input
          containerStyle={styles.mainInputContainer}
          inputContainerStyle={[styles.inputContainer, { paddingBottom: 10 }]}
          inputStyle={[styles.input, { fontSize: 18 }]}
          errorStyle={styles.inputErrorText}
          label={renderLabel('Beskrivelse')}
          multiline={true}
          value={userForm?.description}
          placeholder={'Skriv en kort tekst om hvem du er og hva du kan hjelpe til med.'}
          onChangeText={(v) => onChangeText('description', v.replace(/(\r\n|\n|\r)/gm, ''))}
          errorMessage={
            !validations.description.isValid ? validations.description.errorMessage : ''
          }
        />
      )}
      <CopyDataButton data={user} containerStyle={{ marginTop: -10 }} />
      {isAllowed && (
        <Button
          title="LAGRE PROFIL/ INNSTILLINGER"
          containerStyle={styles.buttonContainer}
          buttonStyle={styles.button}
          titleStyle={styles.buttonTitle}
          disabled={!isAllowed}
          loading={isLoading}
          onPress={submit}
        />
      )}
    </View>
  )
}

export default SettingsEditInfo

const styles = StyleSheet.create({
  mainInputContainer: {
    paddingHorizontal: 0,
  },
  inputLabel: {
    fontFamily: 'FSJoeyMedium',
  },
  inputContainer: {
    borderColor: theme.colors.light,
  },
  input: {
    fontSize: 18,
    minHeight: 35,
  },
  inputErrorText: {
    color: theme.colors.primary,
    fontSize: 9,
    marginLeft: 0,
    height: 10,
  },
  buttonContainer: {
    marginTop: 50,
    marginBottom: 15,
  },
  button: {
    backgroundColor: theme.colors.primary,
  },
  buttonTitle: {
    color: theme.colors.white,
  },
})
