import getApiOrganizations from '../api/organizations/getApiOrganizations'
import getApiOtherOrganizations from '../api/organizations/getApiOtherOrganizations'
import getApiAllOrganizations from '../api/organizations/getApiAllOrganizations'
import { xConsole } from '../plugins/helpers/xConsole'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import {
  IOrganizationsState,
  IOrganizationHeader,
  IOrganizationUser,
  IOrganizationAll,
  IUserState,
} from '../types'

const initialState: IOrganizationsState = {
  organizations: [],
  indices: [],
  isLoading: false,
  loadingPercentage: 0,
  error: false,
  other: {
    organizations: [],
    isLoading: false,
    error: false,
  },
  all: {
    organizations: [],
    indices: [],
    isLoading: false,
    error: false,
  },
}

const organizations = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    // Current user organizations
    getOrganizations(state) {
      state.isLoading = true
      state.loadingPercentage = 0
      state.error = false
    },
    getOrganizationsSuccess(
      state,
      action: PayloadAction<{ organizations: Array<IOrganizationHeader | IOrganizationUser> }>
    ) {
      const payload = action.payload
      state.isLoading = false
      state.loadingPercentage = 100
      state.organizations = payload.organizations
      state.indices = state.organizations.reduce(
        (a: any, e: any, i: number) => (e.type === 'header' && a.push(i), a),
        []
      )
    },
    getOrganizationsFailure(state) {
      state.isLoading = false
      state.loadingPercentage = 100
      state.error = true
    },
    setOrganizationsLoadingPercentage(state, action: PayloadAction<number>) {
      const payload = action.payload
      state.loadingPercentage = ~~payload
    },

    // Other organizations
    getOtherOrganizations(state) {
      state.other.isLoading = true
      state.other.error = false
    },
    getOtherOrganizationsSuccess(
      state,
      action: PayloadAction<{ organizations: Array<IOrganizationHeader | IOrganizationUser> }>
    ) {
      const payload = action.payload
      state.other.isLoading = false
      state.other.organizations = payload.organizations
    },
    getOtherOrganizationsFailure(state) {
      state.other.isLoading = false
      state.other.error = true
    },

    // All organizations (for selector)
    getAllOrganizations(state) {
      state.all.isLoading = true
      state.all.error = false
    },
    getAllOrganizationsSuccess(
      state,
      action: PayloadAction<{ organizations: Array<IOrganizationHeader | IOrganizationAll> }>
    ) {
      const payload = action.payload
      state.all.isLoading = false
      state.all.organizations = payload.organizations
      state.all.indices = state.all.organizations.reduce(
        (a: any, e: any, i: number) => (e.type === 'header' && a.push(i), a),
        []
      )
    },
    getAllOrganizationsFailure(state) {
      state.all.isLoading = false
      state.all.error = true
    },
  },
})

export const {
  getOrganizations,
  getOrganizationsSuccess,
  getOrganizationsFailure,
  setOrganizationsLoadingPercentage,
  getOtherOrganizations,
  getOtherOrganizationsSuccess,
  getOtherOrganizationsFailure,
  getAllOrganizations,
  getAllOrganizationsSuccess,
  getAllOrganizationsFailure,
} = organizations.actions

export default organizations.reducer

export const fetchOrganizations = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user: userState }: IUserState = getState().user
    dispatch(getOrganizations())
    if (userState?.organizations) {
      const res = await getApiOrganizations({
        userOrganizations: userState.organizations,
        updateLoadingPercentage: (percent: number) => {
          dispatch(setOrganizationsLoadingPercentage(percent))
        },
      })
      dispatch(getOrganizationsSuccess({ organizations: res.data }))
    }
  } catch (error) {
    dispatch(getOrganizationsFailure())
    xConsole().error(error as Error, 'organizationsSlice.ts (fetchOrganizations)')
  }
}

export const fetchOtherOrganizations = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getOtherOrganizations())
    const res = await getApiOtherOrganizations()
    dispatch(getOtherOrganizationsSuccess({ organizations: res.data }))
  } catch (error) {
    dispatch(getOtherOrganizationsFailure())
    xConsole().error(error as Error, 'organizationsSlice.ts (fetchOtherOrganizations)')
  }
}

export const fetchAllOrganizations = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getAllOrganizations())
    const res = await getApiAllOrganizations()
    dispatch(getAllOrganizationsSuccess({ organizations: res.data }))
  } catch (error) {
    dispatch(getAllOrganizationsFailure())
    xConsole().error(error as Error, 'organizationsSlice.ts (fetchAllOrganizations)')
  }
}
