import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Keyboard,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

// Store
import store from '../store'
import { useDispatch } from 'react-redux'

// Api
import logout from '../api/auth/logout'

// Themes
import theme from '../config/themes'

// Components
import Title from '../components/login/Title'
import StatusMessage from '../components/login/StatusMessage'
import BackButton from '../components/login/BackButton'
import NameForm from '../components/login/NameForm'
import OrganizationsForm from '../components/login/OrganizationsForm'

const headerTexts = [
  {
    title: '',
    subtitle: '',
  },
  {
    title: 'Hva heter du?',
    subtitle: 'Fyll inn navnet ditt',
  },
  {
    title: 'Topp!\nDu er nesten klar!',
    subtitle: 'Velg hvilke skoler og tjenester du vil ha i din app',
  },
]

const OnBoardingScreen = () => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1) // Step (current step 1 = name, 2 = organizations)
  const [title, setTitle] = useState(headerTexts[1].title)
  const [subtitle, setSubtitle] = useState(headerTexts[1].subtitle)

  // @TODO: Go back (Reset)
  const goBack = async () => {
    let prevStep = Math.min(Math.max(step - 1, 0), 3)
    if (prevStep === 0) {
      // Logout
      await logout(store.getState)
      dispatch({ type: 'CLEAR_ALL' })
    }

    setStep(prevStep)
    setTitle(headerTexts[prevStep].title)
    setSubtitle(headerTexts[prevStep].subtitle)
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          Platform.OS !== 'web' ? Keyboard.dismiss : null
        }}
      >
        <View style={styles.mainContainer}>
          <View style={styles.contentContainer}>
            <SafeAreaView style={{ flex: 0.5, paddingHorizontal: 20 }}>
              <View style={{ flex: 0.3 }}>
                <BackButton onGoBack={goBack} />
              </View>
              <View style={{ flex: 0.7, justifyContent: 'flex-end' }}>
                <Title>{title}</Title>
                <StatusMessage message={subtitle} />
              </View>
            </SafeAreaView>
            <View style={{ flex: 0.5, paddingHorizontal: 20 }}>
              {step === 1 && (
                <NameForm
                  onSuccess={() => {
                    setStep(2)
                    setTitle(headerTexts[2].title)
                    setSubtitle(headerTexts[2].subtitle)
                  }}
                />
              )}
              {step === 2 && <OrganizationsForm />}
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  )
}

export default OnBoardingScreen

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: theme.colors.bluelight,
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
