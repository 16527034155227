import { ScrollView, View } from 'react-native'
import { Text } from '@rneui/themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import TabMenus from '../components/TabMenus'

// Components
import ScreenTitle from '../components/ScreenTitle'
import FAQList from '../components/faq/List'
import CanteenWidget from '../components/faq/widgets/Canteen'

let FAQScreen = () => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScrollView>
        <ScreenTitle title="Hva lurer du på?" />
        <Text style={{ marginHorizontal: 20, fontSize: 20 }}>
          Her ligger det svar på mange spørsmål. Kanskje du finner svar på ditt?
        </Text>
        <CanteenWidget />
        <FAQList />
      </ScrollView>
      <TabMenus />
    </SafeAreaView>
  )
}

export default FAQScreen
