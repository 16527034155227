import Crypto from 'crypto-js'
import { xConsole } from './xConsole'

// Types
interface IEncryptMessageParams {
  content: string
  key: string
}
type EncryptedMessage = {
  ct: string
  iv: string
}

/*
  Encrypt message
  Params: { content(string)(required), key(string)(required) }
  Return: {ct: '', iv: ''}(obj)
*/
const encryptMessage = (args: IEncryptMessageParams): EncryptedMessage | null => {
  let result: EncryptedMessage | null = null
  try {
    const encrypted = Crypto.AES.encrypt(args.content, Crypto.enc.Hex.parse(args.key), {
      iv: Crypto.lib.WordArray.random(128 / 8),
    })
    result = {
      ct: encrypted.ciphertext.toString(Crypto.enc.Base64),
      iv: encrypted.iv.toString(),
    }
    return result
  } catch (error) {
    xConsole().error(error as Error, 'encryptMessage.ts (Main)')
    return result
  }
}

export default encryptMessage
