import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Platform, View, RefreshControl } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { debounce } from 'lodash'
import { xConsole } from '../../../plugins/helpers/xConsole'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store/rootReducer'
import { fetchOrganizations } from '../../../slices/organizationsSlice'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import SearchContacts from './Search'
import ContactItem from './Item'
import ContactProfileOverlay from './ProfileOverlay'
import Loading from '../../Loading'
import EmptyOrganization from '../../EmptyOrganization'

// Theme
import theme from '../../../config/themes'

// Types
import { IOrganizationUser, RootNavigatorParamList, IContactOverlayRef } from '../../../types'
import { Routes } from '../../../config/routes'
type ListContactsProps = {
  isWhiteBg?: boolean
}

const ListContacts = ({ isWhiteBg = false }: ListContactsProps) => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const ContactOverlayRef = useRef<IContactOverlayRef>(null)

  // State: User
  const { user } = useSelector((state: RootState) => state.user)

  // State: Organizations
  const {
    organizations,
    indices,
    isLoading: isApiOrganizationsLoading,
    loadingPercentage: apiOrganizationsLoadingPercentage,
  } = useSelector((state: RootState) => state.organizations)
  const [organizationsFiltered, setOrganizationsFiltered] = useState<IOrganizationUser[]>([])

  const updateOrganizations = () => {
    dispatch(fetchOrganizations())
  }

  useEffect(() => {
    updateOrganizations()
  }, [user?.organizations])

  const openContact = (data: IOrganizationUser) => {
    if (Platform.OS !== 'web') {
      ContactOverlayRef.current?.setIsActive(true, data)
    } else {
      navigation.navigate(Routes.ContactProfile, { item: data })
    }
  }

  const renderItem = useCallback(
    ({ item }) => (
      <ContactItem
        item={item}
        onPress={() => openContact(item)}
        headerBgColor={isWhiteBg ? theme.colors.white : ''}
        placeholderColor={isWhiteBg ? '#ffffff' : '#f2efea'}
        itemListContainerBgColor={isWhiteBg ? '#f7f7f7' : ''}
      />
    ),
    []
  )

  const onSearch = useCallback(
    debounce((s: string) => {
      try {
        if (s.length) {
          const result = organizations.filter(
            (a) =>
              (!!a.fullName && a.fullName.toLowerCase().includes(s.toLowerCase())) ||
              a.type === 'header'
          )
          setOrganizationsFiltered(result)
        } else {
          setOrganizationsFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'contacts/user/List.tsx (onSearch)')
      }
    }, 1000),
    [organizations]
  )

  return (
    <>
      <SearchContacts
        onSearch={(s: string) => {
          onSearch(s)
        }}
        inputColor={isWhiteBg ? '#f7f7f7' : ''}
      />
      {organizations && organizations.length ? (
        <View style={{ flex: 1 }}>
          <FlatList
            data={organizationsFiltered.length ? organizationsFiltered : organizations}
            keyExtractor={(item: any, index: any) => index}
            renderItem={renderItem}
            stickyHeaderIndices={indices}
            refreshing={isApiOrganizationsLoading}
            refreshControl={
              <RefreshControl
                refreshing={isApiOrganizationsLoading}
                onRefresh={updateOrganizations}
              />
            }
            style={{ minHeight: 1 }} // Hacks scroll height problem on Web
            contentContainerStyle={{ paddingBottom: 65 }}
          />
        </View>
      ) : (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
          {isApiOrganizationsLoading && (
            <Loading
              text={`Laster inn kontaker (${apiOrganizationsLoadingPercentage}%)`}
              containerStyle={{ marginTop: -100 }}
            />
          )}
          {!isApiOrganizationsLoading && !user?.organizations.length && (
            <EmptyOrganization containerStyle={{ marginTop: -85 }} />
          )}
        </View>
      )}

      {Platform.OS !== 'web' && <ContactProfileOverlay ref={ContactOverlayRef} />}
    </>
  )
}

export default ListContacts
