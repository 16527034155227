import formatUserData from '../../plugins/helpers/formatUserData'
import getOnlineStatus from '../../plugins/helpers/getOnlineStatus'
import getHexColorFromStatusName from '../../plugins/helpers/getHexColorFromStatusName'
import { chunk } from 'lodash'
import { xConsole } from '../../plugins/helpers/xConsole'

// Firebase
import { usersCol } from '../../api/firebase'
import { getDocs, query, where } from 'firebase/firestore'

// Types
import { IApiResponse, IStatusName } from '../../types'

/**
 * Gets users by ids
 */
async function getApiUsers(ids: Array<String> = []): Promise<IApiResponse> {
  try {
    const idsChunk = chunk(ids, 10)
    const users: { [key: string]: any } = {}
    for (const userIds of idsChunk) {
      const q = query(usersCol, where('uid', 'in', userIds)) // @TODO where uid and id
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        const docData = doc.data()
        const userData = formatUserData(docData)
        userData.status = getOnlineStatus(userData.lastSeen as number)
        userData.statusColor = getHexColorFromStatusName(userData.status as IStatusName)
        if (userData.id) {
          users[userData.id as string] = userData
        }
      })
    }
    return { statusText: 'SUCCESS', status: 200, data: users } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'getApiUsers.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default getApiUsers
