import formatUserData from '../../plugins/helpers/formatUserData'
import getOnlineStatus from '../../plugins/helpers/getOnlineStatus'
import getHexColorFromStatusName from '../../plugins/helpers/getHexColorFromStatusName'
import { xConsole } from '../../plugins/helpers/xConsole'

// Firebase
import { auth, usersCol } from '../../api/firebase'
import { doc, getDoc } from 'firebase/firestore'

// Types
import { IApiResponse, IStatusName } from '../../types'

/**
 * Gets currently logged in user.
 */
async function getApiUser(): Promise<IApiResponse> {
  try {
    const currentUser = auth.currentUser
    if (!currentUser) {
      return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
    }
    const docRef = doc(usersCol, currentUser.uid)
    const docSnap = await getDoc(docRef)
    let user = formatUserData(currentUser) // Default user data by Firebase Auth
    if (docSnap.exists()) {
      const docData = docSnap.data()
      user = formatUserData(docData)
      user.status = getOnlineStatus(user.lastSeen as number, 0, user.customStatus as IStatusName)
      user.statusColor = getHexColorFromStatusName(user.status as IStatusName)
      // accessLevel (admins)
      if (docData.accessLevel && !!Number(docData.accessLevel)) {
        user.accessLevel = Number(docData.accessLevel)
      }
      // hasPassword (TwoFactor)
      await currentUser.getIdTokenResult().then((idTokenResult) => {
        const claims = idTokenResult.claims
        user.hasPassword = !!claims.password
      })
      // Delete account
      if (docData.deleteAccount && !!Number(docData.deleteAccount)) {
        user.deleteAccount = Number(docData.deleteAccount)
      }
      if (!user.id) {
        return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
      }
    } else {
      return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
    }
    return { statusText: 'SUCCESS', status: 200, data: user } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'getApiUser.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default getApiUser
