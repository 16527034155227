import { useState, useCallback } from 'react'
import * as Updates from 'expo-updates'
import { Button } from '@rneui/themed'
import * as Application from 'expo-application'

// Themes
import theme from '../../config/themes'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { toggleDevMode } from '../../slices/userSlice'

const SettingsDevmode = () => {
  const dispatch = useDispatch()
  const { isDevMode } = useSelector((state: RootState) => state.user)
  const version =
    Application && Application.nativeApplicationVersion
      ? `v${Application.nativeApplicationVersion}`
      : 'Kopling'

  const [lastEnableDevmodePressed, setLastEnableDevmodePressed] = useState(0)
  const [enableDevmodePressedTotal, setEnableDevmodePressedTotal] = useState(0)
  const onPressVersion = useCallback(() => {
    const time = new Date().getTime()
    if (time - lastEnableDevmodePressed > 1000) {
      setEnableDevmodePressedTotal(0)
    } else {
      setEnableDevmodePressedTotal(enableDevmodePressedTotal + 1)
    }
    if (enableDevmodePressedTotal >= 10) {
      dispatch(toggleDevMode())
      setEnableDevmodePressedTotal(0)
    }
    setLastEnableDevmodePressed(time)
  }, [lastEnableDevmodePressed])
  return (
    <>
      {isDevMode && (
        <Button
          containerStyle={{
            width: 100,
          }}
          titleStyle={{ color: theme.colors.primary }}
          title={'Clear data'}
          type="clear"
          onPress={async () => {
            dispatch({ type: 'CLEAR_ALL' })
            setTimeout(() => {
              Updates.reloadAsync()
            }, 1000)
          }}
        />
      )}
      <Button
        containerStyle={{
          width: 150,
          marginLeft: 'auto',
          alignItems: 'flex-end',
        }}
        titleStyle={{ color: theme.colors.grey1 }}
        title={version}
        type="clear"
        onPress={onPressVersion}
        activeOpacity={0.8}
      />
    </>
  )
}

export default SettingsDevmode
