/*
  Get Hex color (theme) from status name
  Params: 'online' | 'busy' | 'offline' | 'invisible' (string)(required)
  Return: string
*/

import theme from '../../config/themes'
import { IStatusName } from '../../types'
import { xConsole } from './xConsole'

const getHexColorFromStatusName = (statusName: IStatusName) => {
  try {
    return (
      {
        online: '#10ce00',
        busy: theme.colors?.error,
        offline: '',
        invisible: '',
      }[statusName] || ''
    )
  } catch (error) {
    xConsole().error(error as Error, 'getHexColorFromStatusName.ts (Main)')
    return ''
  }
}

export default getHexColorFromStatusName
