import React, { useRef } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Theme
import theme from '../../config/themes'

// Components
import SubmitButton from './SubmitButton'
import OrganizationSelector from '../organizations/selector'

// Types
import { IOrganizationSelectorRef, RootNavigatorParamList } from '../../types'
import { Routes } from '../../config/routes'
type OrganizationsFormProps = {}

// Variables
const { height } = Dimensions.get('window')

function OrganizationsForm({}: OrganizationsFormProps) {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const { user } = useSelector((state: RootState) => state.user)

  const organizationSelectorRef = useRef<IOrganizationSelectorRef>(null)

  const openSelector = () => {
    if (!user || !organizationSelectorRef.current) {
      return
    }
    organizationSelectorRef.current.setIsActive(true)
  }

  return (
    <>
      <View>
        <SubmitButton
          title="SKOLER / TJENESTER"
          onPress={openSelector}
          loading={false}
          valid={!(user && user.organizations.length)}
        />
      </View>
      {!!(user && user.organizations.length) && (
        <View>
          <SubmitButton
            title="DU ER KLAR - SPØR OM HVA DU VIL!"
            onPress={() => {
              navigation.navigate(Routes.FAQ)
            }}
            loading={false}
            valid={true}
          />
        </View>
      )}
      <OrganizationSelector ref={organizationSelectorRef} bottomSheetTopInset={-(height * 0.5)} />
    </>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    padding: 10,
    borderWidth: 1,
    borderColor: theme.colors.black,
    borderRadius: 10,
  },
})

export default OrganizationsForm
