import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Button, ListItem } from '@rneui/themed'

// Helpers
import checkUsersAlreadyConnected from '../../../plugins/helpers/checkUsersAlreadyConnected'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootReducer'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import CopyDataButton from '../../../components/CopyDataButton'
import Avatar from '../../Avatar'

// Theme
import theme from '../../../config/themes'

// Types
import { IOrganizationUser, RootNavigatorParamList } from '../../../types'
import { Routes } from '../../../config/routes'

type ContactProps = {
  item: IOrganizationUser
}

const ContactProfile = ({ item }: ContactProps) => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const { user } = useSelector((state: RootState) => state.user)
  const { channels } = useSelector((state: RootState) => state.channels)

  /* ========= Start chat ======== */
  const startChat = (isAnonymous: boolean) => {
    const channelExists = checkUsersAlreadyConnected(item.id, isAnonymous, channels)
    if (channelExists) {
      // Go to old channel already exists
      navigation.navigate(Routes.Chat, {
        item: {
          id: channelExists,
          title: item.fullName,
          description: item.title,
          photoURL: item.photoURL,
          statusColor: item.statusColor,
          isAnonymous: isAnonymous,
        },
      })
    } else {
      // Go to new channel for create new one
      navigation.navigate(Routes.Chat, {
        item: {
          title: item.fullName,
          description: item.title,
          photoURL: item.photoURL,
          statusColor: item.statusColor,
          isAnonymous: isAnonymous,
        },
        create: item.id,
        reference: { type: 'contact-page', organizationId: item.organizationId },
      })
    }
  }
  return (
    <View style={styles.mainContainer}>
      <View>
        <ListItem containerStyle={styles.itemListContainer}>
          <Avatar
            photoURL={item.photoURL}
            placeholderTitle={item.fullName}
            statusColor={item.statusColor}
          />
          <ListItem.Content style={styles.itemListContent}>
            <ListItem.Title>{item.fullName}</ListItem.Title>
            <ListItem.Subtitle>
              {item.title} <CopyDataButton data={item} />
            </ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>
        <Text style={styles.description} numberOfLines={4}>
          {item.description}
        </Text>
      </View>
      {user?.id !== item.id && (
        <View style={{ marginBottom: 40 }}>
          <Button
            title={'START DIALOG SOM ANONYM'}
            containerStyle={styles.buttonContainer}
            buttonStyle={[theme._.ButtonStyle.Large, { backgroundColor: theme.colors.black }]}
            onPress={() => {
              startChat(true)
            }}
          />
          <Button
            title={'START DIALOG'}
            containerStyle={styles.buttonContainer}
            buttonStyle={[theme._.ButtonStyle.Large]}
            onPress={() => {
              startChat(false)
            }}
          />
        </View>
      )}
    </View>
  )
}

export default ContactProfile

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  itemListContainer: {
    justifyContent: 'center',
    paddingTop: 0,
  },
  itemListContent: {
    flex: 0,
    minWidth: 150,
  },
  description: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  buttonContainer: {
    marginVertical: 5,
  },
})
