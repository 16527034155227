import { Platform } from 'react-native'

export const apiUrl = 'https://jsonplaceholder.typicode.com'

export const firebaseConfig = {
  apiKey: 'AIzaSyBkWqBSrZstMwsOlYX-2TLtZZwoiDjKB8I',
  authDomain: 'elevtjenesten-app.firebaseapp.com',
  databaseURL: 'https://elevtjenesten-app.firebaseio.com',
  projectId: 'elevtjenesten-app',
  storageBucket: 'elevtjenesten-app.appspot.com',
  messagingSenderId: '108716078054',
  appId: '1:108716078054:web:70df67a2724f0776b25c27',
  measurementId: 'G-4QCZVX5KY4',
}

export const firebaseApiUrl =
  __DEV__ && Platform.OS === 'web'
    ? '/firebaseapi'
    : 'https://elevtjenesten-app.firebaseapp.com/api'

export const channelSalt =
  'eaab7a5535304e5d2b05474a6620daeda5ee74c602eed377659f17d7600a27f4052207f1af6f47d9c88d5ccdd952b8848c7113062267c8dfc1da75265b5b65817bcdf0a53333c7c4abcc4fe5525f3ec1b54acbeb6497b6068bd7feb90246e4308cedc3e98534d7f36d805b1cdc6ae21bc6b408a9edd673f2d7ebf0527061e70d'
