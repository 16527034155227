/*
  Get channel title and avatar
  Params: { users(object)(required), currentUserId(string)(required) }
  Return: { title(string), photoURL(string) }(object)
*/

import getApiUsers from '../../api/users/getApiUsers'
import getHexColorFromStatusName from './getHexColorFromStatusName'
import getOnlineStatus from './getOnlineStatus'
import { xConsole } from './xConsole'

// Types
import { IUser, IStatusName } from '../../types'
interface IGetChannelUsersParams {
  users: object
  currentUserId: string
  prevUsers: Record<string, IUser>
  isAnonymous: boolean
  timeOffset?: number
}

const getChannelUsers = async (args: IGetChannelUsersParams) => {
  // Array because it should support multiple users in channel as possible
  const title: Array<string> = []
  const subtitle: Array<string> = []
  const photoURL: Array<string> = []
  const lastSeen: Array<number> = []
  const customStatus: Array<IStatusName> = []
  try {
    // Loop all users in channel
    for (const [userId, userData] of Object.entries(args.users)) {
      // Dont get self user info, just get another user info
      if (userId !== args.currentUserId) {
        const isAnonymousUser = userData.id && userData.id.length < 21
        let user = args.prevUsers[userId]

        if (!user && userId.length >= 21 && !isAnonymousUser) {
          // Fetch data by API
          const res = await getApiUsers([userId])
          if (res.data && res.data[userId]) {
            user = res.data[userId]
          }
        }

        if (isAnonymousUser) {
          user = {
            ...user,
            ...{
              fullName: userData.id,
              title: 'Anonym',
              photoURL: 'no-image',
              lastSeen: 0,
              customStatus: '',
            },
          }
        }

        if (user) {
          // title
          title.push(user.fullName)
          // subtitle
          subtitle.push(user.title) // because "title" is from user's title in Firebase db
          // photoURL
          photoURL.push(user.photoURL)
          // lastSeen
          lastSeen.push(user.lastSeen)
          // customStatus
          customStatus.push(user.customStatus)
        }
      }
    }
  } catch (error) {
    xConsole().error(error as Error, 'getChannelUsers.ts (Main)')
  }

  const resultTitle: string = title.join(', ') // Join with "," as separator
  const resultSubtitle: string = subtitle.join(', ') // Join with "," as separator
  const resultPhotoURL: string = photoURL[0] // First photoURL found only because not supported multiple photoURL yet
  const resultLastSeen: number = Math.max(...lastSeen) // Max lastSeen
  const resultCustomStatus: IStatusName = customStatus[0] // First customStatus found only because not supported multiple statuses yet

  // Status
  args.timeOffset = args.timeOffset ? args.timeOffset : 0
  const resultStatus = getOnlineStatus(resultLastSeen, args.timeOffset, resultCustomStatus)
  const resultStatusColor = getHexColorFromStatusName(resultStatus)

  return {
    title: resultTitle,
    subtitle: resultSubtitle,
    photoURL: resultPhotoURL,
    status: resultStatus,
    statusColor: resultStatusColor,
  }
}

export default getChannelUsers
