import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Divider } from '@rneui/themed'
import {
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetScrollView,
  BottomSheetBackdrop,
} from '@gorhom/bottom-sheet'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Components
import SettingsAvatarUpload from './AvatarUpload'
import SettingsAvatarList from './AvatarList'

// Types
type SettingsAvatarUploadOverlayProps = {
  onSelect?: (url: string) => void
}

const SettingsAvatarUploadOverlay = forwardRef(
  ({ onSelect }: SettingsAvatarUploadOverlayProps, ref) => {
    const { user } = useSelector((state: RootState) => state.user)
    if (!user) {
      return null
    }

    const [newAvatarUrl, setNewAvatarUrl] = useState('')
    const bottomSheetModalRef = useRef<BottomSheetModal>(null)

    const snapPoints = useMemo(() => ['40%', '75%'], [])

    useImperativeHandle(ref, () => ({
      setIsActive(isActive: Boolean) {
        if (isActive) {
          bottomSheetModalRef.current?.present()
        }
      },
      newAvatarUrl,
    }))

    const avatarOnChange = (url: string, dismissBottomSheetModal = false) => {
      setNewAvatarUrl(url)
      if (!!onSelect) {
        onSelect(url)
      }
      if (dismissBottomSheetModal) {
        bottomSheetModalRef.current?.dismiss()
      }
    }

    const renderBackdrop = useCallback(
      (props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />,
      []
    )

    return (
      <BottomSheetModalProvider>
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={1}
          snapPoints={snapPoints}
          backdropComponent={renderBackdrop}
        >
          <BottomSheetScrollView style={{ flex: 1 }}>
            <View style={{ marginVertical: 15 }}>
              <SettingsAvatarUpload
                isOnPressShouldDoUpload
                newUrl={newAvatarUrl}
                onUpload={(url) => {
                  avatarOnChange(url, true)
                }}
              />
            </View>
            <View style={{ paddingHorizontal: 30 }}>
              <Divider />
              <Text style={{ padding: 10, marginVertical: 15, fontSize: 18 }}>
                Hvis du ikke vil bruke eget bilde kan du bruke en av karakterene under.
              </Text>
              <View style={styles.avatarSelectorContainer}>
                <SettingsAvatarList onChange={avatarOnChange} newUrl={newAvatarUrl} />
              </View>
            </View>
          </BottomSheetScrollView>
        </BottomSheetModal>
      </BottomSheetModalProvider>
    )
  }
)

export default SettingsAvatarUploadOverlay

const styles = StyleSheet.create({
  avatarSelectorContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    marginBottom: 50,
  },
})
