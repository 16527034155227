import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'
import SettingsTwoFactor from '../../components/settings/TwoFactor'

// Variables

const SettingsTwoFactorScreen = () => {
  return (
    <ScreenSubpageLayout title="Tofaktorautentisering">
      <View style={{ marginTop: 30, marginBottom: 10 }}>
        <Text style={{ fontSize: 18 }}>
          Skru på 2-faktor autentisering for å øke sikkerheten på kontoen din.
        </Text>
        <Text style={{ marginVertical: 10, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          NB: Du vil bli automatisk logget ut og må logge inn på nytt etter å ha lagret passord.
        </Text>
      </View>
      <View style={{ marginTop: 10 }}>
        <SettingsTwoFactor />
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsTwoFactorScreen
