import React from 'react'
import { SystemMessage } from 'react-native-gifted-chat'

// Components
import ChatSystemMessageContactCard from './SystemMessageContactCard'
import ChatSystemMessageLeave from './SystemMessageLeave'

// Types
import type { IMessage } from '../../types'
import type { SystemMessageProps } from 'react-native-gifted-chat'
type ChatSystemMessageProps = SystemMessageProps<IMessage> & {
  isAnonymous: boolean
}

function ChatSystemMessage(props: ChatSystemMessageProps) {
  const { currentMessage, isAnonymous } = props
  if (currentMessage) {
    if (currentMessage.systemType === 'contact-card') {
      return <ChatSystemMessageContactCard {...props} isAnonymous={isAnonymous} />
    } else if (currentMessage.systemType === 'leave') {
      return <ChatSystemMessageLeave {...props} isAnonymous={isAnonymous} />
    }
  }
  return <SystemMessage {...props} />
}

export default ChatSystemMessage
