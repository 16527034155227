/*
  Check if users are alreay talk with others before
  Params: targetUserId(string), channels(array)(required)
  Return: boolean
*/

import { IChannel } from '../../types'
import { xConsole } from './xConsole'

const checkUsersAlreadyConnected = (
  targetUserId: string,
  isAnonymous: boolean,
  channels: IChannel[]
): string | null => {
  try {
    let isChannelExists: string | null = null
    for (const channel of channels) {
      const memberSize = Object.keys(channel.users).length
      if (
        !isAnonymous && // Not as anonymous
        !channel.isAnonymous &&
        memberSize === 2 && // 2 members (private message / no group)
        channel.users[targetUserId] && // target user in channel
        channel.users[targetUserId].permission // target user has permission
      ) {
        isChannelExists = channel.id
        break
      }
    }
    return isChannelExists
  } catch (error) {
    xConsole().error(error as Error, 'checkUsersAlreadyConnected.ts (Main)')
    return null
  }
}

export default checkUsersAlreadyConnected
