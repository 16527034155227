import React, { useEffect } from 'react'
import { View, Dimensions } from 'react-native'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Slices
import { fetchFAQ } from '../../slices/faqSlice'

// Components
import FAQListItem from './ListItem'
import Loading from '../Loading'
import EmptyOrganization from '../EmptyOrganization'

// Variables
const { height } = Dimensions.get('window')

// Types
import { IFAQ } from '../../types'

function FAQList() {
  const dispatch = useDispatch()

  // State
  const {
    faq,
    isLoading: isApiFAQLoading,
    loadingPercentage: apiFAQLoadingPercentage,
  } = useSelector((state: RootState) => state.faq)
  const { user } = useSelector((state: RootState) => state.user)

  useEffect(() => {
    dispatch(fetchFAQ())
  }, [user?.organizations])

  return (
    <View style={{ padding: 20 }}>
      {faq && faq.length ? (
        faq.map((item: IFAQ) => <FAQListItem key={item.id} item={item} />)
      ) : (
        <>
          {isApiFAQLoading && (
            <Loading
              text={`Laster inn ofte stilte spørsmål (${apiFAQLoadingPercentage}%)`}
              containerStyle={{ marginTop: height / 2 - 200 }}
            />
          )}
          {!isApiFAQLoading && !user?.organizations.length && (
            <EmptyOrganization containerStyle={{ marginTop: height / 2 - 320 }} />
          )}
        </>
      )}
    </View>
  )
}

export default FAQList
