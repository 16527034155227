// Enumerator for all routes.
export enum Routes {
  Intro = 'IntroScreen',
  Login = 'LoginScreen',
  OnBoarding = 'OnBoardingScreen',
  Channels = 'ChannelsScreen',
  Chat = 'ChatScreen',
  ListOrganizations = 'ListOrganizationsScreen',
  Contacts = 'ContactsScreen',
  UserContacts = 'UserContactsScreen',
  OtherContacts = 'OtherContactsScreen',
  OtherContactsProfile = 'OtherContactsProfileScreen',
  EmergencyContacts = 'EmergencyContactsScreen',
  FAQ = 'FAQScreen',
  ContactProfile = 'ContactProfileScreen',
  Settings = 'SettingsScreen',
  SettingsProfile = 'SettingsProfileScreen',
  SettingsTwoFactor = 'SettingsTwoFactorScreen',
  SettingsOrganizations = 'SettingsOrganizationsScreen',
  SettingsUserStatus = 'SettingsUserStatusScreen',
  SettingsDeleteAccount = 'SettingsDeleteAccountScreen',
  SettingsPrivacy = 'SettingsPrivacyScreen',
  SettingsCrisesRoutines = 'SettingsCrisesRoutinesScreen',
  SettingsAdmin = 'SettingsAdminScreen',
}
