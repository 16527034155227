import { StyleSheet, View, Pressable } from 'react-native'
import Loading from '../Loading'

// Themes
import theme from '../../config/themes'

// Types
type ToastMainProps = {
  toast: any
}

const toastLoading = ({ toast: _toast }: ToastMainProps) => {
  const isDark = _toast.data?.isDark || false

  return (
    <View style={styles.outerContainer}>
      <View
        style={[
          styles.mainContainer,
          { backgroundColor: !isDark ? theme.colors.white : theme.colors.black },
        ]}
      >
        <Loading
          text={_toast.message}
          iconColor={!isDark ? theme.colors.black : theme.colors.white}
          textColor={!isDark ? theme.colors.black : theme.colors.white}
        />
      </View>
    </View>
  )
}

export default toastLoading

const styles = StyleSheet.create({
  outerContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    paddingHorizontal: '10%',
  },
  mainContainer: {
    flexDirection: 'row',
    marginTop: -40,
    paddingHorizontal: 20,
    paddingVertical: 15,
    borderRadius: 25,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
})
