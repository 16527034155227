import { firebaseFunction } from '../../api/firebase'
import { IApiResponse } from '../../types'

// Firebase
import { auth, usersCol } from '../../api/firebase'
import { doc, setDoc } from 'firebase/firestore'

// Helpers
import getServerTime from '../../plugins/helpers/getServerTime'
import { xConsole } from '../../plugins/helpers/xConsole'

async function deleteAccount(): Promise<IApiResponse> {
  try {
    try {
      const currentUser = auth.currentUser
      if (currentUser) {
        const docRef = doc(usersCol, currentUser.uid)
        const serverTime = await getServerTime()
        await setDoc(docRef, { deleteAccount: serverTime.timestamp }, { merge: true })
        return { statusText: 'SUCCESS', status: 200 } as IApiResponse
      }
    } catch (error) {
      xConsole().error(error as Error, 'deleteAccount.ts (Sub)')
    }
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'deleteAccount.ts (Main)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default deleteAccount
