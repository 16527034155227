/*
  Get server time
  Params: null
  Return: { date(date), timestamp(number), offset(number), _deviceDate(date)}(object)
*/
import axios from 'axios'
import { Platform } from 'react-native'
import { xConsole } from './xConsole'

const getServerTime = async () => {
  // Defaults
  let date = new Date()
  const _deviceDate = date
  let timestamp = date.getTime()
  let offset = 0

  try {
    const res = await axios({
      url: __DEV__ && Platform.OS === 'web' ? '/getservertime' : 'https://web.kopling.no',
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    const serverDate = Date.parse(res.headers.date as string)
    offset = serverDate - timestamp // offset should be first position
    date = new Date(serverDate)
    timestamp = date.getTime()
  } catch (error) {
    xConsole().error(error as Error, 'getServerTime.ts (Main)')
  }
  return { date, timestamp, offset, _deviceDate }
}

export default getServerTime
