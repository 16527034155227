import getApiMessages from '../api/messages/getApiMessages'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { xConsole } from '../plugins/helpers/xConsole'

// Types
import { IMessagesState, IMessage, IUserState } from '../types'
const initialState: IMessagesState = {
  messages: {},
  isLoading: false,
  error: false,
  unsubscribe: null,
}

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    getMessages(state) {
      state.isLoading = true
      state.error = false
    },
    getMessagesSuccess(
      state,
      action: PayloadAction<{ id: string; messages: Array<IMessage>; unsubscribe: Function }>
    ) {
      const payload = action.payload
      state.isLoading = false
      state.messages[payload.id] = payload.messages
      state.unsubscribe = payload.unsubscribe
    },
    getMessagesFailure(state) {
      state.isLoading = false
      state.error = true
    },
    setUnsubscribeNull(state) {
      state.unsubscribe = null
    },
  },
})

export const { getMessages, getMessagesSuccess, getMessagesFailure, setUnsubscribeNull } =
  messages.actions

export default messages.reducer

interface IFetchMessagesArgs {
  id: string
  limit: number
}
export const fetchMessages =
  (args: IFetchMessagesArgs): AppThunk =>
  async (dispatch, getState) => {
    try {
      // const { user: userState }: IUserState = getState().user
      const { unsubscribe }: IMessagesState = getState().messages
      if (unsubscribe) {
        unsubscribe() // Unsubscribe for prev watcher
      }
      dispatch(getMessages())

      getApiMessages({
        id: args.id,
        limit: args.limit,
        getState: getState,
        updateMessages: (messages: Array<IMessage>, unsubscribe: Function) => {
          dispatch(
            getMessagesSuccess({ id: args.id, messages: messages, unsubscribe: unsubscribe })
          )
        },
      })
    } catch (error) {
      dispatch(getMessagesFailure())
      xConsole().error(error as Error, 'messagesSlice.ts (fetchMessages)')
    }
  }
