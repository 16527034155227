import React, { useState, useEffect } from 'react'
import { StyleSheet, View, StyleProp, TextStyle, ViewStyle, Animated, Easing } from 'react-native'
import { Icon, Text } from '@rneui/themed'

// Types
type LoadingProps = {
  text?: string
  textColor?: string
  textStyle?: StyleProp<TextStyle>
  iconSize?: number
  iconColor?: string
  containerStyle?: StyleProp<ViewStyle>
}
const Loading = ({
  text = 'Laster inn…',
  textColor = '#666666',
  textStyle = null,
  iconSize = 70,
  iconColor = '#2b2c2b',
  containerStyle = null,
}: LoadingProps) => {
  const [rotateAnimation, setRotateAnimation] = useState(new Animated.Value(0))

  const startAnimation = () => {
    rotateAnimation.setValue(0)
    Animated.timing(rotateAnimation, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      startAnimation()
    })
  }

  useEffect(() => {
    startAnimation()
  }, [])

  const interpolateRotating = rotateAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  })

  return (
    <View style={[styles.mainContainer, containerStyle]}>
      <Animated.View
        style={{
          transform: [
            {
              rotate: interpolateRotating,
            },
          ],
        }}
      >
        <Icon type="feather" name="loader" size={iconSize} color={iconColor} />
      </Animated.View>
      <Text style={[styles.text, textStyle, { color: textColor }]}>{text}</Text>
    </View>
  )
}

export default Loading

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 10,
  },
})
