// Firebase
import { auth } from '../../api/firebase'
import { messagesCol } from '../../api/firebase'
import { onSnapshot, query, orderBy, limit, startAt } from 'firebase/firestore'

// Helpers
import encryptMessage from '../../plugins/helpers/encryptMessage'
import decryptMessage from '../../plugins/helpers/decryptMessage'
import getChannelKey from '../../plugins/helpers/getChannelKey'
import { xConsole } from '../../plugins/helpers/xConsole'

// Types
import { IMessage } from '../../types'
interface IGetApiMessagesArgs {
  id: string
  limit: number
  getState: Function
  updateMessages: Function
}

async function getApiMessages(args: IGetApiMessagesArgs) {
  try {
    const currentUser = auth.currentUser
    if (!currentUser) {
      return
    }
    /* ======== Prepare data ======== */
    let state = args.getState()

    const { users: prevUsers } = state.users
    const { keys: prevKeys } = state.channels
    const key =
      prevKeys[args.id] || (await getChannelKey(args.id, await currentUser.getIdToken(true)))

    const messagRef = messagesCol(args.id)
    const q = query(messagRef, orderBy('createdOn', 'desc'), limit(args.limit))
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const messages: Array<IMessage> = []
      /* ======== Format messages ======== */
      for (const doc of querySnapshot.docs) {
        try {
          const docData = doc.data()
          let user = prevUsers[docData.uid]
          if (!user) {
            // Anonymous user or not found (Fallback)
            user = {
              fullName: docData.uid,
              photoURL: '',
            }
          }

          const formattedMessage: IMessage = {
            _id: doc.id,
            text: docData?.content
              ? decryptMessage({
                  content: docData.content,
                  key: key,
                })
              : '',
            createdAt: docData.createdOn,
            user: {
              _id: docData.uid,
              name: user.fullName,
              avatar: user.photoURL,
            },
            system: docData.type === 'system',
            type: docData.type,
          }

          if (
            docData.type === 'message' &&
            formattedMessage.text &&
            formattedMessage.text.includes('<br>')
          ) {
            formattedMessage.text = formattedMessage.text.replace(/<br\s*[\/]?>/gi, '\n')
          }

          // Custom data for custom view
          if (docData.type !== 'message' && docData.type !== 'system') {
            formattedMessage.customData = formattedMessage.text
            if (docData.json) {
              formattedMessage.customData = JSON.parse(formattedMessage.customData)
              // Format old => new Contact card data structure
              if (formattedMessage.customData.refMessage !== undefined) {
                formattedMessage.customData.fullName = formattedMessage.customData.displayName
                formattedMessage.customData.message = formattedMessage.customData.refMessage
                formattedMessage.customData.title = formattedMessage.customData.subtitle
                formattedMessage.customData.targetId = formattedMessage.customData.uid
                formattedMessage.customData.fromId = formattedMessage.user._id
                formattedMessage.customData.organizationId =
                  formattedMessage.customData.organizationId || ''
              }
            }
            formattedMessage.text = ''
          }

          // System message type
          if (docData.type === 'system' && docData.systemType) {
            formattedMessage.systemType = docData.systemType
          }

          // Format old => new Leave data structure
          if (
            docData.type === 'system' &&
            docData.systemType === undefined &&
            formattedMessage.text &&
            formattedMessage.text.includes('forlot samtalen')
          ) {
            formattedMessage.systemType = 'leave'
            formattedMessage.text = JSON.stringify({
              userId: formattedMessage.user._id || '',
              fullName: formattedMessage.user.name || 'Han/hun',
            })
          }

          // Format old => new Ref message data structure
          if (
            docData.type === 'reference' &&
            formattedMessage.customData &&
            formattedMessage.customData.content
          ) {
            formattedMessage.system = true
            formattedMessage.type = 'system'
            formattedMessage.systemType = 'contact-card'

            let refFullName = 'Noen'
            const refFullNameMatch = formattedMessage.customData.content.match(
              /fra <strong>(.*?)<\/strong><\/div>/
            )
            if (refFullNameMatch && refFullNameMatch[1]) {
              refFullName = refFullNameMatch[1]
            }

            let refMessage = '…'
            const refMessageMatch = formattedMessage.customData.content.match(
              /<div class=\"secret-message\">(.*?)<\/div>/
            )
            if (refMessageMatch && refMessageMatch[1]) {
              refMessage = refMessageMatch[1]
            }
            formattedMessage.text = JSON.stringify({
              targetId: formattedMessage.customData.to,
              fromId: '',
              fromFullName: refFullName,
              message: refMessage,
            })
          }

          // Push to messages
          messages.push(formattedMessage)
        } catch (error) {
          xConsole().error(
            error as Error,
            'getApiMessages.ts (querySnapshot.docs loop and prepare data)'
          )
        }
      }
      messages.sort((a, b) => b.createdAt - a.createdAt) // Sort by date
      args.updateMessages(messages, unsubscribe)
    })
  } catch (error) {
    xConsole().error(error as Error, 'getApiMessages.ts (Main)')
  }
}

export default getApiMessages
