import Crypto from 'crypto-js'
import { customAlphabet } from 'nanoid/non-secure'
import { channelSalt } from '../../config'

// Firebase
import { auth } from '../../api/firebase'
import { channelsCol, messagesCol } from '../../api/firebase'
import { doc, setDoc, addDoc, deleteField } from 'firebase/firestore'

// Helpers
import getChannelKey from '../../plugins/helpers/getChannelKey'
import encryptMessage from '../../plugins/helpers/encryptMessage'
import getServerTime from '../../plugins/helpers/getServerTime'
import getApiUsers from '../../api/users/getApiUsers'
import { xConsole } from '../../plugins/helpers/xConsole'

// Variables
const nanoid = customAlphabet('1234567890abcdef', 30)

// Types
import { IChannelReference, IApiResponse } from '../../types'
interface ICreateChannelArgs {
  targetUserId: string
  isAnonymous: boolean
  firstMessage: string
  reference?: IChannelReference
  // setAnonymousChannel: Function // @ANO-UNCOMMENT
  dispatch: Function
}

async function createApiChannel(args: ICreateChannelArgs): Promise<IApiResponse> {
  try {
    const prefix = '003_'
    const id = prefix + nanoid()
    const user = auth.currentUser
    if (!user) {
      return { statusText: 'NO_CURRENT_USER_FOUND', status: 400 } as IApiResponse
    }
    const token = await user.getIdToken(true)
    let key = await getChannelKey(id, token)
    let userId = user.uid
    const fakeId = customAlphabet(
      '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
      10
    )()
    const serverTime = await getServerTime()
    const docRef = doc(channelsCol, id)

    /* ==================== Anonymous ==================== */
    /* @ANO-UNCOMMENT
    if (args.isAnonymous) {
      
      // Generate new key
      key = Crypto.PBKDF2(
        customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 30)(),
        channelSalt,
        {
          keySize: 256 / 32,
          iterations: 1000,
        }
      ).toString()
      // Generate new fake user id
      userId = customAlphabet(
        '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
        10
      )()
      // Self: Put anonymous channel in Store
      args.setAnonymousChannel({ id: id, key: key })
      // Target: Put anonymous channel data in db
      const targetUserDocRef = doc(pendingAnonymousChannelsCol(args.targetUserId), id)
      await setDoc(targetUserDocRef, { key })
     
    }
    */

    /* ================== First message =================== */
    const message = {
      content:
        encryptMessage({
          content: args.firstMessage,
          key: key,
        }) || deleteField(),
      type: 'message',
      uid: args.isAnonymous ? fakeId : userId,
      createdOn: serverTime.timestamp,
    }

    /* ======= Create channel in channel's collection ======= */
    await setDoc(
      docRef,
      {
        id: id,
        lastMessage: message,
        users: {
          [args.targetUserId]: { permission: true },
          [userId]: { permission: true, id: args.isAnonymous ? fakeId : deleteField() },
        },
        isAnonymous: args.isAnonymous || deleteField(),
        reference: args.reference || deleteField(),
        createdOn: serverTime.timestamp,
      },
      { merge: true }
    )
    /* ===== Create messages in new channel's collection ===== */
    // Reference system message
    try {
      if (args.reference && args.reference.type === 'contact-card') {
        // Get user info
        let referenceUserFullName = null
        if (args.reference.from) {
          const res = await getApiUsers([args.reference.from])
          if (res.data && res.data[args.reference.from]) {
            const user = res.data[args.reference.from]
            referenceUserFullName = user.fullName
          }
        }

        // System message
        const systemMessage = {
          content:
            encryptMessage({
              content: JSON.stringify({
                targetId: args.targetUserId,
                fromId: args.reference.from,
                fromFullName: referenceUserFullName,
                message: args.reference.message || null,
              }),
              key: key,
            }) || deleteField(),
          type: 'system',
          systemType: 'contact-card',
          uid: userId,
          createdOn: serverTime.timestamp - 1,
        }
        await addDoc(messagesCol(id), systemMessage)
      }
    } catch ({ message }) {}
    // First message
    await addDoc(messagesCol(id), message)

    return { statusText: 'SUCCESS', status: 200, data: { id } } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'createApiChannel.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default createApiChannel
