import formatAllOrganizationData from '../../plugins/helpers/formatAllOrganizationData'
import { xConsole } from '../../plugins/helpers/xConsole'

// Firebase
import { institutionsCol } from '../../api/firebase'
import { getDocs, query, where } from 'firebase/firestore'

// Types
import { IApiResponse, IOrganizationHeader, IOrganizationAll } from '../../types'

/**
 * Get all organizations (for selector)
 */
async function getApiAllOrganizations(): Promise<IApiResponse> {
  try {
    // Variables
    const organizations: Array<IOrganizationHeader | IOrganizationAll> = []
    const queryByCategory = async (categoryName: string) => {
      const q = query(institutionsCol, where('category', '==', categoryName))
      const querySnapshot = await getDocs(q)
      if (querySnapshot.size) {
        // Header
        const formattedCategoryName = categoryName
          .replace('Bedrift', 'Tjenester')
          .replace('Skole', 'Skoler')
        const headerData: IOrganizationHeader = {
          id: formattedCategoryName,
          name: formattedCategoryName,
          type: 'header',
        }
        organizations.push(headerData)

        // Items
        querySnapshot.forEach((doc) => {
          const docData = doc.data()
          const formattedOtherOrganizationData = formatAllOrganizationData(docData)
          const allOrganizationData = {
            ...formattedOtherOrganizationData,
            ...{
              type: 'item',
            },
          } as IOrganizationAll
          organizations.push(allOrganizationData)
        })
      }
    }

    // Init
    // await queryByCategory('Bedrift')
    await queryByCategory('Skole')

    return { statusText: 'SUCCESS', status: 200, data: organizations } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'getApiAllOrganizations.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default getApiAllOrganizations
