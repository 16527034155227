import formatUserData from '../../plugins/helpers/formatUserData'
import getOnlineStatus from '../../plugins/helpers/getOnlineStatus'
import getHexColorFromStatusName from '../../plugins/helpers/getHexColorFromStatusName'
import { xConsole } from '../../plugins/helpers/xConsole'

// Firebase
import { usersCol, institutionsCol } from '../../api/firebase'
import { doc, getDoc } from 'firebase/firestore'

// Types
import { IApiResponse, IOrganizationHeader, IOrganizationUser, IStatusName } from '../../types'
interface IGetApiOrganizationsArgs {
  userOrganizations: Array<string>
  updateLoadingPercentage: Function
}

/**
 * Gets organizations from current user
 */
async function getApiOrganizations(args: IGetApiOrganizationsArgs): Promise<IApiResponse> {
  try {
    // Variables
    const organizations: Array<IOrganizationHeader | IOrganizationUser> = []
    // Init
    for (const [organizeIndex, organizeId] of args.userOrganizations.entries()) {
      /* ==============================
      ::: Update loading percentage :::
      ============================== */
      args.updateLoadingPercentage((organizeIndex * 100) / args.userOrganizations.length)

      /* ==============================
      ::::::::::::: Fetch :::::::::::::
      ============================== */
      const docRef = doc(institutionsCol, organizeId)
      const docSnap = await getDoc(docRef)
      if (docSnap.exists()) {
        const organizeDocData = docSnap.data()
        if (organizeDocData.users) {
          // Header
          const headerData: IOrganizationHeader = {
            id: organizeDocData.id,
            name: organizeDocData.title,
            type: 'header',
          }
          organizations.push(headerData)

          // Item (user)
          if (organizeDocData.users) {
            type IOrganizeUser = {
              permission: boolean
              priority?: number
            }
            // Sort Users by priority
            const organizeUserSorted = Object.entries(organizeDocData.users)
              .sort(
                ([kA, a]: [string, any], [kB, b]: [string, any]) =>
                  (a.priority || Infinity) - (b.priority || Infinity) || -(kA > kB)
              )
              .reduce((r, [k, v]) => ({ ...r, [k]: v }), {})

            for (let [userId, user] of Object.entries(organizeUserSorted as IOrganizeUser[])) {
              if (user.permission) {
                const userDocRef = doc(usersCol, userId)
                const userDocSnap = await getDoc(userDocRef)
                if (userDocSnap.exists()) {
                  const userDocData = userDocSnap.data()

                  const formattedUserData = formatUserData(userDocData)
                  /* ==============================
                  :: Get Status/Color :::::::::::::
                  ============================== */
                  formattedUserData.status = getOnlineStatus(
                    formattedUserData.lastSeen as number,
                    0,
                    formattedUserData.customStatus as IStatusName
                  )
                  formattedUserData.statusColor = getHexColorFromStatusName(
                    formattedUserData.status as IStatusName
                  )
                  const userData = {
                    ...{
                      organizationName: organizeDocData.title,
                      organizationId: organizeDocData.id,
                      type: 'item',
                    },
                    ...formattedUserData,
                  } as IOrganizationUser
                  organizations.push(userData)
                }
              }
            }
          }
        }
      }
    }
    return { statusText: 'SUCCESS', status: 200, data: organizations } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'getApiOrganizations.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default getApiOrganizations
