import { StyleSheet, View } from 'react-native'

// Themes
import theme from '../../config/themes'

// Components
import SettingsLogout from './Logout'
import SettingsDevmode from './Devmode'

const SettingsFooter = () => {
  return (
    <View style={styles.mainContainer}>
      <SettingsLogout />
      <SettingsDevmode />
    </View>
  )
}

export default SettingsFooter

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    backgroundColor: theme.colors.light,
  },
})
