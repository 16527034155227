import React from 'react'
import { StyleSheet, Dimensions, View } from 'react-native'
import { Text, Button } from '@rneui/themed'

// Components
import Avatar from '../Avatar'

// Helpers
import checkUsersAlreadyConnected from '../../plugins/helpers/checkUsersAlreadyConnected'
import getApiUsers from '../../api/users/getApiUsers'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Navigation
import { useNavigation, NavigationProp, StackActions } from '@react-navigation/native'

// Themes
import theme from '../../config/themes'

// Variables
const { width } = Dimensions.get('window')

// Types
import type { IMessage, RootNavigatorParamList } from '../../types'
import type { MessageProps } from 'react-native-gifted-chat'
import { Routes } from '../../config/routes'
type ChatMessageContactCardProps = MessageProps<IMessage> & {
  isAnonymous: boolean
}

function ChatMessageContactCard(props: ChatMessageContactCardProps) {
  const { currentMessage, isAnonymous, position } = props
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const item = currentMessage && currentMessage.customData ? currentMessage.customData : null
  const { channels } = useSelector((state: RootState) => state.channels)

  /* ========= Start chat ======== */
  const startChat = async (isAnonymous: boolean) => {
    const channelExists = checkUsersAlreadyConnected(item.targetId, isAnonymous, channels)
    const res = await getApiUsers([item.targetId])
    if (res.data && res.data[item.targetId]) {
      const user = res.data[item.targetId]
      if (channelExists) {
        const pushAction = StackActions.push(Routes.Chat, {
          item: {
            id: channelExists,
            title: user.fullName,
            description: user.title,
            photoURL: user.photoURL,
            isAnonymous: isAnonymous,
          },
          reference: {
            type: 'contact-card',
            from: item.fromId,
            organizationId: item.organizationId,
            message: item.message,
          },
        } as RootNavigatorParamList['ChatScreen'])
        navigation.dispatch(pushAction)
      } else {
        // Go to new channel for create new one
        const pushAction = StackActions.push(Routes.Chat, {
          item: {
            title: user.fullName,
            description: user.title,
            photoURL: user.photoURL,
            isAnonymous: isAnonymous,
          },
          create: user.id,
          reference: {
            type: 'contact-card',
            from: item.fromId,
            organizationId: item.organizationId,
            message: item.message,
          },
        } as RootNavigatorParamList['ChatScreen'])
        navigation.dispatch(pushAction)
      }
    } else {
      toast.show('Beklager, noe gikk galt.')
    }
  }

  if (!item) {
    return null
  }
  return (
    <View style={[styles.mainContainer, { backgroundColor: !isAnonymous ? '#f2efea' : '#1d1d1d' }]}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {item.photoURL && (
          <Avatar
            photoURL={item.photoURL}
            placeholderTitle={item.displayName}
            props={{
              size: 45,
              containerStyle: { marginRight: 15 },
              placeholderStyle: { backgroundColor: theme.colors.grey4 },
            }}
          />
        )}
        <View>
          <Text
            style={{
              fontFamily: 'FSJoeyMedium',
              fontSize: 18,
              color: !isAnonymous ? theme.colors.black : theme.colors.white,
            }}
          >
            {item.fullName}
          </Text>
          {!!item.title && (
            <Text
              style={{
                fontSize: 14,
                color: !isAnonymous ? '#666666' : '#cecece',
              }}
            >
              {item.title}
            </Text>
          )}
        </View>
      </View>
      {position === 'right' && !!item.message && (
        <Text
          style={{
            fontSize: 16,
            color: !isAnonymous ? '#2b2c2b' : '#ffffff',
            textAlign: 'center',
            marginTop: 15,
          }}
        >
          {item.message.trim()}
        </Text>
      )}
      {position === 'left' && (
        <>
          <Button
            title={'START DIALOG SOM ANONYM'}
            containerStyle={[styles.buttonContainer, { marginTop: 15 }]}
            buttonStyle={[styles.button, { backgroundColor: theme.colors.grey1 }]}
            titleStyle={styles.buttonTitle}
            onPress={() => {
              startChat(true)
            }}
          />
          <Button
            title={'START DIALOG'}
            containerStyle={[styles.buttonContainer, { marginTop: 10 }]}
            buttonStyle={[styles.button]}
            titleStyle={styles.buttonTitle}
            onPress={() => {
              startChat(false)
            }}
          />
        </>
      )}
    </View>
  )
}

export default ChatMessageContactCard

const styles = StyleSheet.create({
  mainContainer: {
    width: width - 81,
    marginHorizontal: -8,
    marginVertical: -2,
    padding: 20,
    borderRadius: 15,
    alignItems: 'center',
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    paddingVertical: 10,
  },
  buttonTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 14,
    lineHeight: 14,
  },
})
