import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Keyboard } from 'react-native'
import { GiftedChat } from 'react-native-gifted-chat'
import { SafeAreaView } from 'react-native-safe-area-context'
import { getBottomSpace } from 'react-native-iphone-x-helper'
import { debounce } from 'lodash'
import * as Clipboard from 'expo-clipboard'
import 'dayjs/locale/nb'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { createChannel } from '../../slices/channelsSlice'
import { fetchMessages, setUnsubscribeNull } from '../../slices/messagesSlice'

// Components
import ChatEmpty from '../../components/chat/ChatEmpty'
import ChatAvatar from '../../components/chat/Avatar'
import ChatMessage from '../../components/chat/Message'
import ChatMessageText from '../../components/chat/MessageText'
import ChatSystemMessage from '../../components/chat/SystemMessage'
import ChatDay from '../../components/chat/Day'
import ChatBubble from '../../components/chat/Bubble'
import ChatInput from '../../components/chat/Input'
import ChatInputSend from '../../components/chat/InputSend'
import ChatInputActions from '../../components/chat/actions/InputActions'
import ChatLoadEarlierButton from '../../components/chat/LoadEarlierButton'
import ChatComposer from '../../components/chat/Composer'

// Actions
import ChatInputActionShareContacts from './actions/shareContacts'

// Helpers
import createApiMessage from '../../api/messages/createApiMessage'
import getApiMoreMessages from '../../api/messages/getApiMoreMessages'
import getChannelCurrentUserId from '../../plugins/helpers/getChannelCurrentUserId'
import setUserLastViewed from '../../api/channels/setUserLastViewed'
import { xConsole } from '../../plugins/helpers/xConsole'

// Types
import { RootNavigatorParamList, IMessage, IChannel, IShareContactsRef } from '../../types'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Routes } from '../../config/routes'
type ChatViewProps = NativeStackScreenProps<RootNavigatorParamList, Routes.Chat>

function ChatView(props: ChatViewProps) {
  const dispatch = useDispatch()
  const { item, create, reference } = props.route.params
  const { navigation } = props

  /* ==============================================================
  :: User :::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const { user, device, isHelper } = useSelector((state: RootState) => state.user)
  const { users } = useSelector((state: RootState) => state.users)
  const refUserId = useRef<string>('')

  /* Function: Get current user */
  const getCurrentUserId = () => {
    if (user && refChannel.current) {
      return getChannelCurrentUserId({ channel: refChannel.current, realUserId: user.id })
    }
    return user ? user.id : ''
  }

  /* ==============================================================
  :: Channel ::::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const { keys, channels } = useSelector((state: RootState) => state.channels)
  const refChannel = useRef<IChannel | null>()

  /* Function: Get current channel */
  const getCurrentChannel = (channels: IChannel[]) => {
    let result: IChannel | null = null
    try {
      const channel = channels.find((c) => c.id === item.id)
      if (channel) {
        result = channel
      }
    } catch (error) {
      xConsole().error(error as Error, 'chat/View.tsx (getCurrentChannel)')
    }
    refChannel.current = result
    return result
  }
  /* Function: Set lastViewed */
  const setLastViewed = useCallback(
    debounce((messages) => {
      try {
        if (!create && item.id && user) {
          const lastMessage = messages[item.id].filter((m) => m.user._id !== refUserId.current)[0] // Non-self message
          const lastViewed = lastMessage && lastMessage.createdAt ? lastMessage.createdAt : 0
          setUserLastViewed({
            userId: user.id,
            channelId: item.id,
            lastViewed: lastViewed,
          })
        }
      } catch (error) {
        xConsole().error(error as Error, 'chat/View.tsx (setLastViewed)')
      }
    }, 1000),
    []
  )

  /* ==============================================================
  :: Messages :::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const { messages, unsubscribe: unsubscribeMessages } = useSelector(
    (state: RootState) => state.messages
  )
  const [currentMessages, setCurrentMessages] = useState<IMessage[]>([])
  const refMessages = useRef<IMessage[]>([])

  /* Function: onSend callback (Check if create or just send message) */
  const onSend = useCallback(
    async (messages = []) => {
      if (create) {
        Keyboard.dismiss()
        const toastLoading = toast.show(
          `Starter${item.isAnonymous ? ' anonym' : ''} samtale med ${item.title}`,
          {
            type: 'loading',
            placement: 'center',
            duration: 1000000,
            swipeEnabled: false,
            data: { isDark: item.isAnonymous },
          }
        )
        dispatch(
          createChannel({
            targetUserId: create,
            isAnonymous: item.isAnonymous,
            firstMessage: messages[0].text,
            reference: reference,
            onSuccess(channelId: string) {
              toast.hide(toastLoading)
              navigation.setParams({
                item: {
                  id: channelId,
                  title: item.title,
                  description: item.description,
                  photoURL: item.photoURL,
                  statusColor: item.statusColor,
                  isAnonymous: item.isAnonymous,
                },
                create: undefined,
              })
            },
            onError() {
              toast.hide(toastLoading)
            },
          })
        )
      } else {
        if (!item.id) {
          return
        }
        sendMessage(item.id, messages[0].text)
      }
    },
    [create]
  )
  /* Function: Send message */
  const sendMessage = async (id: string, message: string) => {
    const key = keys[id]
    await createApiMessage({
      id: id,
      userId: refUserId.current,
      message: message,
      key: key,
      createdOn: Date.now() + device.timeOffset,
    })
  }
  /* Function: Get messages */
  const getMessages = () => {
    let result: IMessage[] = []
    try {
      if (!create && item.id && messages[item.id]) {
        // If not creating a channel
        result = [...messages[item.id]]
      }
    } catch (error) {
      xConsole().error(error as Error, 'chat/View.tsx (getMessages)')
    }
    setCurrentMessages(result)
    refMessages.current = result
    return result
  }
  /* Function: Update lastViewed on targetUser */
  const updateTargetUserLastViewed = useCallback(
    debounce(() => {
      try {
        const messages = [...refMessages.current].map(({ received, ...item }) => item) // Clone and remove prev 'received'
        const lastViewedMessageIndex = getLastViewedMessageIndex()
        if (lastViewedMessageIndex !== -1) {
          messages[lastViewedMessageIndex] = {
            ...messages[lastViewedMessageIndex],
            ...{ received: true },
          }

          setCurrentMessages(messages)
          refMessages.current = messages
        }
      } catch (error) {
        xConsole().error(error as Error, 'chat/View.tsx (updateTargetUserLastViewed)')
      }
    }, 1000),
    []
  )
  /* Function: Get lastViewed message index */
  const getLastViewedMessageIndex = () => {
    let result = -1
    try {
      const channel = refChannel.current
      if (channel && refUserId.current) {
        const targetUserId = Object.keys(channel.users).filter((id) => id !== refUserId.current)[0]
        if (targetUserId) {
          const targetUser = channel.users[targetUserId]
          const lastViewed = targetUser.lastViewed
          result = refMessages.current.findIndex((v) => v.createdAt === lastViewed)
        }
      }
    } catch (error) {
      xConsole().error(error as Error, 'chat/View.tsx (getLastViewedMessageIndex)')
    }
    return result
  }

  /* ==============================================================
  :: Pagination :::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const messagePerPage = 20
  const [isHasMore, setIsHasMore] = useState(false)

  /* Function: Load earlier messages */
  const onLoadEarlier = async () => {
    if (!item.id) {
      return
    }
    const firstMessage: IMessage = currentMessages[currentMessages.length - 1]
    const res = await getApiMoreMessages({
      id: item.id,
      limit: messagePerPage,
      users: users,
      key: keys[item.id],
      startAfter: firstMessage.createdAt,
    })
    if (res.status === 200) {
      const mergedMessages = currentMessages.concat(res.data)
      setIsHasMore(res.data.length >= messagePerPage)
      setCurrentMessages(mergedMessages)
    }
  }

  /* ==============================================================
  :: useEffects ::::::::::::::::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  /* Main */

  useEffect(() => {
    if (!create && item.id) {
      getCurrentChannel(channels)
      refUserId.current = getCurrentUserId()
      // Fetch messages from db
      dispatch(fetchMessages({ id: item.id, limit: messagePerPage }))
    }
  }, [props.route.params])
  // Unsubscribe Firebase
  useEffect(() => {
    const unsubscribe = () => {
      if (unsubscribeMessages) {
        unsubscribeMessages()
        dispatch(setUnsubscribeNull())
      }
    }
    return unsubscribe
  }, [unsubscribeMessages])
  /* Messages */
  useEffect(() => {
    getMessages()
    setIsHasMore(currentMessages.length >= messagePerPage)
    setLastViewed(messages)
  }, [messages])
  /* Channels */
  useEffect(() => {
    getCurrentChannel(channels)
    updateTargetUserLastViewed()
  }, [channels])

  /* ==============================================================
  :: GiftedChat's functions :::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  /* Function: On long press message, show actions */
  const onLongPress = (context: any, message: IMessage) => {
    const options = ['Kopier meldingen', 'Avbryt']
    const cancelButtonIndex = options.length - 1
    context
      .actionSheet()
      .showActionSheetWithOptions({ options, cancelButtonIndex }, (i: number) => {
        switch (i) {
          case 0:
            Clipboard.setString(message.text)
            break
        }
      })
  }

  /* ==============================================================
  :: GiftedChat's components ::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  const renderAvatar = (props: any) => <ChatAvatar {...props} isAnonymous={item.isAnonymous} />
  const renderMessage = (props: any) => <ChatMessage {...props} />
  const renderMessageText = (props: any) => (
    <ChatMessageText {...props} isAnonymous={item.isAnonymous} />
  )
  const renderBubble = (props: any) => <ChatBubble {...props} isAnonymous={item.isAnonymous} />
  const renderSystemMessage = (props: any) => (
    <ChatSystemMessage {...props} isAnonymous={item.isAnonymous} />
  )
  const renderDay = (props: any) => <ChatDay {...props} isAnonymous={item.isAnonymous} />
  const renderSend = (props: any) => <ChatInputSend {...props} />
  const renderInputToolbar = (props: any) => {
    return null
  }
  const renderComposer = (props: any) => <ChatComposer {...props} isAnonymous={item.isAnonymous} />
  const renderActions = () => {
    if (!refChannel.current) return null
    if ((!user?.accessLevel || user?.accessLevel < 7) && !isHelper) {
      return null
    }
    return <ChatInputActions {...props} isAnonymous={item.isAnonymous} trigger={triggerAction} />
  }
  const renderLoadEarlier = (props: any) => <ChatLoadEarlierButton {...props} />

  /* ==============================================================
  :: Input actions ::::::::::::::::::::::::::::::::::::::
  ============================================================== */
  /* Function: Main trigger action switcher */
  const triggerAction = (actionName: string) => {
    switch (actionName) {
      case 'shareContacts':
        shareContactsActions()
        break
      default:
        xConsole().log(`Cannot find ${actionName}'s action`)
    }
  }
  /* Function: Share contacts */
  const shareContactsRef = useRef<IShareContactsRef>(null)
  const shareContactsActions = () => {
    if (!shareContactsRef.current) {
      return
    }
    shareContactsRef.current.setIsActive(true)
  }

  return (
    <SafeAreaView style={{ flex: 1 }} edges={['bottom']}>
      <GiftedChat
        messages={currentMessages}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: refUserId.current,
        }}
        infiniteScroll={true}
        loadEarlier={isHasMore}
        onLoadEarlier={onLoadEarlier}
        renderLoadEarlier={renderLoadEarlier}
        alwaysShowSend={true}
        renderAvatar={renderAvatar}
        renderTime={() => null}
        renderChatEmpty={() => <ChatEmpty isAnonymous={item.isAnonymous} isLoading={!create} />}
        renderMessage={renderMessage}
        renderMessageText={renderMessageText}
        renderBubble={renderBubble}
        renderSystemMessage={renderSystemMessage}
        renderDay={renderDay}
        placeholder={'Skriv din melding…'}
        renderSend={renderSend}
        renderInputToolbar={renderInputToolbar}
        renderComposer={renderComposer}
        bottomOffset={getBottomSpace()}
        renderActions={renderActions}
        onLongPress={onLongPress}
        locale={'nb'}
        minInputToolbarHeight={refChannel.current?.disabled ? 0 : 50}
        minComposerHeight={28}
        maxComposerHeight={106}
        wrapInSafeArea={false}
      />
      {refChannel.current && (
        <ChatInputActionShareContacts
          ref={shareContactsRef}
          channel={refChannel.current}
          senderId={refUserId.current}
        />
      )}
    </SafeAreaView>
  )
}

export default ChatView
