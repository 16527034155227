import React from 'react'
import { StyleSheet } from 'react-native'
import { Button } from '@rneui/themed'

import theme from '../../config/themes'

type SubmitButtonProps = {
  title: string
  onPress: () => void
  loading: boolean
  valid: boolean
}
function SubmitButton({ title, onPress, loading, valid }: SubmitButtonProps) {
  return (
    <Button
      title={title}
      onPress={onPress}
      loading={loading}
      containerStyle={styles.buttonContainer}
      buttonStyle={valid ? styles.buttonValid : styles.button}
      titleStyle={styles.buttonTitle}
      loadingProps={{ color: theme.colors.black }}
    />
  )
}

export default SubmitButton

const styles = StyleSheet.create({
  buttonContainer: {
    marginVertical: 15,
  },
  button: {
    backgroundColor: 'transparent',
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
  buttonTitle: {
    fontSize: 18,
    color: theme.colors.black,
  },
  buttonValid: {
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
