import { View } from 'react-native'
import { Composer } from 'react-native-gifted-chat'

import type { ComposerProps } from 'react-native-gifted-chat'

function ChatComposer(props: ComposerProps) {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'flex-start',
        marginHorizontal: 10,
        borderRadius: 20,
        backgroundColor: !props.isAnonymous ? '#efebe5' : '#1d1d1d',
        height: 60,
      }}
    >
      <Composer
        {...props}
        multiline
        composerHeight={80}
        textInputStyle={[{ color: !props.isAnonymous ? '#2b2c2b' : '#ffffff' }]}
        textInputProps={{
          ...props.textInputProps,
          blurOnSubmit: true,
          onSubmitEditing: () => {
            if (props.text && props.onSend) {
              props.onSend({ text: props.text.trim() }, true)
              const textareaEl = document.querySelector('textarea[placeholder]')
              if (textareaEl) {
                setTimeout(() => {
                  textareaEl.focus()
                }, 0)
              }
            }
          },
        }}
      />
    </View>
  )
}

export default ChatComposer
