import React, { useState } from 'react'
import {
  View,
  StyleSheet,
  Keyboard,
  TouchableWithoutFeedback,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// API
import login from '../api/auth/login'
import getApiUser from '../api/users/getApiUser'

// Helpers
import formatPhoneNumber from '../plugins/helpers/formatPhoneNumber'
import { xConsole } from '../plugins/helpers/xConsole'

// Themes
import theme from '../config/themes'

// Components
import Title from '../components/login/Title'
import StatusMessage from '../components/login/StatusMessage'
import BackButton from '../components/login/BackButton'
import PhoneForm from '../components/login/PhoneForm'
import CodeForm from '../components/login/CodeForm'
import PasswordForm from '../components/login/PasswordForm'
import ResetPasswordButton from '../components/login/ResetPasswordButton'

// Types
import { ILoginScreenAuth, RootNavigatorParamList } from '../types'
import { Routes } from '../config/routes'

const headerTexts = [
  {
    title: '',
    subtitle: '',
  },
  {
    title: 'Velkommen til Elevtjenesten!',
    subtitle: 'Logg inn eller lag en bruker',
  },
  {
    title: 'Bekreft kode',
    subtitle: '',
  },
  {
    title: 'Passord',
    subtitle: 'Fyll inn passordet ditt',
  },
]

const LoginScreen = () => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const [step, setStep] = useState(1) // Step (current step 1 = phoneNumber, 2 = phoneNumber + verifyCode)
  const [auth, setAuth] = useState<ILoginScreenAuth | null>(null)
  const [title, setTitle] = useState(headerTexts[1].title)
  const [subtitle, setSubtitle] = useState(headerTexts[1].subtitle)
  const [isLoggingIn, setIsLoggingIn] = useState(false)

  const doLogin = async (token: string) => {
    try {
      setIsLoggingIn(true)
      const res = await login(token)
      if (res.status === 200) {
        const user = await getApiUser()
        if (
          user &&
          user.data &&
          user.data.fullName &&
          !user.data.fullName.includes('Fornavn') &&
          !user.data.fullName.includes('Etternavn') &&
          user.data.organizations &&
          user.data.organizations.length
        ) {
          // Do nothing
        } else {
          navigation.navigate(Routes.OnBoarding, {})
        }
      } else {
        setIsLoggingIn(false)
      }
    } catch (error) {
      setIsLoggingIn(false)
      xConsole().error(error as Error, 'LoginScreen.tsx (doLogin)')
    }
  }

  const goBack = (): void => {
    let prevStep = Math.min(Math.max(step - 1, 1), 3)
    if (prevStep === 2) {
      prevStep = 1 // Not allow to send confirmation code
    }
    setStep(prevStep)
    setTitle(headerTexts[prevStep].title)
    setSubtitle(headerTexts[prevStep].subtitle)
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          Platform.OS !== 'web' ? Keyboard.dismiss : null
        }}
      >
        <View style={styles.mainContainer}>
          <View style={styles.contentContainer}>
            <SafeAreaView style={{ flex: 0.5, paddingHorizontal: 20 }}>
              <View style={{ flex: 0.3 }}>{step > 1 && <BackButton onGoBack={goBack} />}</View>
              <View style={{ flex: 0.7, justifyContent: 'flex-end' }}>
                <Title>{title}</Title>
                <View style={{ minHeight: 80 }}>
                  <StatusMessage message={subtitle} />
                  {step === 3 && auth && auth.phoneNumber && (
                    <ResetPasswordButton
                      phoneNumber={formatPhoneNumber(auth.phoneNumber)}
                      buttonProps={{
                        type: 'clear',
                        containerStyle: { width: 200 },
                        buttonStyle: { paddingLeft: 0, justifyContent: 'flex-start' },
                        titleStyle: { color: theme.colors.black, fontSize: 20 },
                        disabledTitleStyle: { color: theme.colors.teal, opacity: 0.25 },
                      }}
                    />
                  )}
                </View>
              </View>
            </SafeAreaView>
            <View style={{ flex: 0.5, paddingHorizontal: 20 }}>
              {step === 1 && (
                <PhoneForm
                  onSuccess={(auth) => {
                    setStep(2)
                    setTitle(headerTexts[2].title)
                    setSubtitle(`Oppgi koden vi sendte til ${auth.phoneNumber}`)
                    setAuth(auth)
                  }}
                />
              )}
              {step === 2 && (
                <CodeForm
                  onSuccess={(auth) => {
                    setStep(3)
                    setTitle(headerTexts[3].title)
                    setSubtitle(headerTexts[3].subtitle)
                    setAuth(auth)
                  }}
                  login={doLogin}
                  loggingIn={isLoggingIn}
                  auth={auth}
                />
              )}
              {step === 3 && <PasswordForm login={doLogin} loggingIn={isLoggingIn} auth={auth} />}
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  )
}

export default LoginScreen

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: theme.colors.bluelight,
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
