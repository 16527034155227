import React from 'react'

// Navigation & drawer
import { createDrawerNavigator } from '@react-navigation/drawer'

// Screens
import ChannelsScreen from '../screens/ChannelsScreen'
import ContactsScreen from '../screens/contacts/ContactsScreen'
import SettingsScreen from '../screens/settings/SettingsScreen'
import FAQScreen from '../screens/FAQScreen'

// Types
import { RootNavigatorParamList } from '../types'
import { Routes } from '../config/routes'

// Variables
const Drawer = createDrawerNavigator<RootNavigatorParamList>()

const RootNavigator = () => {
  return (
    <Drawer.Navigator
      screenOptions={({ navigation }) => ({
        headerShown: false,
        swipeEnabled: false,
        drawerStyle: {
          width: 0,
        },
      })}
      drawerContent={() => <></>}
    >
      <Drawer.Screen
        name={Routes.FAQ}
        options={{ title: 'Spørsmål og svar' }}
        component={FAQScreen}
      />
      <Drawer.Screen
        name={Routes.Channels}
        component={ChannelsScreen}
        options={{ title: 'Dine meldinger' }}
      />
      <Drawer.Screen
        name={Routes.Contacts}
        component={ContactsScreen}
        options={{ title: 'Andre hjelpetjenester' }}
      />

      <Drawer.Screen
        name={Routes.Settings}
        component={SettingsScreen}
        options={{ title: 'Innstillinger' }}
      />
    </Drawer.Navigator>
  )
}

export default RootNavigator
