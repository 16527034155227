import React from 'react'
import { StyleSheet, View } from 'react-native'
import { ListItem, Text, Icon } from '@rneui/themed'

// Components
import Avatar from '../../Avatar'
// Theme
import theme from '../../../config/themes'

//Types
import { IOrganizationUser, IOrganizationHeader } from '../../../types'
type ListContactItemProps = {
  item: IOrganizationUser | IOrganizationHeader
  onPress: any
  headerBgColor?: string
  placeholderColor?: string
  itemListContainerBgColor?: string
}

const ContactItem = ({
  item,
  onPress,
  headerBgColor = '',
  placeholderColor = '#ffffff',
  itemListContainerBgColor = '',
}: ListContactItemProps) => {
  if (item.type === 'item') {
    return (
      <ListItem
        key={item.id}
        onPress={onPress}
        bottomDivider
        containerStyle={[
          styles.itemListContainer,
          { backgroundColor: itemListContainerBgColor || theme.colors.white },
        ]}
      >
        <Avatar
          photoURL={item.photoURL}
          statusColor={item.statusColor}
          placeholderTitle={item.fullName}
          props={{
            placeholderStyle: { backgroundColor: placeholderColor },
          }}
        />
        <ListItem.Content>
          <ListItem.Title numberOfLines={1}>{item.fullName}</ListItem.Title>
          <ListItem.Subtitle numberOfLines={1} style={{ color: theme?.colors?.grey2 }}>
            {item.title}
          </ListItem.Subtitle>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
      </ListItem>
    )
  } else {
    return (
      <View
        key={item.id}
        style={[styles.header, { backgroundColor: headerBgColor || theme.colors.bodybg }]}
      >
        <Text key={item.id} style={styles.headerText}>
          {item.name}
        </Text>
      </View>
    )
  }
}

export default ContactItem

const styles = StyleSheet.create({
  header: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 20,
    backgroundColor: theme.colors.bodybg,
    height: 40,
  },
  headerText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
  },
  itemListContainer: {
    height: 73,
    paddingHorizontal: 20,
    marginHorizontal: 20,
    marginBottom: 10,
    borderRadius: 8,
  },
})
