import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { ListItem, Text, Icon } from '@rneui/themed'

// Theme
import theme from '../../../config/themes'

// Types
import { IOrganizationAll, IOrganizationHeader } from '../../../types'
type ListContactItemProps = {
  item: IOrganizationAll | IOrganizationHeader
  active: boolean
  onPress: (active: boolean, id: string) => void
}

const ContactItem = ({ item, active, onPress }: ListContactItemProps) => {
  const [localActive, setLocalActive] = useState(false)
  useEffect(() => {
    setLocalActive(active)
  }, [active])

  if (item.type === 'item') {
    return (
      <ListItem
        key={item.id}
        onPress={() => {
          setLocalActive(!active)
          onPress(!active, item.id)
        }}
        containerStyle={[
          styles.itemContainer,
          { backgroundColor: localActive ? '#eaeaea' : '#f7f7f7' },
        ]}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.itemTitle}>{item.organizationName}</ListItem.Title>
        </ListItem.Content>
        {localActive ? (
          <Icon name="check-circle-outline" type="octicons" color={theme.colors.primary} />
        ) : (
          <Icon name="radio-button-off" type="ionicons" color={theme.colors.black} />
        )}
      </ListItem>
    )
  } else {
    return (
      <View key={item.id} style={styles.header}>
        <Text key={item.id} style={styles.headerText}>
          {item.name}
        </Text>
      </View>
    )
  }
}

export default ContactItem

const styles = StyleSheet.create({
  header: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 15,
    height: 40,
    backgroundColor: theme.colors.white,
  },
  headerText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
  },
  itemContainer: {
    paddingHorizontal: 15,
    marginBottom: 10,
    marginHorizontal: 15,
    borderRadius: 10,
  },
  itemTitle: {
    fontFamily: 'FSJoey',
    fontSize: 18,
  },
})
