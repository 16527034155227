import { StyleSheet, View, Platform } from 'react-native'
import { ListItem, Icon } from '@rneui/themed'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Themes
import theme from '../../config/themes'

// Types
import { RootNavigatorParamList } from '../../types'
import { Routes } from '../../config/routes'

// Components

const SettingsMenus = () => {
  const { user } = useSelector((state: RootState) => state.user)
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const goTo = (to: Routes) => {
    navigation.navigate(to, {})
  }
  if (!user) {
    return null
  }
  return (
    <View style={styles.mainContainer}>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsProfile)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>Din profil</ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsOrganizations)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>Skoler og tjenester</ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsUserStatus)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>Tilgjengelighet</ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsTwoFactor)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>Tofaktorautentifisering</ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsPrivacy)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>Personvernerklæring</ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsDeleteAccount)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>Sletting av konto</ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>
      <ListItem
        containerStyle={styles.listItem}
        topDivider
        onPress={() => goTo(Routes.SettingsCrisesRoutines)}
      >
        <ListItem.Content>
          <ListItem.Title style={styles.listItemTitle}>
            Rutiner for krisehenvendelser
          </ListItem.Title>
        </ListItem.Content>
        <Icon type="entypo" name="chevron-right" color={theme.colors.primary} size={24} />
      </ListItem>

      {user.accessLevel && user.accessLevel >= 7 && (
        <ListItem
          containerStyle={styles.listItem}
          topDivider
          onPress={() => {
            if (Platform.OS !== 'web') {
              goTo(Routes.SettingsAdmin)
            } else {
              window.open('https://web-admin.kopling.no', '_blank')
            }
          }}
        >
          <ListItem.Content>
            <ListItem.Title style={[styles.listItemTitle, { fontFamily: 'FSJoeyMedium' }]}>
              Administrasjon
            </ListItem.Title>
          </ListItem.Content>
          <Icon name="external-link" type="feather" color={theme.colors.primary} size={24} />
        </ListItem>
      )}
    </View>
  )
}

export default SettingsMenus

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 20,
  },
  listItem: {
    paddingVertical: 15,
    paddingHorizontal: 0,
    backgroundColor: 'transparent',
  },
  listItemTitle: {
    fontFamily: 'FSJoey',
    fontSize: 22,
  },
})
