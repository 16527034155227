import { firebaseFunction } from '../../api/firebase'
import { xConsole } from '../../plugins/helpers/xConsole'
import { IApiResponse } from '../../types'

async function resetPassword(phoneNumber: string): Promise<IApiResponse> {
  try {
    try {
      const res = await firebaseFunction({
        endpoint: '/resetPassword',
        payload: { phoneNumber },
      })
      if (res.status === 200 && res.data.success === 'RESET_PASSWORD_SENT') {
        return { statusText: 'SUCCESS', status: 200 } as IApiResponse
      }
    } catch (error) {
      xConsole().error(error as Error, 'resetPassword.ts (API)')
    }
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'resetPassword.ts (Main)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default resetPassword
