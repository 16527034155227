import React, { useState, useEffect, useCallback } from 'react'
import { ScrollView, Dimensions, RefreshControl, ActivityIndicator } from 'react-native'
import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview'
import { debounce } from 'lodash'
import { xConsole } from '../../../plugins/helpers/xConsole'

// Slices
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store/rootReducer'
import { fetchOtherOrganizations } from '../../../slices/organizationsSlice'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import SearchContacts from './Search'
import ContactItem from './Item'

// Types
import { IOrganizationOther, RootNavigatorParamList } from '../../../types'
import { Routes } from '../../../config/routes'

// Variables
const { width } = Dimensions.get('window')

const ListOrganizations = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  // State: Organizations
  const { organizations, isLoading: isApiOrganizationsLoading } = useSelector(
    (state: RootState) => state.organizations.other
  )
  const [organizationsFiltered, setOrganizationsFiltered] = useState<IOrganizationOther[]>([])

  const updateOtherOrganizations = () => {
    dispatch(fetchOtherOrganizations())
  }

  useEffect(() => {
    updateOtherOrganizations()
  }, [])

  const connectChannel = (data: IOrganizationOther) => {
    navigation.navigate(Routes.OtherContactsProfile, { item: data })
  }

  const dataProvider = new DataProvider((r1, r2) => {
    return r1 !== r2
  }).cloneWithRows(organizationsFiltered.length ? organizationsFiltered : organizations)

  const _layoutProvider = new LayoutProvider(
    (index) => {
      return index
    },
    (type, dim) => {
      dim.width = width
      dim.height = 73
    }
  )

  const rowRenderer = (type: any, data: IOrganizationOther) => {
    return <ContactItem item={data} onPress={() => connectChannel(data)} />
  }

  const onSearch = useCallback(
    debounce((s: string) => {
      try {
        if (s.length) {
          const result = organizations.filter(
            (a) =>
              !!a.organizationName && a.organizationName.toLowerCase().includes(s.toLowerCase())
          )
          setOrganizationsFiltered(result)
        } else {
          setOrganizationsFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'contacts/other/List.tsx (onSearch)')
      }
    }, 1000),
    [organizations]
  )

  return (
    <>
      <SearchContacts
        onSearch={(s: string) => {
          onSearch(s)
        }}
      />
      {dataProvider && dataProvider.getSize() > 0 ? (
        <RecyclerListView
          testID="recyclerListView"
          dataProvider={dataProvider}
          layoutProvider={_layoutProvider}
          rowRenderer={rowRenderer}
          canChangeSize={true}
          scrollViewProps={{
            refreshControl: (
              <RefreshControl
                refreshing={isApiOrganizationsLoading}
                onRefresh={updateOtherOrganizations}
              />
            ),
            contentContainerStyle: { paddingBottom: 75 },
          }}
        />
      ) : (
        <ScrollView style={{ padding: 20 }}>
          <ActivityIndicator />
        </ScrollView>
      )}
    </>
  )
}

export default ListOrganizations
