import React, { useState, useEffect, useCallback, useRef } from 'react'
import { StyleSheet, Dimensions } from 'react-native'
import { SearchBar } from '@rneui/themed'
import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview'
import { debounce } from 'lodash'
import { xConsole } from '../../../plugins/helpers/xConsole'

// Slices
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store/rootReducer'
import { fetchUsers } from '../../../slices/usersSlice'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Themes
import theme from '../../../config/themes'

// Components
import ContactItem from '../user/Item'
import ContactProfileOverlay from '../user/ProfileOverlay'

// Types
import {
  IOrganizationUser,
  IUser,
  IContactOverlayRef,
  RootNavigatorParamList,
} from '../../../types'
import { Routes } from '../../../config/routes'

// Variables
const { width } = Dimensions.get('window')

const ListOrganizationUsers = (props: any) => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const ContactOverlayRef = useRef<IContactOverlayRef>(null)
  const [search, setSearch] = useState('')

  // State: Organization users
  const usersState = useSelector((state: RootState) => state.users)
  const users = usersState.users as IUser[]
  const [organizationUsers, setOrganizationUsers] = useState<IOrganizationUser[]>([])
  const [organizationUsersFiltered, setOrganizationUsersFiltered] = useState<IOrganizationUser[]>(
    []
  )

  const updateOrganizationUsers = async () => {
    dispatch(fetchUsers(props.users))
  }
  const formatUsersData = async () => {
    setOrganizationUsers([]) // Reset
    for (const userId of props.users) {
      const user: IOrganizationUser = {
        ...users[userId],
        organizationName: '',
        type: 'item',
      }
      if (user.id) {
        setOrganizationUsers((o) => [...o, user])
      }
    }
  }

  useEffect(() => {
    updateOrganizationUsers()
  }, [])

  useEffect(() => {
    formatUsersData()
  }, [users])

  const connectChannel = (data: IOrganizationUser) => {
    ContactOverlayRef.current?.setIsActive(true, data)
  }

  const dataProvider = new DataProvider((r1, r2) => {
    return r1 !== r2
  }).cloneWithRows(organizationUsersFiltered.length ? organizationUsersFiltered : organizationUsers)

  const _layoutProvider = new LayoutProvider(
    (index) => {
      return index
    },
    (type, dim) => {
      dim.width = width
      dim.height = 83 // 73 (Height) + 10 (Margin)
    }
  )

  const updateSearch = (text: string): void => {
    setSearch(text)
    onSearch(text)
  }
  const onSearch = useCallback(
    debounce((s: string) => {
      try {
        if (s.length) {
          const result = organizationUsers.filter(
            (a) => !!a.fullName && a.fullName.toLowerCase().includes(s.toLowerCase())
          )
          setOrganizationUsersFiltered(result)
        } else {
          setOrganizationUsersFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'contacts/other/Users.tsx (onSearch)')
      }
    }, 1000),
    [organizationUsers]
  )

  const rowRenderer = (type: any, data: IOrganizationUser) => {
    return <ContactItem item={data} onPress={() => connectChannel(data)} />
  }

  return (
    <>
      <SearchBar
        placeholder="Søk etter hjelper"
        onChangeText={updateSearch} //  @TODO This is wack (https://github.com/react-native-elements/react-native-elements/issues/3089)
        value={search}
        containerStyle={{
          marginVertical: 10,
          paddingHorizontal: 20,
        }}
        inputContainerStyle={{ backgroundColor: theme.colors.white }}
      />
      {dataProvider && dataProvider.getSize() > 0 && (
        <RecyclerListView
          dataProvider={dataProvider}
          layoutProvider={_layoutProvider}
          rowRenderer={rowRenderer}
          scrollViewProps={{
            refreshControl: null,
          }}
        />
      )}
      <ContactProfileOverlay ref={ContactOverlayRef} bottomInset={0} />
    </>
  )
}

export default ListOrganizationUsers

const styles = StyleSheet.create({})
