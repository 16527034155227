import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'

const SettingsCrisesRoutinesScreen = () => {
  const renderBlackCircle = () => {
    return <Text>{'\u25CF'}</Text>
  }
  const renderWhiteCircle = () => {
    return <Text style={{ fontSize: 10, lineHeight: 23 }}>{'\u25CB'}</Text>
  }
  return (
    <ScreenSubpageLayout title="Retningslinjer ved bekymring for elev som tar kontakt via appen">
      <View style={{ marginTop: 15, marginBottom: 30 }}>
        <Text style={{ margin: 5, fontSize: 18 }}>
          Selv om appen er et lavterskeltilbud som skal besvare spørsmål rundt opplæring og
          psykososiale vansker som påvirker opplæringen, kan elevene ta kontakt mens de står i
          alvorlige krisesituasjoner. Retningslinjene beskrevet under er kun veiledende. Du er selv
          ansvarlig for å vite hvilke lover og regler som gjelder deg og din profesjon med tanke på
          blant annet taushetsplikt og meldeplikt. Du må også selv vurdere alvorlighetsgraden av din
          bekymring for en elev. Dersom du er usikker, bør du kontakte din nærmeste leder.
        </Text>
        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Hvis det oppstår en situasjon hvor du er bekymret for en ungdom
        </Text>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Vis forståelse og omsorg, og valider følelsen til ungdommen
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Formidle til eleven at du er bekymret og at du ønsker å hjelpe
          </Text>
        </View>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Vurdere om situasjonen er akutt
        </Text>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Still direkte spørsmål om det du bekymrer deg for dersom dette ikke kommer klart frem
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Dersom det er en trussel om en alvorlig hendelse som kan inntreffe, for eksempel trussel
            om vold, selvmord, selvskading eller skoleskyting, spør:
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5, paddingLeft: 20 }}>
          {renderWhiteCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>om det foreligger en plan</Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5, paddingLeft: 20 }}>
          {renderWhiteCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            om det er bestemt hvordan det skal utføres
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5, paddingLeft: 20 }}>
          {renderWhiteCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            om det er planlagt et tidspunkt
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Dersom eleven svarer på alle disse spørsmålet bør det vurderes å være en akutt forhøyet
            fare
          </Text>
        </View>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Hvis situasjonen er akutt hvor det er fare for liv og helse
        </Text>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Dersom eleven trenger akutt hjelp, prøv å få informasjon om hvem eleven er,
            telefonnummer og hvor han/hun oppholder seg
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Omhandler det psykisk eller fysisk helse - ta kontakt med legevakten på telefonnummer
            116 117 eller 113 dersom det står om liv og du vet hvor ungdommen oppholder seg.
            Legevakten kan ta kontakt med ungdommen og vurdere om de skal reise ut eller ikke.
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Dersom ungdommen ikke ønsker å oppgi navn, og du vurderer det som akutt fare for liv og
            helse, skal du ta kontakt med politi på 02800 eller nødnummeret 112.
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Dersom du er usikker, ta kontakt med politiet på 02800 for å vurdere situasjonen
          </Text>
        </View>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Hvis situasjonen ikke er akutt
        </Text>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Formidle håp om at situasjonen kan endre seg til det bedre, og at det finnes folk som
            ønsker å hjelpe
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Oppfordre eleven til å ta kontakt med en voksen som han/hun stoler på – for eksempel en
            lærer, foreldre, helsesykepleier eller en slektning
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Oppfordre eleven til å ringe Mental helse, en organisasjon vi samarbeider tett og godt
            med, som har en egen hjelpetelefon, en gratis tjeneste som er åpen 24 timer i døgnet,
            hele året. Telefon: 116 123
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Gi informasjon om tilbud i kommunen eleven bor, avhengig av hvilke vansker eleven
            opplever
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5, paddingLeft: 20 }}>
          {renderWhiteCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Fastlegen kan henvise videre til spesialisthelsetjenesten
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5, paddingLeft: 20 }}>
          {renderWhiteCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Rus - mange kommuner har et tilbud til innbyggere som har vansker med rus – se på
            kommunen sine hjemmesider
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5, paddingLeft: 20 }}>
          {renderWhiteCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Psykisk helse – alle kommuner har et tilbud for de som har psykiske vansker, uten at en
            trenger en henvisning – se kommunen sine hjemmesider
          </Text>
        </View>

        <Text h4 style={{ margin: 5, marginTop: 30 }}>
          Liste over nasjonale hjelpetelefoner
        </Text>
        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Mental helses hjelpetelefon
        </Text>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Telefon 116 123 (hele døgnet). Ringer du hjelpetelefonen og taster 2 kommer du til
            Foreldresupport
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Chat-tjeneste og skrivetjeneste: sidetmedord.no
          </Text>
        </View>
        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Åpningstidene chat:
        </Text>
        <Text style={{ margin: 5, fontSize: 18 }}>Mandag 20–23</Text>
        <Text style={{ margin: 5, fontSize: 18 }}>Tirsdag 12–14</Text>
        <Text style={{ margin: 5, fontSize: 18 }}>Onsdag 12–14 + 20–23</Text>
        <Text style={{ margin: 5, fontSize: 18 }}>Torsdag 12–14</Text>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Alarmtelefonen for barn og unge
        </Text>
        <Text style={{ margin: 5, fontSize: 18 }}>
          Telefon 116 111. Alarmtelefonen er et nødnummer for barn og unge, som er gratis å ringe.
          Ta kontakt hvis du opplever vold i hjemmet, er utsatt for overgrep, er redd for
          tvangsgifte eller opplever andre akutte og vanskelige situasjoner.Tilbudet gjelder også
          voksne som er bekymret for barn og unge – eller har mistanke om at de utsettes for vold og
          overgrep.
        </Text>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Vold- og overgrepslinjen (VO)
        </Text>
        <Text style={{ margin: 5, fontSize: 18 }}>
          Telefon 116 006 (hele døgnet). VO er en hjelpelinje for deg som opplever vold eller
          overgrep i nære relasjoner.
        </Text>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Seksuelle overgrep – Landsdekkende telefon for incest- og seksuelt misbrukte, og deres
          pårørende
        </Text>
        <Text style={{ margin: 5, fontSize: 18 }}>Telefon 800 57 000 (døgnåpen)</Text>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Kirkens SOS
        </Text>
        <Text style={{ margin: 5, fontSize: 18 }}>
          Telefon 22 40 00 40 (hele døgnet). Du kan chatte anonymt med Kirkens SOS på www.soschat.no
          hver dag fra 18.30–22.30 (fredag frem til 01.30). Du kan også skrive en SOS-melding via
          www.kirkens-sos.no. Du kan være anonym og du får svar innen 24 timer.
        </Text>

        <Text style={{ margin: 5, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 18 }}>
          Trusler mot hjelpere i appen?
        </Text>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Ring 800 40 008 og politiet veileder deg om hvordan du kan anmelde.
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Alle trusler skal rapporteres/anmeldes til politiet umiddelbart.
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Du kan lese mer her: https://www.politiet.no/tjenester/anmelde/
          </Text>
        </View>
        <View style={{ flexDirection: 'row', margin: 5 }}>
          {renderBlackCircle()}
          <Text style={{ flex: 1, fontSize: 18, paddingLeft: 5 }}>
            Kopling AS skal også informeres om truslene
          </Text>
        </View>
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsCrisesRoutinesScreen
