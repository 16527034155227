import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { SearchBar } from '@rneui/themed'

// Types
type SearchOrganizationsProps = {
  onSearch: (s: string) => void
}

const SearchOrganizations = ({ onSearch }: SearchOrganizationsProps) => {
  const [search, setSearch] = useState('')

  const updateSearch = (text: string): void => {
    setSearch(text)
    onSearch(text)
  }

  return (
    <SearchBar
      placeholder="Søk etter skoler og tjenester"
      placeholderTextColor="#666666"
      onChangeText={updateSearch}
      value={search}
      containerStyle={styles.container}
      searchIcon={{ type: 'antdesign', name: 'search1' }}
    />
  )
}

export default SearchOrganizations

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    paddingHorizontal: 15,
  },
})
