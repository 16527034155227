import axios from 'axios'
import queryString from 'query-string'
import { initializeApp } from 'firebase/app'
import {
  initializeFirestore,
  CollectionReference,
  collection,
  DocumentData,
} from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

import { firebaseConfig, firebaseApiUrl } from '../config'
import { xConsole } from '../plugins/helpers/xConsole'

// Types
import { IApiResponse, IStatusName } from '../types'
// import { FieldValue } from '@firebase/firestore-types'

// Variables
export const firebaseApp = initializeApp(firebaseConfig)
export const firestore = initializeFirestore(firebaseApp, { experimentalForceLongPolling: true })
export const auth = getAuth()

// Doc: https://javascript.plainenglish.io/using-firestore-with-typescript-in-the-v9-sdk-cf36851bb099
const createCollection = <T = DocumentData>(collectionName: string) => {
  return collection(firestore, collectionName) as CollectionReference<T>
}

const createSubCollection = <T = DocumentData>(collectionName: string, path: string) => {
  return collection(firestore, `${path}/${collectionName}`) as CollectionReference<T>
}

// User
interface IUserDB {
  id: string
  uid: string
  displayName: string
  subtitle: string
  description: string
  phoneNumber: string
  photoURL: string
  institutions: Array<String>
  lastSeen: number
  accessLevel?: number
  deleteAccount?: number
  devices?: object
  onlineStatus?: IStatusName
}
const usersCol = createCollection<IUserDB>('users')

// pendingAnonymousChannels
/* @ANO-UNCOMMENT
interface IPendingAnonymousChannelsDB {
  key: string
}
const pendingAnonymousChannelsCol = (userId: string) =>
  createSubCollection<IPendingAnonymousChannelsDB>('pendingAnonymousChannels', `users/${userId}`)
*/

// Channels
interface IChannelsDB {
  id: string
  lastMessage: object
  users: object
  isAnonymous: boolean
  createdOn: number
  reference?: object | null
}
const channelsCol = createCollection<IChannelsDB>('channels')

// Messages
interface IMessagesDB {
  content: {
    ct: string
    iv: string
  }
  type: string
  uid: string
  createdOn: number
  json?: boolean
  systemType?: string
}
const messagesCol = (channelId: string) =>
  createSubCollection<IMessagesDB>('messages', `channels/${channelId}`)

// Institution
interface IInstitutionDB {
  id: string
  title: string
  subtitle: string
  category: string
  photoURL: string
  buttons: Array<String>
  users: Object
  terms: string
}
const institutionsCol = createCollection<IInstitutionDB>('institutions')

// FAQ
interface IFAQDB {
  id: string
  title: string
  priority?: number
}
const faqCol = (institutionId: string) =>
  createSubCollection<IFAQDB>('faq', `institutions/${institutionId}`)

// Sub FAQ
interface ISubFAQDB {
  id: string
  title: string
  content: string
  priority?: number
}
const subFaqCol = (institutionId: string, faqId: string) =>
  createSubCollection<ISubFAQDB>('faq', `institutions/${institutionId}/faq/${faqId}`)

// Firebase
async function firebaseFunction(args: Object): Promise<IApiResponse> {
  const defaults = {
    endpoint: '',
    method: 'POST',
    payload: {},
  }
  const options = { ...defaults, ...args }
  try {
    const response = await axios({
      method: options.method,
      url: `${firebaseApiUrl}${options.endpoint}`,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify(options.payload),
    })
    return {
      statusText: response.statusText,
      status: response.status,
      data: response.data,
    } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'firebase.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export {
  usersCol,
  IUserDB,
  // pendingAnonymousChannelsCol, // @ANO-UNCOMMENT
  // IPendingAnonymousChannelsDB, // @ANO-UNCOMMENT
  channelsCol,
  IChannelsDB,
  messagesCol,
  IMessagesDB,
  institutionsCol,
  IInstitutionDB,
  faqCol,
  IFAQDB,
  subFaqCol,
  ISubFAQDB,
  firebaseFunction,
}
