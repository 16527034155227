import React, { useEffect, useCallback } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../../store/rootReducer'
import { fetchOrganizations } from '../../../../slices/organizationsSlice'

// Components
import ShareContactsListItem from './Item'

// Theme
import theme from '../../../../config/themes'

type ShareContactsListProps = {
  trigger: Function
  usersInChannel: Array<string>
}

const ShareContactsList = ({ trigger, usersInChannel }: ShareContactsListProps) => {
  const dispatch = useDispatch()

  // State:
  const { organizations, indices } = useSelector((state: RootState) => state.organizations)

  const updateOrganizations = async () => {
    dispatch(fetchOrganizations())
  }

  useEffect(() => {
    updateOrganizations()
  }, [])

  const renderItem = useCallback(
    ({ item }) => (
      <ShareContactsListItem
        item={item}
        trigger={trigger}
        disabled={usersInChannel.includes(item.id)}
      />
    ),
    []
  )

  return (
    <>
      {organizations.length ? (
        <View style={{ flex: 1 }}>
          <FlatList
            data={organizations}
            keyExtractor={(item: any, index: any) => index}
            renderItem={renderItem}
            stickyHeaderIndices={indices}
            style={{ minHeight: 1 }} // Hacks scroll height problem on Web
            contentContainerStyle={{ paddingBottom: 65 }}
          />
        </View>
      ) : (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
          <ActivityIndicator color={theme.colors.black} />
        </View>
      )}
    </>
  )
}

export default ShareContactsList
