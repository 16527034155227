import { StyleSheet, View, Pressable } from 'react-native'
import { Avatar, Icon, Button } from '@rneui/themed'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Themes
import theme from '../../config/themes'

// Avatar list @TODO: Should get this data from db
const avatarList = [
  'https://profile.kopling.no/img/avatars/v4/bekkeblom.png',
  'https://profile.kopling.no/img/avatars/v4/characterg.png',
  'https://profile.kopling.no/img/avatars/v4/characterh.png',
  'https://profile.kopling.no/img/avatars/v4/characteri.png',
  'https://profile.kopling.no/img/avatars/v4/characterj.png',
  'https://profile.kopling.no/img/avatars/v4/characterj2.png',
  'https://profile.kopling.no/img/avatars/v4/characterk.png',
  'https://profile.kopling.no/img/avatars/v4/characterl2.png',
  'https://profile.kopling.no/img/avatars/v4/charakterl.png',
  'https://profile.kopling.no/img/avatars/v4/creeps.png',
  'https://profile.kopling.no/img/avatars/v4/drink.png',
  'https://profile.kopling.no/img/avatars/v4/happy.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper1.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper2.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper3.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper4.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper4v2.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper5.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper6.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper7.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper8.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper9.png',
  'https://profile.kopling.no/img/avatars/v4/hjelper9v2.png',
  'https://profile.kopling.no/img/avatars/v4/jungle.png',
  'https://profile.kopling.no/img/avatars/v4/neutral1.png',
  'https://profile.kopling.no/img/avatars/v4/neutral2.png',
  'https://profile.kopling.no/img/avatars/v4/neutral3.png',
  'https://profile.kopling.no/img/avatars/v4/neutral4.png',
  'https://profile.kopling.no/img/avatars/v4/par.png',
  'https://profile.kopling.no/img/avatars/v4/paws.png',
  'https://profile.kopling.no/img/avatars/v4/petter.png',
  'https://profile.kopling.no/img/avatars/v4/pissed.png',
  'https://profile.kopling.no/img/avatars/v4/pizza.png',
  'https://profile.kopling.no/img/avatars/v4/plants.png',
  'https://profile.kopling.no/img/avatars/v4/shoegreen.png',
  'https://profile.kopling.no/img/avatars/v4/shoegrey.png',
  'https://profile.kopling.no/img/avatars/v4/shoered.png',
  'https://profile.kopling.no/img/avatars/v4/superbruker.png',
  'https://profile.kopling.no/img/avatars/v4/violin.png',
  'https://profile.kopling.no/img/avatars/v4/weather.png',
  'https://profile.kopling.no/img/avatars/v4/willywonka.png',
  'https://profile.kopling.no/img/avatars/v4/zoom1.png',
  'https://profile.kopling.no/img/avatars/v4/zoom2.png',
]

// Types
type SettingsAvatarListProps = {
  onChange: (url: string) => void
  newUrl: string
}

const SettingsAvatarList = ({ onChange, newUrl }: SettingsAvatarListProps) => {
  return (
    <>
      {avatarList.map((url: any, i: number) => (
        <Avatar
          size={60}
          rounded
          key={i}
          source={{ uri: url }}
          containerStyle={styles.itemContainer}
          avatarStyle={newUrl === url ? styles.itemContainerSelected : {}}
          onPress={() => {
            onChange(newUrl !== url ? url : '')
          }}
        />
      ))}
    </>
  )
}

export default SettingsAvatarList

const styles = StyleSheet.create({
  itemContainer: {
    margin: 5,
  },
  itemContainerSelected: {
    borderWidth: 5,
    borderColor: theme.colors.primary,
  },
})
