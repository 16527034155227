'use strict'
import { getApiFAQ } from '../api'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { IFAQState, IFAQ, IUserState } from '../types'
import { xConsole } from '../plugins/helpers/xConsole'

const initialState: IFAQState = {
  faq: [],
  isLoading: false,
  loadingPercentage: 0,
  error: false,
}

const faq = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    getFAQ(state) {
      state.isLoading = true
      state.loadingPercentage = 0
      state.error = false
    },
    getFAQSuccess(state, action: PayloadAction<{ faq: Array<IFAQ> }>) {
      const payload = action.payload
      state.isLoading = false
      state.loadingPercentage = 100
      state.faq = payload.faq
    },
    getFAQFailure(state) {
      state.isLoading = false
      state.loadingPercentage = 100
      state.error = true
    },
    setFAQLoadingPercentage(state, action: PayloadAction<number>) {
      const payload = action.payload
      state.loadingPercentage = ~~payload
    },
  },
})

export const { getFAQ, getFAQSuccess, getFAQFailure, setFAQLoadingPercentage } = faq.actions

export default faq.reducer

export const fetchFAQ = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user }: IUserState = getState().user
    dispatch(getFAQ())
    const res = await getApiFAQ({
      userOrganizations: user?.organizations || [],
      updateLoadingPercentage: (percent: number) => {
        dispatch(setFAQLoadingPercentage(percent))
      },
    })
    dispatch(getFAQSuccess({ faq: res.data }))
  } catch (error) {
    dispatch(getFAQFailure())
    xConsole().error(error as Error, 'faqSlice.ts (fetchFAQ)')
  }
}
