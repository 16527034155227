import { orderBy } from 'lodash'
// Firebase
import { institutionsCol, faqCol, subFaqCol } from '../api/firebase'
import { doc, getDoc, getDocs } from 'firebase/firestore'

import { xConsole } from '../plugins/helpers/xConsole'

// Types
import { IApiResponse, IFAQ, IMainFAQ, ISubFAQ } from '../types'
interface IGetApiFAQArgs {
  userOrganizations: Array<string>
  updateLoadingPercentage: Function
}

async function getApiFAQ(args: IGetApiFAQArgs): Promise<IApiResponse> {
  try {
    // Variables
    const faq: IFAQ[] = []
    // Init
    for (const [organizeIndex, organizeId] of args.userOrganizations.entries()) {
      // Update loading percentage
      args.updateLoadingPercentage((organizeIndex * 100) / args.userOrganizations.length)

      // Prepare data
      const data: IFAQ = {
        id: organizeId,
        title: '',
        items: [],
      }

      const organizeDocSnap = await getDoc(doc(institutionsCol, organizeId))
      if (organizeDocSnap.exists()) {
        const organizeDocData = organizeDocSnap.data()
        data.title = organizeDocData.title
      }

      // Main
      const snapshot = await getDocs(faqCol(organizeId))
      for (const doc of snapshot.docs) {
        const docData = doc.data()
        const docDataFormatted: IMainFAQ = {
          ...{
            id: doc.id,
            title: '(Missing title…)',
            priority: -1,
            items: [],
          },
          ...docData,
        }
        docDataFormatted.content = docDataFormatted.content?.replace(/<br\s*[\/]?>/gi, '\n') // Replace <br> to newline

        // Sub
        const subSnapshot = await getDocs(subFaqCol(organizeId, doc.id))
        for (const subDoc of subSnapshot.docs) {
          const subDocData = subDoc.data()
          const subDocDataFormatted: ISubFAQ = {
            ...{
              id: subDoc.id,
              title: '(Missing title…)',
              content: '(Missing content…)',
              priority: -1,
            },
            ...subDocData,
          }
          subDocDataFormatted.content = subDocDataFormatted.content?.replace(/<br\s*[\/]?>/gi, '\n') // Replace <br> to newline
          docDataFormatted.items.push(subDocDataFormatted)
        }
        docDataFormatted.items = orderBy(docDataFormatted.items, 'priority', 'desc')
        data.items.push(docDataFormatted)
      }
      if (data.items.length) {
        data.items = orderBy(data.items, 'priority', 'desc')
        faq.push(data)
      }
    }

    return { statusText: 'SUCCESS', status: 200, data: faq } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'faq.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export { getApiFAQ }
