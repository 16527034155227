import Crypto from 'crypto-js'
import { xConsole } from './xConsole'

// Types
interface IDecryptMessageParams {
  content: {
    ct: string
    iv: string
  }
  key: string
}

/*
  Decrypt message
  Params: { content: { ct: '', iv: '' }(obj)(required), key(string)(required) }
  Return: string
*/
const decryptMessage = (args: IDecryptMessageParams): string => {
  let result = ''

  try {
    const encrypted = Crypto.lib.CipherParams.create({
      ciphertext: Crypto.enc.Base64.parse(args.content.ct),
      iv: Crypto.enc.Hex.parse(args.content.iv),
    })
    result = Crypto.AES.decrypt(encrypted, Crypto.enc.Hex.parse(args.key), {
      iv: encrypted.iv,
    }).toString(Crypto.enc.Utf8)
    return result
  } catch (error) {
    xConsole().error(error as Error, 'decryptMessage.ts (Main)')
    return result
  }
}

export default decryptMessage
