import React, { useState } from 'react'
import { View } from 'react-native'
import { Button, Overlay, Text, Icon } from '@rneui/themed'

// Themes
import theme from '../../config/themes'

// Store
import store from '../../store'
import { useDispatch } from 'react-redux'

// Api
import logout from '../../api/auth/logout'

const SettingsLogout = () => {
  const dispatch = useDispatch()
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)

  return (
    <>
      <Button
        containerStyle={{
          width: 80,
        }}
        title="Logg ut"
        type="clear"
        onPress={() => {
          setShowConfirmLogout(true)
        }}
      />
      <Overlay
        isVisible={showConfirmLogout}
        onBackdropPress={() => setShowConfirmLogout(false)}
        overlayStyle={{
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 20,
          paddingHorizontal: 40,
        }}
      >
        <Icon name="log-out" type="feather" color={theme.colors.black} size={60} />
        <Text h4 style={{ marginVertical: 15 }}>
          Er du sikker på at du vil logge ut?
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            title="Ja, logg ut"
            onPress={async () => {
              await logout(store.getState)
              dispatch({ type: 'CLEAR_ALL' })
            }}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
          />
          <Button
            title="Avbryt"
            onPress={() => {
              setShowConfirmLogout(false)
            }}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
          />
        </View>
      </Overlay>
    </>
  )
}

export default SettingsLogout
