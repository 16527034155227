import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { View } from 'react-native'
import { Text, Button, Overlay } from '@rneui/themed'

// Store
import { useDispatch } from 'react-redux'
import { leaveChannel } from '../../../slices/channelsSlice'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Theme
import theme from '../../../config/themes'

// Types
import { IChannel, RootNavigatorParamList } from '../../../types'
import { Routes } from '../../../config/routes'
type LeaveChannelOverlayProps = {}

const LeaveChannelOverlay = forwardRef(({}: LeaveChannelOverlayProps, ref) => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [item, setItem] = useState<IChannel | null>()
  const [currentUserId, setCurrentUserId] = useState<string>('')
  const [realUserId, setRealUserId] = useState<string>('')

  useImperativeHandle(ref, () => ({
    setIsActive(isActive: boolean, item: IChannel, currentUserId: string, realUserId: string) {
      if (isActive && item && currentUserId) {
        setItem(item)
        setCurrentUserId(currentUserId)
        setRealUserId(realUserId)
        setIsActive(true)
      }
    },
  }))

  const confirmLeaveChannel = () => {
    if (!item) {
      return
    }
    setIsLoading(true)
    dispatch(
      leaveChannel({
        userId: currentUserId,
        realUserId: realUserId,
        channel: item,
        onSuccess() {
          navigation.navigate(Routes.Channels)
          setIsLoading(false)
          setIsActive(false)
          toast.show(`Samtalen «${item.title}» ble forlot.`, {
            type: 'main',
            data: {
              title: 'Forlot samtalen!',
              icon: 'success',
            },
          })
        },
        onError() {
          toast.show('Beklager, noe gikk galt.')
          setIsLoading(false)
          setIsActive(false)
        },
      })
    )
  }

  if (!item) {
    return null
  }

  return (
    <Overlay
      isVisible={isActive}
      onBackdropPress={() => {
        if (!isLoading) {
          setIsActive(false)
        }
      }}
      overlayStyle={{ width: 320, maxWidth: '90%' }}
    >
      <View style={{ marginVertical: 10 }}>
        <Text h4>Forlater samtalen?</Text>
        <Text style={{ marginTop: 10 }}>
          Er du sikker på at du forlater samtalen{' '}
          <Text style={{ fontFamily: 'FSJoeyMedium' }}>«{item.title}</Text>»? Dette kan ikke angres.
        </Text>
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'flex-end',
          marginTop: 10,
        }}
      >
        <Button
          title="Forlate"
          onPress={confirmLeaveChannel}
          buttonStyle={[
            {
              width: 150,
              backgroundColor: theme.colors.white,
              borderWidth: 1,
              borderColor: theme.colors.primary,
              borderRadius: 10,
            },
          ]}
          titleStyle={{ color: theme.colors.primary }}
          loading={isLoading}
          loadingProps={{ color: theme.colors.primary, size: 14 }}
        />
        <Button
          title="Avbryt"
          onPress={() => setIsActive(false)}
          type="clear"
          buttonStyle={[theme._.ButtonStyle.Clear, { width: 100 }]}
          titleStyle={{ color: theme.colors.black }}
          disabled={isLoading}
        />
      </View>
    </Overlay>
  )
})

export default LeaveChannelOverlay
