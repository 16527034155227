import React, { useState, useEffect } from 'react'
import { Input } from '@rneui/themed'
import { Platform, StyleSheet, View } from 'react-native'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Firebase
import { usersCol } from '../../api/firebase'
import { doc, setDoc } from 'firebase/firestore'
import { fetchUser } from '../../slices/userSlice'

// Theme
import theme from '../../config/themes'

// Components
import SubmitButton from './SubmitButton'

// Helpers
import { xConsole } from '../../plugins/helpers/xConsole'

// Types
type NameFormProps = {
  onSuccess: () => void
}

function NameForm({ onSuccess }: NameFormProps) {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)

  const [name, setName] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (user) {
      setName(user.fullName)
      onChangeText(user.fullName)
    }
  }, [user])

  // Validation
  const nameRegex = new RegExp('.{2,}$')
  const onChangeText = (s: string): void => {
    setName(s)
    setIsValid(!!s && nameRegex.test(s))
  }

  // Submit
  const onSubmit = async (): Promise<void> => {
    if (!user) {
      return
    }
    toast.hideAll()
    if (!isValid) {
      toast.show('Feltet er obligatorisk.', {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
      return
    }

    try {
      setLoading(true)
      const docRef = doc(usersCol, user.id)
      await setDoc(docRef, { displayName: name }, { merge: true })
      dispatch(fetchUser())
      setLoading(false)
      onSuccess()
    } catch (error: any) {
      toast.show(error.message, {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
      xConsole().error(error as Error, 'NameForm.tsx (onSubmit)')
    }
  }
  return (
    <View>
      <Input
        placeholder="Navn"
        value={name}
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmit}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
      />
      <SubmitButton title="GÅ VIDERE" onPress={onSubmit} loading={loading} valid={isValid} />
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})

export default NameForm
