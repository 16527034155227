import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { Button, ButtonProps } from '@rneui/themed'

import resetPassword from '../../api/auth/resetPassword'
import { xConsole } from '../../plugins/helpers/xConsole'

type ResetPasswordButtonProps = {
  phoneNumber: string
  buttonProps?: ButtonProps
}
function ResetPasswordButton({ phoneNumber = '', buttonProps = {} }: ResetPasswordButtonProps) {
  const [isDisabled, setIsDisabled] = useState(false)
  const onPress = async () => {
    try {
      setIsDisabled(true)
      const res = await resetPassword(phoneNumber)
      if (res.status === 200) {
        toast.show(`Vi har sent deg en link for å tilbakestille passordet ditt via SMS`)
      } else {
        toast.show('Beklager, noe gikk galt.')
        setIsDisabled(false)
      }
    } catch (error) {
      toast.show('Beklager, noe gikk galt.')
      setIsDisabled(false)
      xConsole().error(error as Error, 'ResetPasswordButton.tsx (Main)')
    }
  }
  return <Button title={'Glemt passord'} {...buttonProps} disabled={isDisabled} onPress={onPress} />
}

const styles = StyleSheet.create({})

export default ResetPasswordButton
