import { useEffect, useCallback, useRef } from 'react'
import { AppState } from 'react-native'
import { debounce } from 'lodash'

// Firebase
import { auth, usersCol } from '../api/firebase'
import { doc, setDoc } from 'firebase/firestore'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../store/rootReducer'
import { fetchUser } from '../slices/userSlice'
import { fetchChannels } from '../slices/channelsSlice'

// Helpers
import getChannelKeys from '../plugins/helpers/getChannelKeys'
import { xConsole } from '../plugins/helpers/xConsole'

// Variables
const mainRunnerRefreshTime = 15 * 1000 // 15
const subRunnerMaximumRefreshTime = 60 * 1000 // 60

// Types
import { Routes } from '../config/routes'
import type { AppStateStatus } from 'react-native'

const DataRefresher = ({ navigation }) => {
  const dispatch = useDispatch()
  const intervalRef = useRef<any>()
  const { user, device } = useSelector((state: RootState) => state.user)

  const mainRunner = useCallback(async () => {
    try {
      const currentUser = auth.currentUser
      const isAllowedToRun =
        currentUser && user && !AppState.currentState.match(/inactive|background/) // If user logged in && the app is active (not minimize)
      if (isAllowedToRun) {
        // Set lastSeen (timestamp) to /users/{id}/
        const docRef = doc(usersCol, user.id)
        await setDoc(docRef, { lastSeen: Date.now() + device.timeOffset }, { merge: true })

        // Fetch new pending anonymous channels
        // dispatch(fetchAnonymousChannels())

        // Run subRunner() with maximum runtime
        subRunner()
      }
    } catch (error) {
      xConsole().error(error as Error, 'DataRefresher.tsx (mainRunner)')
    }
  }, [])

  const subRunner = debounce(
    () => {
      try {
        const currentUser = auth.currentUser
        const isAllowedToRun = currentUser && !AppState.currentState.match(/inactive|background/) // If user logged in && the app is active (not minimize)
        if (isAllowedToRun) {
          dispatch(fetchUser())
          dispatch(fetchChannels())
        }
      } catch (error) {
        xConsole().error(error as Error, 'DataRefresher.tsx (subRunner)')
      }
    },
    subRunnerMaximumRefreshTime,
    { maxWait: 0 }
  )

  // Do interval
  useEffect(() => {
    intervalRef.current = setInterval(mainRunner, mainRunnerRefreshTime)
    return () => clearInterval(intervalRef.current)
  }, [user, intervalRef])

  // On AppState changes
  const onAppStateChanged = (state: AppStateStatus) => {
    if (state === 'active') {
      dispatch(fetchUser())
      dispatch(fetchChannels())
    }
  }
  useEffect(() => {
    const listener = AppState.addEventListener('change', onAppStateChanged)
    return () => {
      listener.remove()
    }
  }, [])

  // On Route changes
  const onNavigationStateChanged = debounce(async () => {
    const currentRoute = navigation.getCurrentRoute()
    if (currentRoute.name === Routes.FAQ) {
      getChannelKeys()
    }
  }, 500)
  useEffect(() => {
    const unsubscribe = navigation.addListener('state', onNavigationStateChanged)
    return unsubscribe
  }, [])

  return null
}

export default DataRefresher
