import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { StyleSheet, View, TextInput } from 'react-native'
import { Text, Button, Overlay } from '@rneui/themed'
import { IOrganizationUser } from '../../../../types'

// Components
import Avatar from '../../../Avatar'

// Theme
import theme from '../../../../config/themes'

//Types
type ShareContactsOverlayProps = {
  item: IOrganizationUser | null
  trigger: Function
}

const ShareContactsOverlay = forwardRef(({ item, trigger }: ShareContactsOverlayProps, ref) => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState('')

  useImperativeHandle(ref, () => ({
    setIsActive,
    setIsLoading,
  }))

  const onChangeText = (text: string) => {
    setText(text)
  }

  return (
    <Overlay
      isVisible={isActive}
      onBackdropPress={() => {
        if (!isLoading) {
          setIsActive(false)
        }
      }}
      overlayStyle={styles.overlay}
    >
      {item && (
        <View style={styles.mainContainer}>
          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <Avatar
              photoURL={item.photoURL}
              placeholderTitle={item.title}
              statusColor={item.statusColor}
              props={{
                size: 45,
                containerStyle: { marginRight: 15 },
              }}
            />
            <View>
              <Text h4 h4Style={{ fontSize: 18, lineHeight: 20 }}>
                {item.fullName}
              </Text>
              <Text>{item.title}</Text>
            </View>
          </View>
          <TextInput
            placeholder={`Fortell gjerne kort hva hen trenger hjelp til eller lurer på. Dette vil kun vises til ${item.fullName}.`}
            multiline={true}
            onChangeText={onChangeText}
            style={styles.textInput}
            placeholderTextColor={'#666666'}
            editable={!isLoading}
          />
          <Button
            title="DEL KONTAKT"
            buttonStyle={styles.button}
            titleStyle={styles.buttonTitle}
            loading={isLoading}
            onPress={() => trigger(text)}
          />
          <Button
            title="AVBRYT"
            buttonStyle={styles.button}
            titleStyle={[styles.buttonTitle, { color: theme.colors.black }]}
            type="clear"
            onPress={() => setIsActive(false)}
            disabledStyle={{ opacity: 0.25 }}
            disabled={isLoading}
          />
        </View>
      )}
    </Overlay>
  )
})

export default ShareContactsOverlay

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: '#f7f7f7',
  },
  mainContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  textInput: {
    height: 150,
    width: 300,
    marginVertical: 10,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    textAlignVertical: 'top',
    backgroundColor: '#ffffff',
  },
  button: {
    width: 300,
    paddingVertical: 10,
  },
  buttonTitle: {
    fontFamily: 'FSJoey',
    color: theme.colors.white,
  },
})
