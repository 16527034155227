import { firebaseFunction } from '../../api/firebase'
import { xConsole } from '../../plugins/helpers/xConsole'
import { IApiResponse } from '../../types'

// Firebase
import { auth } from '../../api/firebase'

async function setPassword(password: string): Promise<IApiResponse> {
  try {
    try {
      const currentUser = auth.currentUser
      if (currentUser) {
        const token = await currentUser.getIdToken(true)
        const res = await firebaseFunction({
          endpoint: '/setPassword',
          payload: { password, token },
        })
        if (res.status === 200 && res.data.success === 'SET_PASSWORD') {
          return { statusText: 'SUCCESS', status: 200 } as IApiResponse
        }
      }
    } catch (error) {
      xConsole().error(error as Error, 'setPassword.ts (API)')
    }
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'setPassword.ts (Main)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default setPassword
