import React from 'react'
import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenTitle from '../../components/ScreenTitle'
import ListOrganizations from '../../components/contacts/other/List'

const OtherContactsScreen = () => {
  return (
    <View style={{ flex: 1 }}>
      <View>
        <ScreenTitle title="Nasjonale tjenester" />
        <Text style={{ marginHorizontal: 20, fontSize: 20 }}>
          Her finner du mange av Norges beste hjelpetjenester.
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <ListOrganizations />
      </View>
    </View>
  )
}

export default OtherContactsScreen
