import { View } from 'react-native'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'
import PrivacyContent from '../../components/settings/PrivacyContent'

const SettingsPrivacyScreen = () => {
  return (
    <ScreenSubpageLayout title="Personvernerklæring">
      <View style={{ marginTop: 15, marginBottom: 30 }}>
        <PrivacyContent />
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsPrivacyScreen
