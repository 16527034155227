import { StyleSheet, View } from 'react-native'
import { Text } from '@rneui/themed'

import Loading from '../Loading'

// Types
type EmptyChannelsProps = {
  loading: boolean
  loadingPercentage: number
}

const ChannelEmpty = ({ loading, loadingPercentage }: EmptyChannelsProps) => {
  return (
    <View style={styles.mainContainer}>
      <View>
        {loading ? (
          <Loading text={`Laster inn dine meldinger (${loadingPercentage})%`} />
        ) : (
          <>
            <Text style={styles.text}>
              Hvis du har et spørsmål - lite eller stort er det mange her som kan hjelpe deg.
            </Text>
          </>
        )}
      </View>
    </View>
  )
}

export default ChannelEmpty

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    padding: 20,
    fontSize: 20,
    textAlign: 'center',
  },
})
