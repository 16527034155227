import { auth } from '../../api/firebase'
import { signOut } from 'firebase/auth'
import { xConsole } from '../../plugins/helpers/xConsole'

import { IApiResponse } from '../../types'

async function logout(getState?: Function): Promise<IApiResponse> {
  try {
    // Check all unsubscriber functions in Store (Firebase)
    try {
      if (getState) {
        const state = getState()
        for (const [firstLevelStateKey, firstLevelStateValue] of Object.entries(state)) {
          for (const [key, value] of Object.entries(firstLevelStateValue as any)) {
            if (key.startsWith('unsubscribe') && typeof value === 'function') {
              await value()
            }
          }
        }
      }
    } catch (error) {
      xConsole().error(error as Error, 'logout.ts (Remove Redux unsubscribers data)')
    }
    // SignOut
    return signOut(auth).then(() => {
      return { statusText: 'SUCCESS', status: 200 } as IApiResponse
    })
  } catch (error) {
    xConsole().error(error as Error, 'logout.ts (Main)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default logout
