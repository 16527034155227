import React, { useEffect, useState, useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text } from '@rneui/themed'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { fetchAllOrganizations } from '../../slices/organizationsSlice'

const SettingsSelectedOrganizations = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  const { organizations } = useSelector((state: RootState) => state.organizations.all)
  const [selectedOrganizations, setSelectedOrganizations] = useState<Array<string>>([])

  if (!user) {
    return null
  }

  const listOrganizations = useCallback(() => {
    const items: Array<string> = []
    user.organizations.forEach((id) => {
      const found = organizations.find((v) => v.id === id)
      if (found) {
        items.push(found.organizationName || id)
      }
    })
    setSelectedOrganizations(items)
  }, [user.organizations, organizations])

  useEffect(() => {
    dispatch(fetchAllOrganizations())
  }, [])

  useEffect(() => {
    listOrganizations()
  }, [organizations, user.organizations])

  return (
    <View>
      {selectedOrganizations.length ? (
        <>
          <Text style={styles.title}>Du har valgt disse:</Text>
          {selectedOrganizations.map((item, index) => (
            <Text style={styles.value} key={index}>
              {item}
            </Text>
          ))}
        </>
      ) : (
        <>
          {user.organizations.length ? (
            <>
              <Text style={styles.title}>Du har valgt disse:</Text>
              <Text style={styles.value}>…</Text>
            </>
          ) : (
            <Text style={styles.title}>Du har ikke valgt noen tjeneste.</Text>
          )}
        </>
      )}
    </View>
  )
}

export default SettingsSelectedOrganizations

const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    fontFamily: 'FSJoeyMedium',
    marginBottom: 15,
  },
  value: {
    fontSize: 18,
  },
})
