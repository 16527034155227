import React from 'react'
import { View, StyleSheet, Linking } from 'react-native'
import { Text, Image, Button } from '@rneui/themed'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootReducer'
import theme from '../../../config/themes'

const data = {
  '3OAsXP2ASts11AsaXBa6': {
    logoUri: 'https://profile.kopling.no/img/logos/v4/schools/al-videregaende-skole.png',
    link: 'https://bit.ly/3Ti7WML',
  },
  TOLjtoo1crHO7nXKXC3lz: {
    logoUri: 'https://profile.kopling.no/img/logos/v4/schools/eikeli-videregaende-skole.png',
    link: 'https://bit.ly/3M85dT6',
  },
}

function CanteenWidget() {
  const { user } = useSelector((state: RootState) => state.user)
  if (!user || !user.organizations || !user.organizations.length) {
    return null
  }

  const renderWidget = (id: string) => {
    if (data[id]) {
      return (
        <View style={styles.mainContainer} key={id}>
          <View style={styles.headerContainer}>
            <Text h4 h4Style={{ fontSize: 20 }}>
              Din kantine
            </Text>
            <Image
              source={{
                uri: data[id].logoUri,
              }}
              containerStyle={styles.logoContainer}
              resizeMode={'contain'}
            />
          </View>
          <Button
            title="BESTILL MAT"
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.button}
            titleStyle={styles.buttonTitle}
            onPress={() => {
              Linking.openURL(data[id].link)
            }}
          />
        </View>
      )
    }
    return null
  }

  return user.organizations.map((v) => renderWidget(v))
}

export default CanteenWidget

const styles = StyleSheet.create({
  mainContainer: {
    margin: 20,
    padding: 25,
    backgroundColor: theme.colors.white,
    borderRadius: 10,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logoContainer: {
    width: 90,
    height: 22,
  },
  buttonContainer: {
    marginTop: 15,
  },
  button: {
    backgroundColor: theme.colors.primary,
  },
  buttonTitle: {
    color: theme.colors.white,
  },
})
