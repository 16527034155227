import React, { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, ScrollView, ActivityIndicator, Linking } from 'react-native'
import { Header as HeaderRNE, Image, Text, Button } from '@rneui/themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import { BottomSheetModalProvider } from '@gorhom/bottom-sheet'

// Components
import ScreenTitle from '../../components/ScreenTitle'
import ListOrganizationUsers from '../../components/contacts/other/Users'

// Themes
import theme from '../../config/themes'

// Types
import { RootNavigatorParamList } from '../../types'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Routes } from '../../config/routes'

type OtherContactsProfileScreenProps = NativeStackScreenProps<
  RootNavigatorParamList,
  Routes.OtherContactsProfile
>

function OtherContactsProfileScreen({ route, navigation }: OtherContactsProfileScreenProps) {
  const { item } = route.params
  const [userIds, setUserIds] = useState<Array<string>>([])
  const [isImageError, setIsImageError] = useState(false)
  useEffect(() => {
    // Get users data by this organize
    if (item.users.length) {
      setUserIds(item.users.map((a) => a.id))
    }
  }, [])
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <BottomSheetModalProvider>
        <ScreenTitle goBack />
        <ScrollView contentContainerStyle={{ flex: 1 }}>
          {!isImageError && (
            <View style={styles.logoContainer}>
              <Image
                source={{
                  uri: `https://profile.kopling.no/img/logos/v4/horizontal/${item.id}.png`,
                }}
                containerStyle={styles.logoImageContainer}
                PlaceholderContent={<ActivityIndicator />}
                placeholderStyle={styles.logoImagePlaceholder}
                resizeMode={'contain'}
                onError={() => {
                  setIsImageError(true)
                }}
              />
            </View>
          )}

          <View style={styles.contentContainer}>
            <Text h4 style={styles.title}>
              {item.organizationName}
            </Text>
            <Text style={styles.description}>{item.description}</Text>
          </View>
          <View>
            {item.buttons.map((button: any, i: number) => (
              <Button
                key={i}
                title={
                  <View style={{ flexDirection: 'column', maxWidth: '80%' }}>
                    {button.title && (
                      <Text style={styles.buttonTitle} numberOfLines={2}>
                        {button.title}
                      </Text>
                    )}
                    {button.subtitle && !userIds.length && (
                      <Text style={styles.buttonSubtitle} numberOfLines={2}>
                        {button.subtitle}
                      </Text>
                    )}
                  </View>
                }
                onPress={() => Linking.openURL(button.link)}
                icon={{
                  name: 'link',
                  type: 'entypo',
                  size: userIds.length ? 40 : 35,
                  color: '#666666',
                }}
                iconContainerStyle={{
                  padding: 10,
                  marginRight: 10,
                }}
                titleStyle={{ fontWeight: '700' }}
                containerStyle={styles.buttonContainer}
                buttonStyle={[styles.button, { paddingVertical: userIds.length ? 0 : 20 }]}
              />
            ))}
          </View>
          {userIds.length ? <ListOrganizationUsers users={userIds} /> : null}
        </ScrollView>
      </BottomSheetModalProvider>
    </SafeAreaView>
  )
}

export default OtherContactsProfileScreen

const styles = StyleSheet.create({
  logoContainer: {
    alignItems: 'center',
    paddingVertical: 25,
    marginVertical: 10,
    marginHorizontal: 20,
    borderRadius: 20,
    backgroundColor: theme.colors.white,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  logoImageContainer: {
    width: '100%',
    height: 100,
  },
  logoImagePlaceholder: {
    height: '100%',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  contentContainer: {
    marginVertical: 5,
    marginHorizontal: 20,
  },
  title: {
    textAlign: 'center',
    marginBottom: 5,
  },
  description: {
    fontSize: 18,
    textAlign: 'center',
  },
  buttonContainer: {
    marginVertical: 10,
    marginHorizontal: 20,
  },
  button: {
    backgroundColor: theme.colors.bluelight,
    justifyContent: 'flex-start',
    borderRadius: 8,
  },
  buttonTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
    color: '#2b2c2b',
  },
  buttonSubtitle: {
    color: '#666666',
  },
})
