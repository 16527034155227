import { StyleSheet, ScrollView, KeyboardAvoidingView } from 'react-native'
import { Text } from '@rneui/themed'
import { SafeAreaView } from 'react-native-safe-area-context'

// Themes
import theme from '../../config/themes'

// Components
import ScreenTitle from '../../components/ScreenTitle'
import TabMenus from '../../components/TabMenus'

// Types
type SettingsScreenSubpageLayoutProps = {
  title?: string
  children: React.ReactNode
  withKeyboardAvoidingView?: boolean
}

const SettingsScreenSubpageLayout = ({
  title,
  children,
  withKeyboardAvoidingView = false,
}: SettingsScreenSubpageLayoutProps) => {
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScreenTitle goBack />
      <ScrollView contentContainerStyle={styles.viewContainer}>
        {!!title && (
          <Text h3 h3Style={styles.title}>
            {title}
          </Text>
        )}
        {withKeyboardAvoidingView ? (
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={'position'}
            contentContainerStyle={{ backgroundColor: theme.colors.bodybg }}
          >
            <>{children}</>
          </KeyboardAvoidingView>
        ) : (
          <>{children}</>
        )}
      </ScrollView>
      <TabMenus />
    </SafeAreaView>
  )
}

export default SettingsScreenSubpageLayout

const styles = StyleSheet.create({
  viewContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  title: {
    fontFamily: 'FSJoey',
  },
})
