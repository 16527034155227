import React, { useRef, useMemo, forwardRef, useCallback, useImperativeHandle } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { Button } from '@rneui/themed'

import {
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetBackdrop,
} from '@gorhom/bottom-sheet'

// Theme
import theme from '../../../config/themes'

// Components
import OrganizationList from './List'
type OrganizationSelectorProps = {
  bottomSheetTopInset?: number
}

// Variables
const { height } = Dimensions.get('window')

const OrganizationSelector = forwardRef(
  ({ bottomSheetTopInset = 0 }: OrganizationSelectorProps, ref) => {
    const bottomSheetModalRef = useRef<BottomSheetModal>(null)
    const snapPoints = useMemo(() => [height * 0.7], [])

    useImperativeHandle(ref, () => ({
      setIsActive(isActive: boolean) {
        if (isActive) {
          bottomSheetModalRef.current?.present()
        }
      },
    }))

    /*
    const renderBackdrop = useCallback(
      (props) => (
        <BottomSheetBackdrop
          {...props}
          disappearsOnIndex={-1}
          appearsOnIndex={0}
          style={[
            bottomSheetTopInset && {
              minHeight: height + 300,
              top: bottomSheetTopInset - 300,
            },
          ]}
        />
      ),
      []
    )
    */

    const handleDismissModalPress = useCallback(() => {
      bottomSheetModalRef.current?.dismiss()
    }, [])

    return (
      <BottomSheetModalProvider>
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={0}
          snapPoints={snapPoints}
          topInset={bottomSheetTopInset}
        >
          <View style={styles.bottomSheetHeader}>
            <Button
              title="LUKK"
              onPress={handleDismissModalPress}
              type="clear"
              buttonStyle={[
                theme._.ButtonStyle.Clear,
                { width: 75, paddingRight: 0, justifyContent: 'flex-end' },
              ]}
              titleStyle={{ color: theme.colors.primary }}
            />
          </View>
          <View style={{ flex: 1 }}>
            <OrganizationList />
          </View>
        </BottomSheetModal>
      </BottomSheetModalProvider>
    )
  }
)

export default OrganizationSelector

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
})
