import { useEffect, useState } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { Text } from '@rneui/themed'
import { StackActions } from '@react-navigation/native'

// Components
import Avatar from '../Avatar'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Helpers
import { xConsole } from '../../plugins/helpers/xConsole'

// Themes
import theme from '../../config/themes'

// Types
import { IChannel } from '../../types'
type ToastNewMessageProps = {
  data: any
  navigation: any
}
import { Routes } from '../../config/routes'

const toastNewMessage = ({ data, navigation }: ToastNewMessageProps) => {
  const [text, setText] = useState('Du har fått en melding')
  const [photoURL, setPhotoURL] = useState('no-image')
  const { channels } = useSelector((state: RootState) => state.channels)
  const [channel, setChannel] = useState<IChannel | undefined>()

  const doPress = () => {
    try {
      if (channel) {
        navigation.dispatch(
          StackActions.push(Routes.Chat, {
            item: {
              id: channel.id,
              title: channel.title,
              description: channel.subtitle,
              photoURL: channel.photoURL,
              statusColor: channel.statusColor,
              isAnonymous: channel.isAnonymous,
            },
          })
        )
        toast.hideAll()
      }
    } catch (error) {
      xConsole().error(error as Error, 'NewMessage.tsx (onPress)')
    }
  }

  useEffect(() => {
    initData()
  }, [])

  const initData = () => {
    try {
      const currentRoute = navigation.getCurrentRoute()
      const message = data.message ? JSON.parse(data.message) : null
      const isInCurrentChannel =
        currentRoute.name === Routes.Chat && currentRoute.params.item.id === message.data.channelId

      const channel = channels.find((c) => c.id === message.data.channelId)
      if (!isInCurrentChannel && channel) {
        setChannel(channel)
        if (channel.title) {
          setText(`${channel.title} har sendt deg en melding`)
        }

        if (channel.photoURL) {
          setPhotoURL(channel.photoURL)
        }
      }
    } catch (error) {
      xConsole().error(error as Error, 'NewMessage.tsx (initData)')
    }
  }

  if (!channel) {
    return null
  }

  return (
    <View style={{ maxWidth: 350 }}>
      <Pressable onPress={doPress}>
        <View style={styles.mainContainer}>
          <Avatar
            photoURL={photoURL}
            placeholderTitle={channel?.title}
            isAnonymous={channel?.isAnonymous}
            props={{
              size: 22,
              containerStyle: [styles.avatar],
            }}
          />
          <View style={styles.textContainer}>
            <Text style={styles.text}>{text}</Text>
          </View>
        </View>
      </Pressable>
    </View>
  )
}

export default toastNewMessage

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#fff',
    paddingVertical: 5,
    paddingLeft: 5,
    paddingRight: 10,
    margin: 10,
    borderRadius: 25,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  avatar: {
    marginRight: 5,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  text: {
    fontFamily: 'FSJoey',
    fontSize: 14,
    lineHeight: 16,
  },
})
