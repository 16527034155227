import getApiChannels from '../api/channels/getApiChannels'
// import getApiAnonymousChannels from '../api/channels/getApiAnonymousChannels' // @ANO-UNCOMMENT
import createApiChannel from '../api/channels/createApiChannel'
import leaveApiChannel from '../api/channels/leaveApiChannel'
import { xConsole } from '../plugins/helpers/xConsole'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { fetchUsers } from '../slices/usersSlice'

// Types
import { IChannelsState, IChannel, IUserState, IChannelReference } from '../types'
const initialState: IChannelsState = {
  channels: [],
  keys: {},
  // anonymousChannels: {}, // @ANO-UNCOMMENT
  isLoading: false,
  loadingPercentage: 0,
  error: false,
  unsubscribe: null,
}

const channels = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    getChannels(state) {
      state.isLoading = true
      state.loadingPercentage = 0
      state.error = false
    },
    getChannelsSuccess(
      state,
      action: PayloadAction<{ channels: Array<IChannel>; unsubscribe: Function }>
    ) {
      const payload = action.payload
      payload.channels.forEach((v) => {
        // Store key for faster re-use in getChannelKey() src/plugins/helpers/index.ts
        if (v.key) {
          state.keys = { ...state.keys, ...{ [v.id]: v.key } }
        }
      })
      state.isLoading = false
      state.loadingPercentage = 100
      state.channels = payload.channels
      state.unsubscribe = payload.unsubscribe
    },
    getChannelsFailure(state) {
      state.isLoading = false
      state.loadingPercentage = 100
      state.error = true
    },
    setChannelKey(state, action: PayloadAction<{ id: string; key: string }>) {
      // @UNUSED for now
      const payload = action.payload
      state.keys = { ...state.keys, ...{ [payload.id]: payload.key } }
    },
    /* @ANO-UNCOMMENT
    setAnonymousChannel(state, action: PayloadAction<{ id: string; key: string }>) {
      const payload = action.payload
      state.anonymousChannels = { ...state.anonymousChannels, ...{ [payload.id]: payload.key } }
    },
    deleteAnonymousChannel(state, action: PayloadAction<{ id: string }>) {
      const payload = action.payload
      delete state.anonymousChannels[payload.id]
      state.anonymousChannels = { ...state.anonymousChannels }
    },
    */
    setChannelLoadingPercentage(state, action: PayloadAction<number>) {
      const payload = action.payload
      state.loadingPercentage = ~~payload
    },
  },
})

export const {
  getChannels,
  getChannelsSuccess,
  getChannelsFailure,
  setChannelKey,
  // setAnonymousChannel, // @ANO-UNCOMMENT
  // deleteAnonymousChannel, // @ANO-UNCOMMENT
  setChannelLoadingPercentage,
} = channels.actions

export default channels.reducer

export const fetchChannels = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user: userState }: IUserState = getState().user
    const { unsubscribe }: IChannelsState = getState().channels
    if (unsubscribe) {
      unsubscribe() // Unsubscribe for prev watcher
    }
    dispatch(getChannels())

    if (userState?.id) {
      getApiChannels({
        userId: userState.id,
        getState: getState,
        updateChannels: (channels: IChannel[], unsubscribe: Function) => {
          dispatch(getChannelsSuccess({ channels: channels, unsubscribe: unsubscribe }))
        },
        updateUsers: (users: Array<string>) => {
          return new Promise((resolve) => {
            try {
              dispatch(fetchUsers(users))
              let i = 0
              const interval = setInterval(() => {
                i++
                if (!getState().users.isLoading || i >= 10) {
                  clearInterval(interval)
                  resolve(true)
                }
              }, 500)
            } catch (error) {
              xConsole().error(
                error as Error,
                'channelsSlice.ts (fetchChannels->getApiChannels->updateUsers)'
              )
              resolve(true)
            }
          })
        },
        updateLoadingPercentage: (percent: number) => {
          dispatch(setChannelLoadingPercentage(percent))
        },
        /* @ANO-UNCOMMENT
        deleteAnonymousChannel: (id: string) => {
          dispatch(deleteAnonymousChannel({ id }))
        },
        getAnonymousChannelKey: (id: string) => {
          return new Promise((resolve) => {
            try {
              dispatch(fetchAnonymousChannels())
              let i = 0
              const interval = setInterval(() => {
                i++
                const data = getState().channels.anonymousChannels[id]
                if (data) {
                  resolve(data)
                } else {
                  if (!data || i >= 100) {
                    clearInterval(interval)
                    resolve('')
                  }
                }
              }, 50)
            } catch (error) {
              xConsole().error(
                error as Error,
                'channelsSlice.ts (fetchChannels->getApiChannels->getAnonymousChannelKey)'
              )
              resolve('')
            }
          })
        },
        */
      }) // Watch snapshots
    }
  } catch (error) {
    xConsole().error(error as Error, 'channelsSlice.ts (fetchChannels: Main)')
    dispatch(getChannelsFailure())
  }
}

/* @ANO-UNCOMMENT
interface IUpdateChannelsArgs {
  id: string
  key: string
}
export const fetchAnonymousChannels = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user: userState }: IUserState = getState().user
    if (userState?.id) {
      getApiAnonymousChannels({
        userId: userState.id,
        updateChannels: (channels: Array<IUpdateChannelsArgs>) => {
          for (const { id, key } of channels) {
            dispatch(setAnonymousChannel({ id, key }))
          }
        },
      }) // Fetch snapshots
    }
  } catch (error) {
    xConsole().error(error as Error, 'channelsSlice.ts (fetchAnonymousChannels: Main)')
  }
}
*/

interface ICreateChannelArgs {
  targetUserId: string
  isAnonymous: boolean
  firstMessage: string
  reference?: IChannelReference
  onSuccess: Function
  onError: Function
}
export const createChannel =
  (args: ICreateChannelArgs): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await createApiChannel({
        targetUserId: args.targetUserId,
        isAnonymous: args.isAnonymous,
        firstMessage: args.firstMessage,
        reference: args.reference,
        /* @ANO-UNCOMMENT
        setAnonymousChannel(data: any) {
          dispatch(setAnonymousChannel(data))
        },
        */
        dispatch: dispatch,
      })
      if (res.status === 200) {
        dispatch(fetchChannels())
        let i = 0
        const interval = setInterval(() => {
          i++
          const { channels } = getState().channels
          const currentChannel = channels.find((c) => c.id === res.data.id)
          if (currentChannel || i >= 20) {
            clearInterval(interval)
            args.onSuccess(res.data.id)
          }
        }, 500)
      } else {
        args.onError()
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'channelsSlice.ts (createChannel)')
      args.onError(error.message)
    }
  }

interface ILeaveChannelArgs {
  userId: string
  realUserId: string
  channel: IChannel
  onSuccess: Function
  onError: Function
}
export const leaveChannel =
  (args: ILeaveChannelArgs): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await leaveApiChannel({
        userId: args.userId,
        realUserId: args.realUserId,
        channel: args.channel,
      })
      if (res.status === 200) {
        let i = 0
        const interval = setInterval(() => {
          i++
          const { channels } = getState().channels
          const currentChannel = channels.find((c) => c.id === res.data.id)

          if (!currentChannel) {
            // If channel not found (leaved/removed)
            clearInterval(interval)
            args.onSuccess(res.data.id)
          } else if (i >= 20) {
            clearInterval(interval)
            args.onError()
          }
        }, 500)
      } else {
        args.onError()
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'channelsSlice.ts (leaveChannel)')
      args.onError(error.message)
    }
  }
