import { AnyAction } from 'redux'
import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import userReducer from '../slices/userSlice'
import usersReducer from '../slices/usersSlice'
import channelsReducer from '../slices/channelsSlice'
import messagesReducer from '../slices/messagesSlice'
import organizationsReducer from '../slices/organizationsSlice'
import faqReducer from '../slices/faqSlice'

const appReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  channels: channelsReducer,
  messages: messagesReducer,
  organizations: organizationsReducer,
  faq: faqReducer,
})

const rootReducer = (state: ReturnType<typeof appReducer>, action: AnyAction) => {
  if (action.type === 'CLEAR_ALL') {
    return appReducer(undefined, action) // Clear all data
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
