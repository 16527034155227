import React from 'react'
import { StyleSheet } from 'react-native'
import { ListItem } from '@rneui/themed'

// Components
import Avatar from '../../Avatar'

// Themes
import theme from '../../../config/themes'

// Types
import { IOrganizationOther } from '../../../types'
type ListContactItemProps = {
  item: IOrganizationOther
  onPress: any
}
const ListOrganizationItem = ({ item, onPress }: ListContactItemProps) => {
  return (
    <ListItem
      key={item.id}
      onPress={onPress}
      bottomDivider
      containerStyle={styles.itemListContainer}
    >
      <Avatar
        photoURL={`https://profile.kopling.no/img/logos/v4/square/${item.id}.png`}
        placeholderTitle={item.organizationName}
        props={{
          size: 52,
          rounded: false,
          imageProps: { resizeMode: 'contain' },
        }}
      ></Avatar>
      <ListItem.Content>
        <ListItem.Title numberOfLines={1}>{item.organizationName}</ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>{item.description}</ListItem.Subtitle>
      </ListItem.Content>
    </ListItem>
  )
}

export default ListOrganizationItem

const styles = StyleSheet.create({
  itemListContainer: {
    height: 73,
    paddingHorizontal: 20,
    backgroundColor: theme.colors.bodybg,
  },
})
