import React, { useEffect, useState, forwardRef, useCallback, useImperativeHandle } from 'react'
import { Platform, Dimensions, StyleSheet, View, ActivityIndicator } from 'react-native'
import { Text } from '@rneui/themed'
import { BottomSheetFlatList } from '@gorhom/bottom-sheet'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { debounce } from 'lodash'
import { xConsole } from '../../../plugins/helpers/xConsole'

// Firebase
import { usersCol } from '../../../api/firebase'
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store/rootReducer'
import { fetchUser } from '../../../slices/userSlice'
import { fetchAllOrganizations } from '../../../slices/organizationsSlice'

// Components
import SearchOrganizations from './Search'
import OrganizationItem from './Item'

// Theme
import theme from '../../../config/themes'

// Types
import { IOrganizationAll } from '../../../types'
type OrganizationSelectorProps = {}

// Variables
const { height } = Dimensions.get('window')

const OrganizationList = forwardRef(({}: OrganizationSelectorProps, ref) => {
  const dispatch = useDispatch()
  const [isActive, setIsActive] = useState(false)
  const insets = useSafeAreaInsets()

  useImperativeHandle(ref, () => ({
    setIsActive,
  }))

  // State
  const { user } = useSelector((state: RootState) => state.user)
  const { organizations, indices } = useSelector((state: RootState) => state.organizations.all)
  const [organizationsFiltered, setOrganizationsFiltered] = useState<IOrganizationAll[]>([])

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchAllOrganizations())
  }, [])

  const onSelect = async (active: boolean, id: string) => {
    if (!user) {
      return
    }
    const docRef = doc(usersCol, user.id)
    if (active) {
      await updateDoc(docRef, {
        institutions: arrayUnion(id),
      })
    } else {
      await updateDoc(docRef, {
        institutions: arrayRemove(id),
      })
    }
    toast.hideAll()
    toast.show('Lagret!')
    dispatch(fetchUser())
  }

  const renderItem = useCallback(
    ({ item }) => {
      let isActive = user ? user.organizations.includes(item.id) : false
      return (
        <OrganizationItem
          item={item}
          onPress={(active, id) => onSelect(active, id)}
          active={isActive}
        />
      )
    },
    [user]
  )

  const onSearch = useCallback(
    debounce((s: string) => {
      try {
        if (s.length) {
          const result = organizations.filter(
            (a) =>
              !!a.organizationName && a.organizationName.toLowerCase().includes(s.toLowerCase())
          )
          setOrganizationsFiltered(result)
        } else {
          setOrganizationsFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'organizations/selector/List.tsx (onSearch)')
      }
    }, 1000),
    []
  )

  return (
    <View style={styles.listContainer}>
      <Text h4 style={{ paddingHorizontal: 15 }}>
        Legg til flere skoler og tjenester
      </Text>
      <SearchOrganizations
        onSearch={(s: string) => {
          onSearch(s)
        }}
      />
      {organizations.length ? (
        <BottomSheetFlatList
          data={organizationsFiltered.length > 0 ? organizationsFiltered : organizations}
          keyExtractor={(item: any, index: any) => index}
          renderItem={renderItem}
          stickyHeaderIndices={organizationsFiltered.length > 0 ? [] : indices}
          style={{ marginTop: 10 }}
          contentInset={{ bottom: insets.bottom }}
        />
      ) : (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
          <ActivityIndicator color={theme.colors.black} />
        </View>
      )}
    </View>
  )
})

export default OrganizationList

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 200,
  },
  contentContainer: {
    backgroundColor: 'white',
  },
  itemContainer: {
    padding: 6,
    margin: 6,
    backgroundColor: '#eee',
  },
  listContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        maxHeight: height * 0.7 - 70,
      },
    }),
  },
})
