/*
  xConsole with Crashlytics and console as fallback for web
  Params: null
  Return: nothing
*/

import { Platform } from 'react-native'
import Constants from 'expo-constants'

const isCrashlyticsSupported = () => {
  return !(
    Platform.OS === 'web' ||
    Constants.appOwnership === 'expo' ||
    Constants.sessionId === 'mock'
  )
}

const xConsole = () => {
  const _isCrashlyticsSupported = isCrashlyticsSupported()
  const crashlytics = _isCrashlyticsSupported
    ? require('@react-native-firebase/crashlytics').default
    : () => {}

  return {
    log(s: string) {
      if (_isCrashlyticsSupported) {
        crashlytics().log(s)
      }
      console.log(s)
    },
    setUserId(id: string) {
      if (_isCrashlyticsSupported) {
        crashlytics().setUserId(id)
      }
    },
    error(error: Error, info?: string) {
      if (_isCrashlyticsSupported) {
        crashlytics().recordError(error)
        if (info) {
          crashlytics().log(`${error && error.message ? error.message + ' :' : ''}${info}`)
        }
      }
      console.log(error)
    },
    crash() {
      if (_isCrashlyticsSupported) {
        crashlytics().crash()
      }
    },
  }
}

export { xConsole }
