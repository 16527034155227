import React from 'react'
import { Message } from 'react-native-gifted-chat'

// Types
import type { IMessage } from '../../types'
import type { MessageProps } from 'react-native-gifted-chat'
type ChatMessageProps = MessageProps<IMessage>

function ChatMessage(props: ChatMessageProps) {
  const { nextMessage } = props
  const isLastMessage = nextMessage && !nextMessage._id

  return (
    <Message
      {...props}
      containerStyle={{
        left: {
          marginBottom: !isLastMessage ? 0 : 20,
          marginLeft: 20,
        },
        right: {
          marginBottom: !isLastMessage ? 0 : 20,
          marginRight: 20,
        },
      }}
    />
  )
}

export default ChatMessage
