import React from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Image, Text } from '@rneui/themed'
import { useNavigation, NavigationProp, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// Types
import { RootNavigatorParamList } from '../types'
import { Routes } from '../config/routes'

type ITabList = {
  label: string
  icon: string
  iconActive: string
  destination: Routes
  destinationParams?: object
}

const tabList: ITabList[] = [
  {
    label: 'LURER PÅ…',
    icon: require('../assets/images/tabmenus/faq.png'),
    iconActive: require('../assets/images/tabmenus/faq-active.png'),
    destination: Routes.FAQ,
  },
  {
    label: 'MELDINGER',
    icon: require('../assets/images/tabmenus/chat.png'),
    iconActive: require('../assets/images/tabmenus/chat-active.png'),
    destination: Routes.Channels,
  },
  {
    label: 'HJELPERE',
    icon: require('../assets/images/tabmenus/organizations.png'),
    iconActive: require('../assets/images/tabmenus/organizations-active.png'),
    destination: Routes.Contacts,
    destinationParams: { screen: Routes.UserContacts },
  },
  {
    label: 'PROFIL',
    icon: require('../assets/images/tabmenus/profile.png'),
    iconActive: require('../assets/images/tabmenus/profile-active.png'),
    destination: Routes.Settings,
  },
]

const TabMenus = () => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const route = useRoute()
  const insets = useSafeAreaInsets()

  const onTabPress = (item: ITabList) => {
    navigation.navigate(item.destination, item.destinationParams || {})
  }

  return (
    <View
      style={[styles.mainContainer, { marginBottom: -insets.bottom, paddingBottom: insets.bottom }]}
    >
      <View style={styles.tabContainer}>
        {tabList.map((item: any, i: number) => (
          <TouchableOpacity style={[styles.tabItem]} onPress={() => onTabPress(item)} key={i}>
            <Image
              source={
                route.name === item.destination ||
                (route.name.includes('Settings') && item.destination === Routes.Settings)
                  ? item.iconActive
                  : item.icon
              }
              style={styles.tabIcon}
              placeholderStyle={{ backgroundColor: 'transparent' }}
            />
            <Text style={styles.tabLabel}>{item.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

export default TabMenus

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#e8e5df',
  },
  tabContainer: {
    flexDirection: 'row',
    height: 62, // to know the height
  },
  tabItem: {
    paddingVertical: 7,
    flexGrow: 1,
    flexBasis: 0,
    alignItems: 'center',
  },
  tabIcon: {
    aspectRatio: 1,
    width: 32,
    height: 32,
  },
  tabLabel: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 11,
  },
})
