import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'
import { useActionSheet } from '@expo/react-native-action-sheet'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/rootReducer'

// Helpers
import getChannelCurrentUserId from '../../../plugins/helpers/getChannelCurrentUserId'

// Components
import LeaveChannelOverlay from './LeaveChannelOverlay'

// Types
import { IChannel, ILeaveChannelOverlayRef } from '../../../types'
type ChannelSettingsOverlayProps = {}

const ChannelSettingsOverlay = forwardRef(({}: ChannelSettingsOverlayProps, ref) => {
  const { showActionSheetWithOptions } = useActionSheet()
  const { user } = useSelector((state: RootState) => state.user)
  const { channels } = useSelector((state: RootState) => state.channels)

  const leaveChannelOverlayRef = useRef<ILeaveChannelOverlayRef>(null)

  useImperativeHandle(ref, () => ({
    async setIsActive(isActive: boolean, id: string) {
      if (isActive && id && user) {
        Keyboard.dismiss()
        const channel = channels.find((c) => c.id === id)
        if (channel) {
          const currentUserId = getChannelCurrentUserId({
            channel: channel,
            realUserId: user.id,
          })

          showActionSheetWithOptions(
            {
              title: channel.title,
              options: ['Forlate samtalen', 'Avbryt'],
              cancelButtonIndex: 1,
              destructiveButtonIndex: 0,
            },
            (buttonIndex) => {
              if (buttonIndex === 0) {
                leaveChannel(channel, currentUserId, user.id)
              }
            }
          )
        } else {
          toast.show('Beklager, noe gikk galt.', {
            type: 'main',
            data: {
              title: 'Obs!',
              icon: 'error',
            },
          })
        }
      } else {
        toast.show('Beklager, noe gikk galt.', {
          type: 'main',
          data: {
            title: 'Obs!',
            icon: 'error',
          },
        })
      }
    },
  }))

  const leaveChannel = (channel: IChannel, currentUserId: string, realUserId: string) => {
    if (!leaveChannelOverlayRef.current || !channel || !currentUserId) {
      return
    }
    leaveChannelOverlayRef.current.setIsActive(true, channel, currentUserId, realUserId)
  }

  return (
    <>
      <LeaveChannelOverlay ref={leaveChannelOverlayRef} />
    </>
  )
})

export default ChannelSettingsOverlay
