import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Icon } from '@rneui/themed'

// Firebase
import { usersCol } from '../../api/firebase'
import { doc, setDoc } from 'firebase/firestore'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { fetchUser } from '../../slices/userSlice'

// Themes
import theme from '../../config/themes'

// Types
import { IStatusName } from '../../types'

const SettingsStatus = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)

  if (!user) {
    return null
  }

  useEffect(() => {
    dispatch(fetchUser())
  }, [])

  const doChangeUserStatus = async (statusName: IStatusName) => {
    const docRef = doc(usersCol, user.id)
    await setDoc(docRef, { onlineStatus: statusName }, { merge: true })
    toast.show('Lagret!')
    dispatch(fetchUser())
  }

  return (
    <View>
      <Button
        title="Tilgjengelig"
        type="clear"
        containerStyle={styles.buttonContainer}
        buttonStyle={[
          styles.button,
          { borderColor: user.customStatus === 'online' ? theme.colors.primary : 'transparent' },
        ]}
        titleStyle={[
          styles.buttonTitle,
          { fontFamily: user.customStatus === 'online' ? 'FSJoeyMedium' : 'FSJoey' },
        ]}
        onPress={() => {
          doChangeUserStatus('online')
        }}
      >
        Tilgjengelig
        <Icon name="circle" color="#38A751" />
      </Button>

      <Button
        title="Tilgjengelig"
        type="clear"
        containerStyle={styles.buttonContainer}
        buttonStyle={[
          styles.button,
          { borderColor: user.customStatus === 'busy' ? theme.colors.primary : 'transparent' },
        ]}
        titleStyle={[
          styles.buttonTitle,
          { fontFamily: user.customStatus === 'busy' ? 'FSJoeyMedium' : 'FSJoey' },
        ]}
        onPress={() => {
          doChangeUserStatus('busy')
        }}
      >
        Opptatt
        <Icon name="circle" color="#e74137" />
      </Button>

      <Button
        title="Tilgjengelig"
        type="clear"
        containerStyle={styles.buttonContainer}
        buttonStyle={[
          styles.button,
          { borderColor: user.customStatus === 'invisible' ? theme.colors.primary : 'transparent' },
        ]}
        titleStyle={[
          styles.buttonTitle,
          { fontFamily: user.customStatus === 'invisible' ? 'FSJoeyMedium' : 'FSJoey' },
        ]}
        onPress={() => {
          doChangeUserStatus('invisible')
        }}
      >
        Ikke synlig
        <Icon name="circle" color="#adadad" />
      </Button>
    </View>
  )
}

export default SettingsStatus

const styles = StyleSheet.create({
  buttonContainer: {
    marginBottom: 15,
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 20,
    backgroundColor: theme.colors.white,
    borderWidth: 2,
    borderColor: 'transparent',
    borderRadius: 10,
    justifyContent: 'space-between',
  },
  buttonTitle: {
    fontFamily: 'FSJoey',
    color: theme.colors.black,
    fontSize: 18,
  },
})
