import { Text } from '@rneui/themed'

const PrivacyContent = () => {
  return (
    <>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Denne personvernerklæringen er ment å gi informasjon om hvordan og hvorfor Kopling AS samler
        inn og behandler personopplysninger.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Det er Kopling AS v/ daglig leder som er behandlingsansvarlig for opplysninger som samles
        inn og behandles av Kopling AS. Ansvaret kan være delegert til en annen stilling i
        hverdagen. Personvernerklæringen som følger under gir den informasjonen
        personvernregelverket krever at vi gir i forbindelse med vår behandling av dine
        personopplysninger.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Kopling AS behandling av personopplysninger er i overensstemmelse med det til enhver tid
        gjeldende personvernregelverk.
      </Text>
      <Text style={{ margin: 5, fontSize: 18, fontWeight: 'bold' }}>
        1. Rettslig grunnlag for behandling av personopplysninger
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Behandling av personopplysninger er ikke tillatt med mindre det foreligger et gyldig
        behandlingsgrunnlag. Et slikt behandlingsgrunnlag kan eksempelvis være samtykke fra den
        registrerte, kontrakt (inngåelse av avtale), lov eller der vi som behandlingsansvarlig har
        en «berettiget interesse» som overstiger den registrertes krav på personvern.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Vårt behandlingsgrunnlag er i hovedsak samtykke.{' '}
      </Text>
      <Text style={{ margin: 5, fontSize: 18, fontWeight: 'bold' }}>
        2. Vår bruk av personopplysninger{' '}
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Vi samler inn og behandler personopplysninger for at vi skal kunne gi deg tilgang til våre
        tjenester
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Opplysninger vi registrerer er ditt telefonnummer, samt navn for i) de som er hjelpere i
        Elevtjenesten, og ii) dersom du er annen bruker enn hjelper og velger å oppgi navn. Vi
        henter opplysningene kun fra deg som er bruker.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Meldingene du sender anonymt gjennom vår tjeneste er krypterte, og kan ikke leses av oss. De
        krypterte meldingene lagres på våre servere slik at du som bruker skal kunne motta meldinger
        selv om telefonen er avslått eller uten dekning.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        For øvrig lagrer vi metadata på et generelt nivå for å føre statistikk for bruk. Data som
        lagres er eksempelvis tid brukt i appen, antall meldinger sendt og antall chatrom opprettet.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Metadata er ikke personsensitiv data, men generell data som sammenstilles i en felles mengde
        på tvers av plattformen.
      </Text>
      <Text style={{ margin: 5 }}>
        Samtaler og chatrom kan kun leses direkte i appen, og kan/må slettes av brukeren selv. Alle
        brukerens meldinger blir da umiddelbart slettet. Da slettes også dine meldinger hos
        samtalepartneren din. Metadata slettes ikke.
      </Text>
      <Text style={{ margin: 5, fontSize: 18, fontWeight: 'bold' }}>
        3. Utlevering av personopplysninger – bruk av tredjepart
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Registrerte personopplysninger behandles konfidensielt hos oss. Opplysninger vil ikke deles
        med tredjeparter.
      </Text>
      <Text style={{ margin: 5 }}>
        Merk at vi ved en rettslig kjennelse vil være forpliktet i lov til å utlevere dine
        personopplysninger.
      </Text>
      <Text style={{ margin: 5, fontSize: 18, fontWeight: 'bold' }}>
        4. Lagring av personopplysningene og sletting
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Personopplysningene vi registrerer lagres på en server fra Intellipush og i Googles
        Firebase. Geografisk ligger dine opplysninger i Frankfurt og Irland, og som i dag anses for
        å være en trygg og tilfredsstillende lagring av dine opplysninger. Grunnen til dette er at
        alle EU-stater er forpliktet til å møte kravene i GDPR og dermed sikrer den registrerte
        minimum av personvern.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Vi sletter dine personopplysninger uten ugrunnet opphold, og senest innen en måned, når de
        ikke lenger er nødvendige for formålet de ble samlet inn for, dersom du trekker tilbake
        samtykket som ligger til grunn for behandlingen av opplysningene eller du gjør en innsigelse
        mot behandlingen og det ikke finnes mer tungtveiende berettigede grunner for behandlingen.
        Vi har konkrete sletterutiner per behandlingsaktivitet.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Dersom du trekker ditt samtykke til behandling av personopplysninger vil vår behandling av
        dine personopplysninger opphøre, men ikke påvirke lovligheten av en behandling basert på
        samtykke før samtykket er trukket tilbake.
      </Text>
      <Text style={{ margin: 5, fontSize: 18, fontWeight: 'bold' }}>5. Dine rettigheter</Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Du har rett til å anmode oss om innsyn, korrigering, sletting (med mindre vi er forpliktet
        til å oppbevare opplysningene) og begrensning av behandlingen. I tillegg kan du anmode oss
        om å avslutte behandlingen av personopplysningene (eksempelvis trekke ditt samtykke
        tilbake). Dersom du anmoder oss om å benytte deg av en eller flere av disse rettighetene,
        vil vi innrette oss etter anmodningen, med mindre vi er forpliktet til annet, innen en måned
        fra den ble avgitt. Anmodningene kan rettes til slettmeg@kopling.no.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Dersom du mener vi har brutt personvernregelverket har du mulighet til å klage til
        Datatilsynet.
      </Text>
      <Text style={{ margin: 5, fontSize: 18 }}>
        Ta gjerne kontakt med hei@kopling.no dersom du har kommentarer, eller ønsker mer
        informasjon, om vår behandling av dine personopplysninger.
      </Text>
    </>
  )
}

export default PrivacyContent
