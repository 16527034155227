/*
  Format / prepare other organization data safely
  Params: {} (obj)(required)
  Return: {} (obj)
*/
import { xConsole } from './xConsole'

const formatOtherOrganizationData = (obj: any) => {
  // Default return
  const defaults: { [key: string]: string | number | Array<any> } = {
    id: '',
    organizationName: '',
    description: '',
    photoURL: '',
    buttons: [],
    users: [],
  }
  // Rules: check types by Object.prototype.toString.call
  interface IRules {
    [key: string]: string
  }
  const rules: IRules = {
    id: '[object String]',
    organizationName: '[object String]',
    description: '[object String]',
    photoURL: '[object String]',
    buttons: '[object Array]',
    users: '[object Array]',
  }
  try {
    // Convert old Firebase data to new Structure
    if (obj.title) {
      obj.organizationName = obj.title
    }
    if (obj.subtitle) {
      obj.description = obj.subtitle
    }
    if (obj.users) {
      obj.users = Object.keys(obj.users).map((k) => {
        return { id: k, priority: obj.users[k].priority || 0 }
      })
    }

    // Verify
    Object.entries(obj).forEach(([key, val]: [string, any]) => {
      if (key in defaults && key in rules) {
        if (Object.prototype.toString.call(val) === rules[key]) {
          defaults[key] = val
        }
      }
    })
    return defaults
  } catch (error) {
    xConsole().error(error as Error, 'formatOtherOrganizationData.ts (Main)')
    return defaults
  }
}

export default formatOtherOrganizationData
