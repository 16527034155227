import React from 'react'
import { TouchableHighlight } from 'react-native'
import { ListItem, Icon, Badge } from '@rneui/themed'

// Components
import Avatar from '../Avatar'

// Themes
import theme from '../../config/themes'

// Types
import { IChannel } from '../../types'

type ListChannelItemProps = {
  item: IChannel
  onPress: any
  onLongPress: any
}
const ChannelItem = ({ item, onPress, onLongPress }: ListChannelItemProps) => {
  return (
    <ListItem
      key={item.id}
      onPress={onPress}
      onLongPress={onLongPress}
      bottomDivider
      Component={TouchableHighlight}
      containerStyle={{ height: 73, backgroundColor: theme.colors.bodybg, paddingHorizontal: 20 }}
    >
      <Avatar
        photoURL={item.photoURL}
        statusColor={item.statusColor}
        placeholderTitle={item.title}
        isAnonymous={item.isAnonymous}
      />
      <ListItem.Content>
        <ListItem.Title numberOfLines={1}>
          {item.title}{' '}
          {item.isAnonymous && (
            <Icon
              name="user-secret"
              type="font-awesome-5"
              color={theme.colors.black}
              size={13}
              style={{ marginLeft: 3, marginTop: 4 }}
            />
          )}
        </ListItem.Title>
        <ListItem.Subtitle numberOfLines={2}>{item.lastMessage}</ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Content right style={{ alignItems: 'center', flex: 0, minWidth: 50 }}>
        <ListItem.Title style={{ fontSize: 11 }}>{item.lastMessageFromNow}</ListItem.Title>
        {!item.isRead && (
          <Badge
            containerStyle={{ marginTop: 5 }}
            badgeStyle={{ width: 14, height: 14, borderRadius: 20 }}
          />
        )}
      </ListItem.Content>
    </ListItem>
  )
}

export default ChannelItem
