import React from 'react'
import { StyleSheet } from 'react-native'
import { Send, LoadEarlier } from 'react-native-gifted-chat'

// Themes
import theme from '../../config/themes'

// Types
import type { LoadEarlierProps } from 'react-native-gifted-chat'

function LoadEarlierButton(props: LoadEarlierProps) {
  return (
    <LoadEarlier
      {...props}
      label="Last tidligere meldinger"
      wrapperStyle={styles.wrapper}
      textStyle={styles.text}
    />
  )
}

export default LoadEarlierButton

const styles = StyleSheet.create({
  wrapper: {
    backgroundColor: '#e5e5e5',
  },
  text: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 10,
    color: theme.colors.black,
  },
})
