import React, { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Input } from '@rneui/themed'

// Helpers
import formatPhoneNumber from '../../plugins/helpers/formatPhoneNumber'
import { xConsole } from '../../plugins/helpers/xConsole'

// Api
import { firebaseFunction } from '../../api'

// Theme
import theme from '../../config/themes'

// Components
import SubmitButton from './SubmitButton'

// Types
import { ILoginScreenAuth } from '../../types'
type PasswordFormProps = {
  auth: ILoginScreenAuth | null
  login: (token: string) => void
  loggingIn: boolean
}

function PasswordForm({ auth, login, loggingIn }: PasswordFormProps) {
  const [password, setPassword] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Validation
  const passwordRegex = new RegExp('.{5,}$')
  const onChangeText = (password: string): void => {
    setPassword(password)
    setIsValid(!!password && passwordRegex.test(password))
  }

  // Submit
  const onSubmit = async (): Promise<void> => {
    toast.hideAll()
    if (!isValid) {
      toast.show('Skriv inn riktig passord for å logge inn.', {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
      return
    }

    try {
      setIsLoading(true)
      if (!auth) {
        setIsLoading(false)
        toast.show('Beklager, noe gikk galt.', {
          type: 'main',
          data: {
            title: 'Obs!',
            icon: 'error',
          },
        })
        return
      }
      const res = await firebaseFunction({
        endpoint: '/auth',
        payload: {
          phoneNumber: formatPhoneNumber(auth.phoneNumber),
          verifyCode: auth.verifyCode,
          password: password,
        },
      })
      setIsLoading(false)
      if (res.data) {
        if (res.data.success === 'CORRECT_PASSWORD' && res.data?.token) {
          login(res.data.token)
        } else if (res.data.error === 'WRONG_PASSWORD') {
          toast.show('Feil passord, prøv igjen.', {
            type: 'main',
            data: {
              title: 'Obs!',
              icon: 'error',
            },
          })
        } else if (res.data.error === 'TOO_MANY_ATTEMPTS') {
          toast.show('Du har prøvd mange ganger, kontakt oss!', {
            type: 'main',
            data: {
              title: 'Obs!',
              icon: 'error',
            },
          })
        }
      } else {
        toast.show('Beklager, noe gikk galt.', {
          type: 'main',
          data: {
            title: 'Obs!',
            icon: 'error',
          },
        })
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'PasswordForm.tsx (onSubmit)')
      toast.show(error.message, {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
    }
  }
  return (
    <View>
      <Input
        placeholder="Fyll inn passord"
        secureTextEntry
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmit}
        disabledInputStyle={{ opacity: 1 }}
        disabled={isLoading || loggingIn}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
      />
      <SubmitButton
        title="LOGG INN"
        onPress={async () => {
          onSubmit()
        }}
        loading={isLoading || loggingIn}
        valid={isValid}
      />
    </View>
  )
}

export default PasswordForm

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
