import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { StyleSheet, View } from 'react-native'
import { Button } from '@rneui/themed'
import { BottomSheetModal, BottomSheetBackdrop } from '@gorhom/bottom-sheet'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import ContactProfile from './Profile'

// Theme
import theme from '../../../config/themes'

// Types
import { RootNavigatorParamList, IOrganizationUser } from '../../../types'

type ContactOverlayProps = {
  bottomInset?: number
}

const ContactProfileOverlay = forwardRef(({ bottomInset }: ContactOverlayProps, ref) => {
  const insets = useSafeAreaInsets()
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const [item, setItem] = useState<IOrganizationUser>()
  const snapPoints = useMemo(() => [380], [])

  useImperativeHandle(ref, () => ({
    setIsActive(isActive: boolean, item: IOrganizationUser) {
      if (isActive) {
        bottomSheetModalRef.current?.present()
        setItem(item)
      }
    },
  }))

  const handleDismissModalPress = useCallback(() => {
    bottomSheetModalRef.current?.dismiss()
  }, [])

  const renderBackdrop = useCallback(
    (props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />,
    []
  )

  return (
    <>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        bottomInset={bottomInset !== undefined ? bottomInset : 46 + insets.bottom}
        backdropComponent={renderBackdrop}
        stackBehavior={'push'}
      >
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={handleDismissModalPress}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>
        {item && (
          <View style={{ flex: 1, paddingHorizontal: 20 }}>
            <ContactProfile item={item} />
          </View>
        )}
      </BottomSheetModal>
    </>
  )
})

export default ContactProfileOverlay

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
})
