import React from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { Icon } from '@rneui/themed'

// Types
type ChatInputActionsProps = {
  isAnonymous: boolean
  trigger: (actionName: string) => void
}

const ChatInputActions = ({ isAnonymous, trigger }: ChatInputActionsProps) => {
  return (
    <>
      <View style={styles.mainContainer}>
        <Pressable onPress={() => trigger('shareContacts')}>
          <Icon
            name="people"
            type="ionicon"
            size={26}
            color={!isAnonymous ? '#2b2c2b' : '#ffffff'}
            containerStyle={styles.openerContainer}
          />
        </Pressable>
      </View>
    </>
  )
}

export default ChatInputActions

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    bottom: 0,
    left: 5,
  },
  openerContainer: {
    height: 40,
    width: 40,
    justifyContent: 'center',
  },
})
