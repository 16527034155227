/*
  Set lastViewed in channels/{id}/users/{uid}/lastViewed
  Params: { channelId(string)(required), userId(string)(required) }
  Return: (nothing, just try to set lastViewed)
*/

import { xConsole } from '../../plugins/helpers/xConsole'

// Firebase
import { channelsCol } from '../../api/firebase'
import { doc, setDoc } from 'firebase/firestore'

// Types
interface ISetUserLastViewed {
  channelId: string
  userId: string
  lastViewed: number
}
async function setUserLastViewed(args: ISetUserLastViewed) {
  try {
    if (args.channelId && args.userId) {
      const docRef = doc(channelsCol, args.channelId)
      await setDoc(
        docRef,
        {
          users: {
            [args.userId]: { lastViewed: args.lastViewed },
          },
        },
        { merge: true }
      )
    }
  } catch (error) {
    xConsole().error(error as Error, 'setUserLastViewed.ts (Main)')
  }
}

export default setUserLastViewed
