import React, { useState, useRef } from 'react'
import { View } from 'react-native'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'
import SettingsAvatarUpload from '../../components/settings/AvatarUpload'
import SettingsEditInfo from '../../components/settings/EditInfo'
import AvatarUploadOverlay from '../../components/settings/AvatarUploadOverlay'

// Types
import { IAvatarUploadOverlayRef } from '../../types'

const SettingsProfileScreen = () => {
  const avatarUploadOverlayRef = useRef<IAvatarUploadOverlayRef>(null)
  const [newAvatarUrl, setNewAvatarUrl] = useState('')
  const openAvatarUploadOverlay = () => {
    if (!avatarUploadOverlayRef.current) {
      return
    }
    avatarUploadOverlayRef.current.setIsActive(true)
  }

  return (
    <>
      <ScreenSubpageLayout title="Din profil" withKeyboardAvoidingView>
        <View style={{ marginTop: 30, marginBottom: 10 }}>
          <SettingsAvatarUpload onPress={openAvatarUploadOverlay} newUrl={newAvatarUrl} />
        </View>
        <View>
          <SettingsEditInfo newAvatarUrl={newAvatarUrl} />
        </View>
      </ScreenSubpageLayout>
      <AvatarUploadOverlay ref={avatarUploadOverlayRef} onSelect={setNewAvatarUrl} />
    </>
  )
}

export default SettingsProfileScreen
