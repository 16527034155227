//@TODO: Merge this as getApiMessages.ts

// Firebase
import { messagesCol } from '../../api/firebase'
import { getDocs, query, orderBy, limit, startAfter } from 'firebase/firestore'

// Helpers
import decryptMessage from '../../plugins/helpers/decryptMessage'
import { xConsole } from '../../plugins/helpers/xConsole'

// Types
import { IApiResponse, IMessage } from '../../types'
interface IGetApiMoreMessagesArgs {
  id: string
  limit: number
  users: any
  key: string
  startAfter: number
}

async function getApiMoreMessages(args: IGetApiMoreMessagesArgs): Promise<IApiResponse> {
  try {
    /* ======== Prepare data ======== */
    const messages: Array<IMessage> = []
    const messagRef = messagesCol(args.id)
    const q = query(
      messagRef,
      orderBy('createdOn', 'desc'),
      startAfter(args.startAfter),
      limit(args.limit)
    )
    const querySnapshot = await getDocs(q)

    for (const doc of querySnapshot.docs) {
      try {
        const docData = doc.data()
        let user = args.users[docData.uid]
        if (!user) {
          // Anonymous user or not found (Fallback)
          user = {
            fullName: docData.uid,
            photoURL: '',
          }
        }

        const formattedMessage: IMessage = {
          _id: doc.id,
          text: docData?.content
            ? decryptMessage({
                content: docData.content,
                key: args.key,
              })
            : '',
          createdAt: docData.createdOn,
          user: {
            _id: docData.uid,
            name: user.fullName,
            avatar: user.photoURL,
          },
          system: docData.type === 'system',
          type: docData.type,
        }

        // Custom data for custom view
        if (docData.type !== 'message' && docData.type !== 'system') {
          formattedMessage.customData = formattedMessage.text
          if (docData.json) {
            formattedMessage.customData = JSON.parse(formattedMessage.customData)
            // Format old => new Contact card data structure
            if (formattedMessage.customData.refMessage !== undefined) {
              formattedMessage.customData.fullName = formattedMessage.customData.displayName
              formattedMessage.customData.message = formattedMessage.customData.refMessage
              formattedMessage.customData.title = formattedMessage.customData.subtitle
              formattedMessage.customData.targetId = formattedMessage.customData.uid
              formattedMessage.customData.fromId = formattedMessage.user._id
              formattedMessage.customData.organizationId =
                formattedMessage.customData.organizationId || ''
            }
          }
          formattedMessage.text = ''
        }

        // Format old => new Leave data structure
        if (
          docData.type === 'system' &&
          docData.systemType === undefined &&
          formattedMessage.text &&
          formattedMessage.text.includes('forlot samtalen')
        ) {
          formattedMessage.systemType = 'leave'
          formattedMessage.text = JSON.stringify({
            userId: formattedMessage.user._id || '',
            fullName: formattedMessage.user.name || 'Han/hun',
          })
        }

        // Format old => new Ref message data structure
        if (
          docData.type === 'reference' &&
          formattedMessage.customData &&
          formattedMessage.customData.content
        ) {
          formattedMessage.system = true
          formattedMessage.type = 'system'
          formattedMessage.systemType = 'contact-card'

          let refFullName = 'Noen'
          const refFullNameMatch = formattedMessage.customData.content.match(
            /fra <strong>(.*?)<\/strong><\/div>/
          )
          if (refFullNameMatch && refFullNameMatch[1]) {
            refFullName = refFullNameMatch[1]
          }

          let refMessage = '…'
          const refMessageMatch = formattedMessage.customData.content.match(
            /<div class=\"secret-message\">(.*?)<\/div>/
          )
          if (refMessageMatch && refMessageMatch[1]) {
            refMessage = refMessageMatch[1]
          }
          formattedMessage.text = JSON.stringify({
            targetId: formattedMessage.customData.to,
            fromId: '',
            fromFullName: refFullName,
            message: refMessage,
          })
        }

        // Push to messages
        messages.push(formattedMessage)
      } catch (error) {
        xConsole().error(
          error as Error,
          'getApiMoreMessages.ts (querySnapshot.docs loop and prepare data)'
        )
      }
    }
    messages.sort((a, b) => b.createdAt - a.createdAt) // Sort by date
    return { statusText: 'SUCCESS', status: 200, data: messages } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'getApiMoreMessages.ts (Main)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default getApiMoreMessages
