import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'
import UserStatus from '../../components/settings/UserStatus'

const SettingsUserStatusScreen = () => {
  return (
    <>
      <ScreenSubpageLayout title="Tilgjengelighet">
        <View style={{ marginTop: 30, marginBottom: 10 }}>
          <Text style={{ fontSize: 18 }}>Oppdater din status i appen.</Text>
          <View style={{ marginVertical: 40 }}>
            <UserStatus />
          </View>
        </View>
      </ScreenSubpageLayout>
    </>
  )
}

export default SettingsUserStatusScreen
