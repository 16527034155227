import { View } from 'react-native'

// Components
import ScreenSubpageLayout from '../../components/settings/ScreenSubpageLayout'
import SettingsSelectedOrganizations from '../../components/settings/SelectedOrganizations'
import SettingsOrganizationSelectorButton from '../../components/settings/OrganizationSelectorButton'

const SettingsOrganizationsScreen = () => {
  return (
    <ScreenSubpageLayout title="Skoler og tjenester">
      <View style={{ marginVertical: 30 }}>
        <SettingsSelectedOrganizations />
      </View>
      <SettingsOrganizationSelectorButton />
    </ScreenSubpageLayout>
  )
}

export default SettingsOrganizationsScreen
