import { xConsole } from './xConsole'
// Types
import { IChannel } from '../../types'
interface IGetChannelCurrentUserIdArgs {
  channel: IChannel
  realUserId: string
}

/*
  Get current channel user id (for normal and anonymous channel)
  Params: { channel(object)(required), realUserId(string)(required) }
  Return: id(string)
*/
const getChannelCurrentUserId = (args: IGetChannelCurrentUserIdArgs) => {
  let id = args.realUserId // default is real user id
  try {
    if (args.channel.isAnonymous) {
      for (const userId in args.channel.users) {
        if (
          args.channel.users[userId].id && // Check if data has id for target which anonymous's user is
          userId === args.realUserId // Check if target is same as current user id
        ) {
          id = args.channel.users[userId].id
        }
      }
    }
  } catch (error) {
    xConsole().error(error as Error, 'getChannelCurrentUserId.ts (Main)')
  }
  return id
}

export default getChannelCurrentUserId
