import React, { useState, useEffect } from 'react'
import { View } from 'react-native'

// Components
import ChatHeader from '../components/chat/Header'
import ChatView from '../components/chat/View'

// Helpers
import { xConsole } from '../plugins/helpers/xConsole'

// Types
import { RootNavigatorParamList } from '../types'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Routes } from '../config/routes'

type ChatScreenProps = NativeStackScreenProps<RootNavigatorParamList, Routes.Chat>

const ChatScreen = (props: ChatScreenProps) => {
  /* ======= Prepare data ====== */
  const { item } = props.route.params
  const [channelId, setChannelId] = useState<string | null>('')
  const [channelTitle, setChannelTitle] = useState<string>('')
  const [channelDescription, setChannelDescription] = useState<string>('')
  const [channelPhotoURL, setChannelPhotoURL] = useState<string>('no-image')
  const [channelStatusColor, setChannelStatusColor] = useState<string>('')
  const [isAnonymous, setIsAnonymous] = useState<boolean>(false)

  /* ==== Prepare channel info ==== */
  const prepareChannelBasicInfo = async () => {
    try {
      setChannelId(item.id ? item.id : null)
      setChannelTitle(item.title)
      setChannelDescription(item.description)
      setChannelPhotoURL(item.photoURL)
      setChannelStatusColor(item.statusColor)
      setIsAnonymous(item.isAnonymous)
    } catch (error) {
      xConsole().error(error as Error, 'ChatScreen.tsx (prepareChannelBasicInfo)')
    }
  }

  useEffect(() => {
    prepareChannelBasicInfo()
  }, [])

  return (
    <View style={{ flex: 1, backgroundColor: !isAnonymous ? '#f8f8f8' : '#333333' }}>
      <ChatHeader
        id={channelId}
        title={channelTitle}
        description={channelDescription}
        photoURL={channelPhotoURL}
        statusColor={channelStatusColor}
        isAnonymous={isAnonymous}
      />
      <ChatView {...props} />
    </View>
  )
}

export default ChatScreen
