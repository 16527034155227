import React, { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Input, Button } from '@rneui/themed'

// Store
import store from '../../store'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// APIs
import setPassword from '../../api/auth/setPassword'
import removePassword from '../../api/auth/removePassword'
import logout from '../../api/auth/logout'

// Components
import ResetPasswordButton from '../../components/login/ResetPasswordButton'

// Themes
import theme from '../../config/themes'

const SettingsTwoFactor = () => {
  const dispatch = useDispatch()
  const { user, isDevMode } = useSelector((state: RootState) => state.user)
  const [isLoading, setIsLoading] = useState(false)
  const [isShowSetPassword, setIsShowSetPassword] = useState(false)
  const [passwordInputs, setPasswordInputs] = useState({ password: '', confirmPassword: '' })
  const [isPasswordInputsValid, setIsPasswordInputsValid] = useState(true)

  if (!user) {
    return null
  }

  const hasPassword = () => {
    return !!user.hasPassword
  }

  const doSetPassword = async () => {
    setIsLoading(true)
    if (
      passwordInputs.password &&
      passwordInputs.confirmPassword &&
      passwordInputs.password === passwordInputs.confirmPassword &&
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(passwordInputs.password) // https://stackoverflow.com/a/40923568/622813
    ) {
      setIsPasswordInputsValid(true)
      const res = await setPassword(passwordInputs.password)
      if (res.status === 200) {
        await logout(store.getState)
        dispatch({ type: 'CLEAR_ALL' })
      } else {
        toast.show('Beklager, noe gikk galt.')
      }
    } else {
      setIsPasswordInputsValid(false)
    }
    setIsLoading(false)
  }

  const onChangeText = (k: 'password' | 'confirmPassword', v: string) => {
    setPasswordInputs({ ...passwordInputs, ...{ [k]: v } })
  }

  const renderLabel = (title: string) => {
    return <Text style={styles.inputLabel}>{title}</Text>
  }

  return (
    <View>
      {!isShowSetPassword && (
        <Button
          title="SKRU PÅ"
          disabled={hasPassword()}
          disabledStyle={styles.buttonDisabledContainer}
          disabledTitleStyle={styles.buttonDisabledTitle}
          onPress={() => setIsShowSetPassword(true)}
        />
      )}
      {hasPassword() && (
        <>
          <Text style={{ textAlign: 'center', marginTop: 15 }}>
            Du har allerede aktivert tofaktorautentisering
          </Text>
          <ResetPasswordButton
            phoneNumber={user.phoneNumber}
            buttonProps={{
              type: 'clear',
              titleStyle: {
                color: theme.colors.primary,
                fontFamily: 'FSJoeyMedium',
                textDecorationLine: 'underline',
              },
              disabledTitleStyle: { color: theme.colors.primary, opacity: 0.25 },
            }}
          />
          {isDevMode && (
            <Button
              title="Skru av og slett passordet"
              type="clear"
              titleStyle={{ color: theme.colors.blue, fontSize: 11 }}
              onPress={async () => {
                const res = await removePassword(user.phoneNumber)
                if (res.status === 200) {
                  await logout(store.getState)
                  dispatch({ type: 'CLEAR_ALL' })
                } else {
                  toast.show('Beklager, noe gikk galt.')
                }
              }}
            />
          )}
        </>
      )}
      {isShowSetPassword && (
        <View>
          <Input
            containerStyle={styles.mainInputContainer}
            inputContainerStyle={styles.inputContainer}
            inputStyle={styles.input}
            errorStyle={styles.inputErrorText}
            label={renderLabel('Passord')}
            onChangeText={(v) => onChangeText('password', v)}
            secureTextEntry
            disabled={isLoading}
          />
          <Input
            containerStyle={styles.mainInputContainer}
            inputContainerStyle={styles.inputContainer}
            inputStyle={styles.input}
            errorStyle={styles.inputErrorText}
            label={renderLabel('Gjenta passord')}
            onChangeText={(v) => onChangeText('confirmPassword', v)}
            secureTextEntry
            disabled={isLoading}
          />
          <Text style={{ marginBottom: 15 }}>
            Passordet må være minst 8 tegn, inneholde både store og små bokstaver, samt minst ett
            tall.
          </Text>
          {!isPasswordInputsValid && (
            <Text style={{ marginBottom: 15, color: theme.colors.primary }}>
              Passordmønster er ikke tillatt, prøv igjen.
            </Text>
          )}
          <Button title={'Set passord'} onPress={doSetPassword} loading={isLoading} />
        </View>
      )}
    </View>
  )
}

export default SettingsTwoFactor

const styles = StyleSheet.create({
  mainInputContainer: {
    paddingHorizontal: 0,
  },
  buttonDisabledContainer: {
    opacity: 0.5,
    backgroundColor: theme.colors.primary,
  },
  buttonDisabledTitle: {
    color: theme.colors.white,
  },

  inputLabel: {
    fontFamily: 'FSJoeyMedium',
  },
  inputContainer: {
    borderColor: theme.colors.light,
  },
  input: {
    fontSize: 16,
    minHeight: 35,
  },
  inputErrorText: {
    color: theme.colors.primary,
    fontSize: 9,
    marginLeft: 0,
    height: 10,
  },
  buttonContainer: {
    marginVertical: 15,
  },
  buttonActive: {
    backgroundColor: theme.colors.primary,
  },
  buttonInactive: {
    backgroundColor: 'transparent',
    borderWidth: 2,
  },
  buttonTitleActive: {
    color: theme.colors.white,
  },
  buttonTitleInactive: {
    color: theme.colors.black,
  },
})
