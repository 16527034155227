import { firebaseFunction } from '../../api/firebase'
import { IChannelKeys } from '../../types'
import { xConsole } from './xConsole'

/*
  Get channel key
  Params: { id(string)(required), idToken(string)(required), prevKeys(object)(required) }
  Return: key(string)
*/
const getChannelKey = async (
  id: string,
  token: string,
  prevKeys?: IChannelKeys,
  force?: Boolean
) => {
  let key = ''
  try {
    if (!force) {
      if (prevKeys && prevKeys[id]) {
        key = prevKeys[id]
      } else if (global.preChannelKeys && global.preChannelKeys[id]) {
        key = global.preChannelKeys[id]
      }
    }
    if (!key) {
      const res = await firebaseFunction({
        endpoint: '/channelKey',
        payload: { channel: id, token: token },
      })
      if (res.data?.key) {
        key = res.data.key
      }
    }
  } catch (error) {
    xConsole().error(error as Error, 'getChannelKey.ts (Main)')
  }
  return key
}

export default getChannelKey
