import React from 'react'
import { View, Linking } from 'react-native'

// Components
import ContactItem from './Item'

const list = [
  {
    title: 'Hjelpetelefonen',
    subtitle: 'Mental Helse',
    photoURL:
      'https://firebasestorage.googleapis.com/v0/b/kopling-dev.appspot.com/o/hjelpetelefonen.png?alt=media&token=d8783abb-9f3a-446f-90bb-a6a0d66ab576',
    description:
      'Hjelpetelefonen er Mental Helses gratis døgnåpne telefontjeneste for alle som trenger noen å snakke med. Du kan være anonym og vi har taushetsplikt.',
    phoneNumber: '116 123',
  },
]

const ListEmergencies = () => {
  const onPress = (data: any) => {
    Linking.openURL(`tel:${data.phoneNumber.replace(/\s/g, '')}`)
  }

  return (
    <View style={{ padding: 20 }}>
      {list.map((data, index) => (
        <ContactItem key={index} item={data} onPress={() => onPress(data)} />
      ))}
    </View>
  )
}

export default ListEmergencies
