import { StyleSheet, View, Dimensions, Linking, Platform } from 'react-native'
import { Button, Icon, Text } from '@rneui/themed'

// Themes
import theme from '../../config/themes'

// Types
type ToastMainProps = {
  toast: any
}

const { width, height } = Dimensions.get('window')

const toastCenter = ({ toast: _toast }: ToastMainProps) => {
  const isDark = _toast.data?.isDark || false

  return (
    <View style={styles.outerContainer}>
      <View
        style={[
          styles.mainContainer,
          { backgroundColor: !isDark ? theme.colors.white : theme.colors.black },
        ]}
      >
        <View style={{ flex: 0, justifyContent: 'center', alignItems: 'center' }}>
          <Icon type="antdesign" name="exclamationcircle" size={70} color={theme.colors.black} />
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'FSJoeyMedium',
              fontSize: 22,
              color: theme.colors.black,
              marginTop: 10,
            }}
          >
            Viktig info!
          </Text>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 18,
              color: theme.colors.grey2,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Plattformen oppdateres 13.12.22 og vil derfor ha noe nedetid mellom klokken 17.00 -
            19.00. Vi gir deg en ny beskjed når oppdateringen er klar til å lastes ned.
          </Text>
        </View>
        <View style={{ flex: 0, alignItems: 'flex-end', marginBottom: -20 }}>
          <Button
            title="LUKK"
            type="clear"
            titleStyle={{ color: theme.colors.primary, fontFamily: 'FSJoeyMedium' }}
            onPress={() => {
              if (_toast && _toast.id) {
                toast.hide(_toast.id)
              }
            }}
          />
        </View>
      </View>
    </View>
  )
}

export default toastCenter

const styles = StyleSheet.create({
  outerContainer: {
    width: width,
    height: height,
    justifyContent: 'center',
    paddingHorizontal: '4%',
  },
  mainContainer: {
    flexDirection: 'column',
    marginTop: -40,
    padding: 30,
    borderRadius: 25,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 10,
  },
})
