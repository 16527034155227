import React, { useRef } from 'react'
import { Button } from '@rneui/themed'

// Components
import OrganizationSelector from '../organizations/selector'

// Types
import { IOrganizationSelectorRef } from '../../types'

const SettingsOrganizationSelectorButton = () => {
  const organizationSelectorRef = useRef<IOrganizationSelectorRef>(null)

  const openSelector = () => {
    organizationSelectorRef.current?.setIsActive(true)
  }
  return (
    <>
      <Button title="VELG SKOLER/ TJENESTER" onPress={openSelector} />
      <OrganizationSelector ref={organizationSelectorRef} />
    </>
  )
}

export default SettingsOrganizationSelectorButton
