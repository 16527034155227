import { StyleSheet, View, Dimensions, Linking, Platform } from 'react-native'
import { Button, Icon, Text } from '@rneui/themed'

// Themes
import theme from '../../config/themes'

// Types
type ToastMainProps = {
  toast: any
}

const { width, height } = Dimensions.get('window')

const toastCenter = ({ toast: _toast }: ToastMainProps) => {
  const isDark = _toast.data?.isDark || false

  return (
    <View style={styles.outerContainer}>
      <View
        style={[
          styles.mainContainer,
          { backgroundColor: !isDark ? theme.colors.white : theme.colors.black },
        ]}
      >
        <View style={{ flex: 0, justifyContent: 'center', alignItems: 'center' }}>
          <Icon type="octicon" name="download" size={70} color={theme.colors.black} />
          <Text
            style={{
              textAlign: 'center',
              fontFamily: 'FSJoeyMedium',
              fontSize: 22,
              color: theme.colors.black,
              marginTop: 10,
            }}
          >
            Ny oppdatering!
          </Text>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 18,
              color: theme.colors.grey2,
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Venligst oppdater til den nyest versjonen for å fortsette å bruke appen :)
          </Text>
        </View>
        <View style={{ flex: 0, alignItems: 'flex-end', marginBottom: -20 }}>
          <Button
            title="OPPDATER"
            type="clear"
            titleStyle={{ color: theme.colors.primary, fontFamily: 'FSJoeyMedium' }}
            onPress={() => {
              let url = 'https://kopling.no'
              if (Platform.OS === 'ios') {
                url = 'https://apps.apple.com/us/app/elevtjenesten/id1221692281'
              }
              if (Platform.OS === 'android') {
                url = 'https://play.google.com/store/apps/details?id=com.whispr.app'
              }
              Linking.openURL(url)
            }}
          />
        </View>
      </View>
    </View>
  )
}

export default toastCenter

const styles = StyleSheet.create({
  outerContainer: {
    width: width,
    height: height,
    justifyContent: 'center',
    paddingHorizontal: '4%',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  mainContainer: {
    flexDirection: 'column',
    marginTop: -40,
    padding: 30,
    borderRadius: 25,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 10,
  },
})
