// Firebase
import { channelsCol, messagesCol, IMessagesDB } from '../../api/firebase'
import { doc, setDoc, addDoc } from 'firebase/firestore'

// Helpers
import encryptMessage from '../../plugins/helpers/encryptMessage'
import { xConsole } from '../../plugins/helpers/xConsole'

// Types
import { IApiResponse } from '../../types'
interface ICreateApiMessageArgs {
  id: string
  userId: string
  message: string
  key: string
  createdOn: number
  type?: string
  systemType?: string
  json?: boolean
  lastMessage?: string
}

async function createApiMessage(args: ICreateApiMessageArgs): Promise<IApiResponse> {
  try {
    // Encrypt the message
    const encryptedMessage = encryptMessage({
      content: args.message,
      key: args.key,
    })
    if (!encryptedMessage) {
      return { statusText: 'CANNOT_ENCRYPT_MESSAGE', status: 400 } as IApiResponse
    }

    // Data
    const message: IMessagesDB = {
      content: encryptedMessage,
      type: args.type ? args.type : 'message',
      uid: args.userId,
      createdOn: args.createdOn,
    }

    // System type
    if (args.systemType) {
      message.systemType = args.systemType
    }

    // Type JSON
    if (args.json) {
      message.json = args.json
    }

    // Add messages
    const messagesRef = messagesCol(args.id)
    await addDoc(messagesRef, message)
    // Set lastMessage
    let lastMessage = message
    if (args.lastMessage) {
      // If custom lastMessage
      const encryptedLastMessage = encryptMessage({
        content: args.lastMessage,
        key: args.key,
      })
      if (encryptedLastMessage) {
        lastMessage.content = encryptedLastMessage
      }
    }
    const channelRef = doc(channelsCol, args.id)
    await setDoc(channelRef, { lastMessage: message }, { merge: true })
    // Return
    return { statusText: 'SUCCESS', status: 200, data: '' } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'createApiMessage.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default createApiMessage
