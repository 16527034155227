import getApiUser from '../api/users/getApiUser'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import getServerTime from '../plugins/helpers/getServerTime'
import { xConsole } from '../plugins/helpers/xConsole'

// Firebase
import { institutionsCol } from '../api/firebase'
import { getDocs, query, where } from 'firebase/firestore'

// Types
import { IUserState, IUser, IDevice } from '../types'
const initialState: IUserState = {
  user: null,
  device: {
    timeOffset: 0,
  },
  isHelper: false,
  isDevMode: false,
  isLoading: false,
  error: false,
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser(state) {
      state.isLoading = true
      state.error = false
    },
    getUserSuccess(state, action: PayloadAction<{ user: IUser }>) {
      const payload = action.payload
      state.isLoading = false
      state.user = payload.user
    },
    getUserFailure(state) {
      state.isLoading = false
      state.error = true
    },
    setUserDevice(state, action: PayloadAction<{ device: IDevice }>) {
      const payload = action.payload
      state.device = { ...state.device, ...payload.device }
    },
    setUserDevMode(state, action: PayloadAction<boolean>) {
      const payload = action.payload
      state.isDevMode = payload
    },
    setIsHelper(state, action: PayloadAction<boolean>) {
      const payload = action.payload
      state.isHelper = payload
    },
  },
})

export const {
  getUser,
  getUserSuccess,
  getUserFailure,
  setUserDevice,
  setUserDevMode,
  setIsHelper,
} = user.actions

export default user.reducer

export const fetchUser = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getUser())
    const res = await getApiUser()
    if (res.status === 200) {
      dispatch(getUserSuccess({ user: res.data }))

      // Get device info
      const serverTime = await getServerTime()
      dispatch(setUserDevice({ device: { timeOffset: serverTime.offset } }))
    }
  } catch (error) {
    dispatch(getUserFailure())
    xConsole().error(error as Error, 'userSlice.ts (fetchUser)')
  }
}

export const toggleDevMode = (): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(getUser())
    const { isDevMode } = getState().user
    dispatch(setUserDevMode(!isDevMode))
  } catch (error) {
    xConsole().error(error as Error, 'userSlice.ts (toggleDevMode)')
  }
}

export const checkIsHelper = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user } = getState().user
    if (!user) return
    const q = query(institutionsCol, where(`users.${user.id}.permission`, '==', true))
    const querySnapshot = await getDocs(q)
    dispatch(setIsHelper(querySnapshot.size >= 1))
  } catch (error) {
    xConsole().error(error as Error, 'userSlice.ts (checkIsHelper)')
  }
}
