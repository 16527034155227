import React from 'react'

// Navigation & drawer
import { createStackNavigator } from '@react-navigation/stack'
import { createNavigationContainerRef } from '@react-navigation/native'
import RootNavigator from '../navigation/RootNavigator'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../store/rootReducer'

// Screens
import LoginScreen from '../screens/LoginScreen'
import OnBoardingScreen from '../screens/OnBoardingScreen'
import ChatScreen from '../screens/ChatScreen'
import ContactProfileScreen from '../screens/ContactProfileScreen'
import OtherContactsProfileScreen from '../screens/contacts/OtherContactsProfileScreen'

import SettingsProfileScreen from '../screens/settings/SettingsProfileScreen'
import SettingsTwoFactorScreen from '../screens/settings/SettingsTwoFactorScreen'
import SettingsOrganizationsScreen from '../screens/settings/SettingsOrganizationsScreen'
import SettingsUserStatusScreen from '../screens/settings/SettingsUserStatusScreen'
import SettingsDeleteAccountScreen from '../screens/settings/SettingsDeleteAccountScreen'
import SettingsPrivacyScreen from '../screens/settings/SettingsPrivacyScreen'
import SettingsCrisesRoutinesScreen from '../screens/settings/SettingsCrisesRoutinesScreen'
import SettingsAdminScreen from '../screens/settings/SettingsAdminScreen'

// Themes
import theme from '../config/themes'

// Types
import { Routes } from '../config/routes'

// Variables
const Stack = createStackNavigator()

const AppNavigator = () => {
  const { user } = useSelector((state: RootState) => state.user)

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: 'white',
        headerStyle: { backgroundColor: theme.colors.primary },
        headerBackTitleVisible: false,
      }}
    >
      {!!user ? (
        // Screens for logged in users
        <Stack.Group>
          <Stack.Screen name="Root" component={RootNavigator} options={{ headerShown: false }} />
          <Stack.Screen
            name={Routes.Chat}
            component={ChatScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.ContactProfile}
            component={ContactProfileScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.OtherContactsProfile}
            component={OtherContactsProfileScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.OnBoarding}
            component={OnBoardingScreen}
            options={{ headerShown: false }}
          />

          {/* ====== Setting pages ====== */}
          <Stack.Screen
            name={Routes.SettingsProfile}
            component={SettingsProfileScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsTwoFactor}
            component={SettingsTwoFactorScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsOrganizations}
            component={SettingsOrganizationsScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsUserStatus}
            component={SettingsUserStatusScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsDeleteAccount}
            component={SettingsDeleteAccountScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsPrivacy}
            component={SettingsPrivacyScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsCrisesRoutines}
            component={SettingsCrisesRoutinesScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name={Routes.SettingsAdmin}
            component={SettingsAdminScreen}
            options={{ headerShown: false }}
          />
        </Stack.Group>
      ) : (
        // Auth screens
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name={Routes.Login} component={LoginScreen} />
        </Stack.Group>
      )}
    </Stack.Navigator>
  )
}

export default AppNavigator
export const navigationRef = createNavigationContainerRef()
