import { ListItem } from '@rneui/themed'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Text, Icon } from '@rneui/themed'
import Autolink from 'react-native-autolink'

import theme from '../../config/themes'
import { IFAQ, IMainFAQ, ISubFAQ } from '../../types'

type FAQListItemProps = {
  item: IFAQ
}

function FAQListItem({ item }: FAQListItemProps) {
  // Accordions
  const [mainFAQExpanded, setMainFAQExpanded] = useState<String>('')
  const [subFAQExpanded, setSubFAQExpanded] = useState<String>('')

  return (
    <View style={{ marginBottom: 40 }}>
      <Text h4 h4Style={{ color: theme.colors.primary }}>
        {item.title}
      </Text>
      {item.items.map((mainFAQ: IMainFAQ, mainFAQIndex: React.Key) => (
        <View key={mainFAQIndex}>
          <ListItem.Accordion
            containerStyle={{
              backgroundColor: 'transparent',
              borderBottomColor: theme.colors.divider,
              borderBottomWidth: 1,
              paddingBottom: 15,
              paddingLeft: 0,
            }}
            content={
              <ListItem.Content>
                <ListItem.Title
                  style={{
                    fontSize: 20,
                    fontFamily: mainFAQ.id === mainFAQExpanded ? 'FSJoeyMedium' : 'FSJoey',
                  }}
                >
                  {mainFAQ.title}
                </ListItem.Title>
              </ListItem.Content>
            }
            icon={{
              type: 'entypo',
              name: 'chevron-down',
              color: theme.colors.primary,
            }}
            expandIcon={{
              type: 'entypo',
              name: 'chevron-up',
              color: theme.colors.primary,
            }}
            isExpanded={mainFAQ.id === mainFAQExpanded}
            bottomDivider
            onPress={() => {
              const to = mainFAQExpanded !== mainFAQ.id ? mainFAQ.id : ''
              setMainFAQExpanded(to)
              setSubFAQExpanded('')
            }}
          >
            <View
              style={{
                backgroundColor: 'transparent',
                paddingBottom: 15,
                borderBottomWidth: 1,
                borderBottomColor: theme.colors.divider,
              }}
            >
              {!!mainFAQ.content && (
                <View
                  style={{
                    padding: 15,
                  }}
                >
                  <Autolink
                    text={mainFAQ.content}
                    renderText={(text) => <Text style={{ fontSize: 16 }}>{text}</Text>}
                    linkStyle={{
                      fontSize: 16,
                      color: theme.colors.primary,
                      fontFamily: 'FSJoey',
                    }}
                  />
                </View>
              )}
              {mainFAQ.items.map((subFAQ: ISubFAQ, subFAQIndex: React.Key) => (
                <ListItem.Accordion
                  bottomDivider
                  containerStyle={{
                    backgroundColor: 'transparent',
                    borderBottomWidth: 0,
                    paddingVertical: 10,
                    paddingLeft: 0,
                    alignItems: 'flex-start',
                  }}
                  key={subFAQIndex}
                  content={
                    <>
                      <Icon
                        type="entypo"
                        name="dot-single"
                        containerStyle={{
                          marginTop: -2,
                          width: 25,
                        }}
                      />
                      <ListItem.Content>
                        <ListItem.Title
                          style={{
                            fontSize: 18,
                            fontFamily: subFAQ.id === subFAQExpanded ? 'FSJoeyMedium' : 'FSJoey',
                          }}
                        >
                          {subFAQ.title}
                        </ListItem.Title>
                      </ListItem.Content>
                    </>
                  }
                  icon={{
                    type: 'entypo',
                    name: 'chevron-down',
                    color: theme.colors.primary,
                  }}
                  expandIcon={{
                    type: 'entypo',
                    name: 'chevron-up',
                    color: theme.colors.primary,
                  }}
                  isExpanded={subFAQ.id === subFAQExpanded}
                  onPress={() => {
                    const to = subFAQExpanded !== subFAQ.id ? subFAQ.id : ''
                    setSubFAQExpanded(to)
                  }}
                >
                  <View
                    style={{
                      paddingBottom: 15,
                      paddingLeft: 0,
                      borderBottomWidth: 1,
                      borderColor: theme.colors.divider,
                    }}
                  >
                    <Autolink
                      text={subFAQ.content}
                      renderText={(text) => <Text style={{ fontSize: 16 }}>{text}</Text>}
                      linkStyle={{
                        fontSize: 16,
                        color: theme.colors.primary,
                        fontFamily: 'FSJoey',
                      }}
                    />
                  </View>
                </ListItem.Accordion>
              ))}
            </View>
          </ListItem.Accordion>
        </View>
      ))}
    </View>
  )
}

export default FAQListItem
