import { firebaseFunction } from '../../api/firebase'
import { xConsole } from '../../plugins/helpers/xConsole'
import { IApiResponse } from '../../types'

// Firebase
import { auth } from '../../api/firebase'

async function verifyPassword(password: string): Promise<IApiResponse> {
  try {
    try {
      const currentUser = auth.currentUser
      if (currentUser) {
        const res = await firebaseFunction({
          endpoint: '/verifyPassword',
          payload: { password, uid: currentUser.uid },
        })
        if (res.status === 200 && res.data.success === 'CORRECT_PASSWORD') {
          return { statusText: 'SUCCESS', status: 200 } as IApiResponse
        }
      }
    } catch (error) {
      xConsole().error(error as Error, 'verifyPassword.ts (API)')
    }
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'verifyPassword.ts (Main)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default verifyPassword
