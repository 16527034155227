/*
  Request notification permission
  Params: null
  Return: nothing
*/
import { Platform } from 'react-native'
import getDeviceId from './getDeviceId'
import Constants from 'expo-constants'
import * as Notifications from 'expo-notifications'
import { StackActions } from '@react-navigation/native'

// Firebase
import { usersCol } from '../../api/firebase'
import { doc, setDoc } from 'firebase/firestore'

// Helpers
import { xConsole } from './xConsole'

// Types
import { IChannel } from '../../types'
import { Routes } from '../../config/routes'

const isNotificationSupported = () => {
  return !(
    Platform.OS === 'web' ||
    Constants.appOwnership === 'expo' ||
    Constants.sessionId === 'mock'
  )
}

const requestNotificationPermission = async (userId: string) => {
  try {
    if (!isNotificationSupported()) {
      return false
    }
    const messaging = require('@react-native-firebase/messaging').default
    const authStatus = await messaging().requestPermission()
    const enabled =
      authStatus === messaging.AuthorizationStatus.AUTHORIZED ||
      authStatus === messaging.AuthorizationStatus.PROVISIONAL
    if (enabled) {
      const token = await messaging().getToken()
      const deviceId = await getDeviceId()
      if (userId && token && deviceId) {
        const docRef = doc(usersCol, userId)
        await setDoc(
          docRef,
          { devices: { [deviceId]: { id: deviceId, token: token, type: Platform.OS } } },
          { merge: true }
        )
        messaging().onTokenRefresh(async (token) => {
          await setDoc(
            docRef,
            { devices: { [deviceId]: { id: deviceId, token: token, type: Platform.OS } } },
            { merge: true }
          )
        })
      }
    }
  } catch ({ message }) {}
}

const onNotificationReceived = () => {
  let unsubscribe = () => {}
  try {
    if (isNotificationSupported()) {
      const messaging = require('@react-native-firebase/messaging').default
      unsubscribe = messaging().onMessage(async (remoteMessage) => {
        toast.hideAll()
        toast.show(JSON.stringify(remoteMessage), { type: 'newMessage' })
      })
    }
  } catch ({ message }) {}
  return unsubscribe
}

const onNotificationOpenedApp = (navigationRef, store) => {
  let unsubscribe = () => {}
  try {
    if (isNotificationSupported()) {
      const messaging = require('@react-native-firebase/messaging').default
      unsubscribe = messaging().onNotificationOpenedApp((remoteMessage) => {
        if (remoteMessage?.data?.channelId && navigationRef && store) {
          try {
            const { channels } = store.getState().channels
            const channel = channels.find((c: IChannel) => c.id === remoteMessage.data.channelId)
            if (channel) {
              navigationRef.dispatch(
                StackActions.push(Routes.Chat, {
                  item: {
                    id: channel.id,
                    title: channel.title,
                    description: channel.subtitle,
                    photoURL: channel.photoURL,
                    statusColor: channel.statusColor,
                    isAnonymous: channel.isAnonymous,
                  },
                })
              )
            } else {
              navigationRef.navigate(Routes.Channels)
            }
          } catch (error) {
            xConsole().error(error as Error, 'notifications.ts (onNotificationOpenedApp)')
          }
        }
      })
      // Go to channel overview when opened from quit state https://rnfirebase.io/messaging/notifications
      messaging()
        .getInitialNotification()
        .then((remoteMessage) => {
          if (remoteMessage && remoteMessage.data?.channelId && navigationRef) {
            let i = 0
            const interval = setInterval(() => {
              i++
              if (navigationRef.isReady() || i >= 40) {
                navigationRef.navigate(Routes.Channels)
                clearInterval(interval)
              }
            }, 500)
          }
        })
    }
  } catch ({ message }) {}
  return unsubscribe
}

const setNotificationBadge = (total: number) => {
  try {
    if (isNotificationSupported()) {
      Notifications.setBadgeCountAsync(total)
    }
  } catch (error) {
    xConsole().error(error as Error, 'notifications.ts (setNotificationBadge)')
  }
}

const deleteNotificationToken = async () => {
  try {
    if (isNotificationSupported()) {
      const messaging = require('@react-native-firebase/messaging').default
      await messaging().deleteToken()
    }
  } catch ({ message }) {}
}

export {
  requestNotificationPermission,
  onNotificationReceived,
  onNotificationOpenedApp,
  setNotificationBadge,
  isNotificationSupported,
  deleteNotificationToken,
}
