/*
  Format / prepare channel data safely
  Params: {} (obj)(required)
  Return: {} (obj)
*/
import { xConsole } from './xConsole'

const formatChannelData = (obj: any) => {
  // Default return
  const defaults: { [key: string]: string | number | Array<any> | object | boolean } = {
    id: '',
    key: '',
    title: '',
    subtitle: '',
    photoURL: '',
    lastMessage: '',
    lastMessageDate: 0,
    lastMessageFromNow: '',
    isRead: false,
    isAnonymous: false,
    status: '',
    statusColor: '',
    users: {},
    reference: {},
    disabled: false,
  }
  // Rules: check types by Object.prototype.toString.call
  interface IRules {
    [key: string]: string
  }
  const rules: IRules = {
    id: '[object String]',
    key: '[object String]',
    title: '[object String]',
    subtitle: '[object String]',
    photoURL: '[object String]',
    lastMessage: '[object String]',
    lastMessageDate: '[object Number]',
    lastMessageFromNow: '[object String]',
    isRead: '[object Boolean]',
    isAnonymous: '[object Boolean]',
    status: '[object String]',
    statusColor: '[object String]',
    users: '[object Object]',
    reference: '[object Object]',
    disabled: '[object Boolean]',
  }
  try {
    // Verify
    Object.entries(obj).forEach(([key, val]: [string, any]) => {
      if (key in defaults && key in rules) {
        if (Object.prototype.toString.call(val) === rules[key]) {
          defaults[key] = val
        }
      }
    })
    return defaults
  } catch (error) {
    xConsole().error(error as Error, 'formatChannelData.ts (Main)')
    return defaults
  }
}

export default formatChannelData
