import { View, ScrollView } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Components
import ScreenTitle from '../../components/ScreenTitle'
import SettingsMenus from '../../components/settings/Menus'
import SettingsFooter from '../../components/settings/Footer'
import TabMenus from '../../components/TabMenus'
import CopyDataButton from '../../components/CopyDataButton'

const SettingsScreen = () => {
  const allState = useSelector((state: RootState) => state)
  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ScreenTitle title="Profil og innstillinger" />
      <ScrollView>
        <SettingsMenus />
        <CopyDataButton
          data={allState}
          containerStyle={{ paddingLeft: 20 }}
          text="(Copy all data from this user)"
        />
      </ScrollView>
      <SettingsFooter />
      <TabMenus />
    </SafeAreaView>
  )
}

export default SettingsScreen
