import React from 'react'
import { StyleSheet, View } from 'react-native'

// Components
import { Avatar } from 'react-native-gifted-chat'
// import Avatar from '../Avatar'

// Themes
import theme from '../../config/themes'

// Types
import type { AvatarProps, IMessage } from 'react-native-gifted-chat'
type ChatAvatarProps = AvatarProps<IMessage> & {
  isAnonymous: boolean
}

function ChatAvatar(props: ChatAvatarProps) {
  const { isAnonymous } = props
  return (
    <Avatar
      {...props}
      imageStyle={{
        left: {
          ...styles.image,
          ...{
            backgroundColor: !isAnonymous ? '#666666' : '#1d1d1d',
          },
        },
      }}
      textStyle={styles.text}
    />
  )
}

export default ChatAvatar

const styles = StyleSheet.create({
  container: {},
  image: {
    width: 28,
    height: 28,
    backgroundColor: '#666666',
    marginBottom: 2,
  },
  text: {
    fontFamily: 'FSJoeyMedium',
  },
})
