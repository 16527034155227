import React, { useState, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Overlay, Text, Icon, Input } from '@rneui/themed'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { fetchUser } from '../../slices/userSlice'

// APIs
import verifyPassword from '../../api/auth/verifyPassword'
import deleteAccount from '../../api/auth/deleteAccount'

// Themes
import theme from '../../config/themes'

const SettingsDeleteAccountButton = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  if (!user) {
    return null
  }

  const openDeleteAccount = () => {
    setIsActive(true)
    // organizationSelectorRef.current?.setIsActive(true)
  }

  const onChangeText = (s: string) => {
    setPassword(s)
  }

  const doDeleteAccount = async () => {
    setIsLoading(true)
    setIsPasswordValid(true)
    if (user.hasPassword && !password) {
      setIsPasswordValid(false)
    }
    if (user.hasPassword) {
      const verifyPasswordRes = await verifyPassword(password)
      if (verifyPasswordRes.status === 200) {
        setIsPasswordValid(true)
      } else {
        setIsPasswordValid(false)
      }
    }
    if (isPasswordValid) {
      const res = await deleteAccount()
      if (res.status === 200) {
        toast.show('Vi skal slette konto i 30 dager.')
        dispatch(fetchUser())
        setIsActive(false)
      } else {
        toast.show('Beklager, noe gikk galt.')
      }
    }
    setIsLoading(false)
  }

  return (
    <>
      <Button
        title="SLETT KONTO"
        onPress={openDeleteAccount}
        disabled={!!user.deleteAccount}
        disabledStyle={{ backgroundColor: theme.colors.primary, opacity: 0.5 }}
        disabledTitleStyle={{ color: theme.colors.white }}
      />
      <Overlay
        isVisible={isActive}
        onBackdropPress={() => {
          if (!isLoading) {
            setIsActive(false)
          }
        }}
        overlayStyle={{
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 20,
          paddingHorizontal: 40,
        }}
      >
        <Icon name="sad" type="ionicon" color={theme.colors.black} size={60} />
        <Text h4 style={{ marginVertical: 15, textAlign: 'center' }}>
          Er du sikker på at du vil slette kontoen din?
        </Text>
        <Text style={{ marginVertical: 15, textAlign: 'center' }}>
          Denne handlingen kan ikke reverseres.
        </Text>
        {user.hasPassword && (
          <Input
            placeholder={'Bekreft passord'}
            containerStyle={styles.mainInputContainer}
            inputContainerStyle={styles.inputContainer}
            inputStyle={styles.input}
            errorStyle={styles.inputErrorText}
            errorMessage={!isPasswordValid ? 'Feil passord, prøv igjen.' : ''}
            onChangeText={onChangeText}
            secureTextEntry
            disabled={isLoading}
          />
        )}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            title="Slett konto"
            onPress={doDeleteAccount}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
            disabledStyle={{ opacity: 0.25 }}
            disabled={isLoading}
          />
          <Button
            title="Avbryt"
            onPress={() => {
              setIsActive(false)
            }}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
            disabledStyle={{ opacity: 0.25 }}
            disabled={isLoading}
          />
        </View>
      </Overlay>
    </>
  )
}

export default SettingsDeleteAccountButton

const styles = StyleSheet.create({
  mainInputContainer: {
    width: 300,
    paddingHorizontal: 0,
  },

  inputLabel: {
    fontFamily: 'FSJoeyMedium',
  },
  inputContainer: {
    borderColor: theme.colors.light,
  },
  input: {
    fontSize: 16,
    minHeight: 35,
    textAlign: 'center',
  },
  inputErrorText: {
    color: theme.colors.primary,
    fontSize: 9,
    marginLeft: 0,
    height: 10,
    textAlign: 'center',
  },
})
