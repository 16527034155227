import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Dimensions, RefreshControl } from 'react-native'
import { RecyclerListView, DataProvider, LayoutProvider } from 'recyclerlistview'
import { debounce } from 'lodash'
import { xConsole } from '../../plugins/helpers/xConsole'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../store/rootReducer'
import { fetchChannels } from '../../slices/channelsSlice'

// Components
import SearchChannels from '../../components/channels/Search'
import ChannelItem from '../../components/channels/Item'
import ChannelSettingsOverlay from '../../components/channels/Settings/Overlay'
import ChannelEmpty from '../../components/channels/Empty'
import CopyDataButton from '../../components/CopyDataButton'

// Types
import { IChannel, IChannelSettingsOverlayRef, RootNavigatorParamList } from '../../types'
import { Routes } from '../../config/routes'

// Variables
const { width } = Dimensions.get('window')

const ListChannels = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const channelSettingsOverlayRef = useRef<IChannelSettingsOverlayRef>(null)

  // State: Channels
  const {
    channels,
    isLoading: isApiLoading,
    loadingPercentage: apiLoadingPercentage,
  } = useSelector((state: RootState) => state.channels)
  const [channelsFiltered, setChannelsFiltered] = useState<IChannel[]>([])

  const updateChannels = async () => {
    dispatch(fetchChannels())
  }

  useEffect(() => {
    updateChannels()
  }, [])

  const connectChannel = (item: IChannel) => {
    navigation.navigate(Routes.Chat, {
      item: {
        id: item.id,
        title: item.title,
        description: item.subtitle,
        photoURL: item.photoURL,
        statusColor: item.statusColor,
        isAnonymous: item.isAnonymous,
      },
    })
  }

  const openChannelSettings = (id: string) => {
    if (!channelSettingsOverlayRef.current) {
      return
    }
    channelSettingsOverlayRef.current.setIsActive(true, id)
  }

  const dataProvider = new DataProvider((r1, r2) => {
    return r1 !== r2
  }).cloneWithRows(channelsFiltered.length ? channelsFiltered : channels)

  const _layoutProvider = new LayoutProvider(
    (index) => {
      return index
    },
    (type, dim) => {
      dim.width = width
      dim.height = 73
    }
  )

  const rowRenderer = (type: any, data: IChannel) => {
    return (
      <ChannelItem
        item={data}
        onPress={() => connectChannel(data)}
        onLongPress={() => openChannelSettings(data.id)}
      />
    )
  }

  const onSearch = useCallback(
    debounce((s: string) => {
      try {
        if (s.length) {
          const result = channels.filter(
            (a) => !!a.title && a.title.toLowerCase().includes(s.toLowerCase())
          )
          setChannelsFiltered(result)
        } else {
          setChannelsFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'channels/List.tsx (onSearch)')
      }
    }, 1000),
    [channels]
  )
  return (
    <>
      <CopyDataButton data={channels} containerStyle={{ paddingLeft: 20, marginTop: -10 }} />
      <SearchChannels
        onSearch={(s: string) => {
          onSearch(s)
        }}
      />
      {dataProvider && dataProvider.getSize() > 0 ? (
        <RecyclerListView
          testID="recyclerlistview"
          dataProvider={dataProvider}
          layoutProvider={_layoutProvider}
          rowRenderer={rowRenderer}
          canChangeSize={true}
          scrollViewProps={{
            refreshControl: <RefreshControl refreshing={isApiLoading} onRefresh={updateChannels} />,
          }}
        />
      ) : (
        <ChannelEmpty loading={isApiLoading} loadingPercentage={apiLoadingPercentage} />
      )}
      <ChannelSettingsOverlay ref={channelSettingsOverlayRef} />
    </>
  )
}

export default ListChannels
