/*
  Get device id
  Params: null
  Return: id(string) || null
*/
import { Platform } from 'react-native'
import * as Application from 'expo-application'
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { xConsole } from './xConsole'

const getDeviceId = async () => {
  let id: string | null = null
  try {
    if (Platform.OS === 'android') {
      id = Application.androidId
    } else if (Platform.OS === 'ios') {
      id = await Application.getIosIdForVendorAsync()
    } else if (Platform.OS === 'web') {
      const fp = await FingerprintJS.load()
      const fpResult = await fp.get()
      id = fpResult.visitorId
    }
  } catch (error) {
    xConsole().error(error as Error, 'getDeviceId.ts (Main)')
  }
  return id
}

export default getDeviceId
