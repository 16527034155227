/*
  Check if channel disabled like leave channel / noone in channel so hide input
  Params: currentUserId(string), channels(array)(required)
  Return: boolean
*/

import { IChannel } from '../../types'
import { xConsole } from './xConsole'

const checkChannelDisabled = (currentUserId: string, channel: IChannel): boolean => {
  let isChannelDisabled = false
  try {
    const otherMembers = Object.entries(channel.users).filter(
      ([k, v]) => k !== currentUserId && v.uid !== currentUserId && v.permission === true
    )
    // Conditions
    if (otherMembers.length === 0) {
      isChannelDisabled = true
    }
    return isChannelDisabled
  } catch (error) {
    xConsole().error(error as Error, 'checkChannelDisabled.ts (Main)')
    return isChannelDisabled
  }
}

export default checkChannelDisabled
