// Firebase
import { channelsCol } from '../../api/firebase'
import { doc, setDoc, getDoc } from 'firebase/firestore'

// Helpers
import getServerTime from '../../plugins/helpers/getServerTime'
import getApiUsers from '../../api/users/getApiUsers'
import createApiMessage from '../../api/messages/createApiMessage'
import { xConsole } from '../../plugins/helpers/xConsole'

// Types
import { IChannel, IApiResponse } from '../../types'
interface ILeaveChannelArgs {
  userId: string
  realUserId: string
  channel: IChannel
}

async function leaveApiChannel(args: ILeaveChannelArgs): Promise<IApiResponse> {
  try {
    const serverTime = await getServerTime()
    const docRef = doc(channelsCol, args.channel.id)
    const docSnap = await getDoc(docRef)
    if (!docSnap.exists()) {
      return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
    }
    const docData = docSnap.data()
    const isAnonymous = args.userId.length < 21

    /* =================== Get fullName ==================== */
    let fullName: string = args.userId
    try {
      if (!isAnonymous) {
        const res = await getApiUsers([args.userId])
        if (res.data && res.data[args.userId]) {
          const user = res.data[args.userId]
          fullName = user.fullName
        }
      }
    } catch ({ message }) {}

    /* ================ Send: System message ================= */
    const res = await createApiMessage({
      id: args.channel.id,
      userId: args.userId,
      message: JSON.stringify({
        userId: args.userId,
        fullName: fullName,
      }),
      lastMessage: `${fullName} forlot samtalen`,
      type: 'system',
      systemType: 'leave',
      key: args.channel.key,
      createdOn: serverTime.timestamp - 1,
      json: true,
    })
    if (res.status !== 200) {
      return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
    }

    /* = Leave channel by setting permission = false in channel's collection = */
    await setDoc(
      docRef,
      {
        users: {
          [args.realUserId]: { permission: false },
        },
      },
      { merge: true }
    )

    return {
      statusText: 'SUCCESS',
      status: 200,
      data: { id: args.channel.id, isAnonymous: docData.isAnonymous },
    } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'leaveApiChannels.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default leaveApiChannel
