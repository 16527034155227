import { firebaseFunction, auth } from '../../api/firebase'
import { xConsole } from './xConsole'

/*
  Get channel keys (all)
  Params: null
  Return: key(string)
*/
const getChannelKeys = async () => {
  try {
    const user = auth.currentUser
    if (user) {
      const token = await user.getIdToken(true)
      const res = await firebaseFunction({
        endpoint: '/channelKeys',
        payload: { token: token },
      })
      if (res.status === 200) {
        global.preChannelKeys = res.data.channels
      }
    }
  } catch (error) {
    xConsole().error(error as Error, 'getChannelKeys.ts (Main)')
  }
}

export default getChannelKeys
