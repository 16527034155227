import moment from 'moment'
import 'moment/locale/nb'
import formatChannelData from '../../plugins/helpers/formatChannelData'
import decryptMessage from '../../plugins/helpers/decryptMessage'
import getChannelKey from '../../plugins/helpers/getChannelKey'
import getChannelUsers from '../../plugins/helpers/getChannelUsers'
import getChannelCurrentUserId from '../../plugins/helpers/getChannelCurrentUserId'
import checkChannelDisabled from '../../plugins/helpers/checkChannelDisabled'
import { xConsole } from '../../plugins/helpers/xConsole'

// import faker from '@faker-js/faker'

// Helpers
import { setNotificationBadge } from '../../plugins/helpers/notifications'

// Firebase
import { auth, channelsCol } from '../../api/firebase'
import { doc, onSnapshot, getDoc, query, where } from 'firebase/firestore'

// Types
import { IChannel } from '../../types'
interface IGetApiChannelsArgs {
  userId: string
  getState: Function
  updateChannels: Function
  updateUsers: Function
  // deleteAnonymousChannel: Function // @ANO-UNCOMMENT
  // getAnonymousChannelKey: Function // @ANO-UNCOMMENT
  updateLoadingPercentage: Function
}

moment.locale('nb')

async function getApiChannels(args: IGetApiChannelsArgs) {
  try {
    const q = query(channelsCol, where(`users.${args.userId}.permission`, '==', true))
    const currentUser = auth.currentUser
    if (!currentUser) {
      // Throw error if no currentUser found
      throw new Error('NO_CURRENTUSER_FOUND')
    }
    const token = await currentUser.getIdToken(true)
    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      /* ====== Prepare data ===== */
      const nowMoment = moment()
      const docs: Array<any> = querySnapshot.docs
      let state = args.getState()
      const prepareData = {
        docs: [] as any,
        users: new Set(),
      }

      /* == Anonymous channels (Self made channels) == */
      /* @ANO-UNCOMMENT
      const { anonymousChannels } = state.channels
      for (const [anonymousChannelId] of Object.entries(anonymousChannels)) {
        try {
          const docRef = doc(channelsCol, anonymousChannelId)
          const docSnap = await getDoc(docRef)
          if (!docs.some((a) => a.id === docSnap.id)) {
            docs.push(docSnap)
          }
        } catch (error) {
          args.deleteAnonymousChannel(anonymousChannelId) // Delete from anonymous channels state
          xConsole().error(
            error as Error,
            'getApiChannels.ts (Anonymous channels – State loop & Firestore docs)'
          )
        }
      }
      */

      /* === Overall channels === */
      for (const doc of docs) {
        // Collect all users in all channels
        const docData = doc.data()
        Object.keys(docData.users).forEach((user) => {
          if (user.length > 10) {
            // Prevent adding fake anonymous users by id length > 10
            prepareData.users.add(user)
          }
        })
        prepareData.docs.push(docData)
      }

      /* ====== Get states ====== */
      await args.updateUsers([...prepareData.users])
      state = args.getState() // Get State again
      const { device } = state.user
      const { keys: prevKeys } = state.channels
      const { users: prevUsers } = state.users
      let notificationBadgeCount = 0

      /* ====== Format data ===== */
      const channels: IChannel[] = []
      for (const [docDataIndex, docData] of prepareData.docs.entries()) {
        /* ==============================
        ::: Update loading percentage :::
        ============================== */
        args.updateLoadingPercentage((docDataIndex * 100) / prepareData.docs.length)

        /* ==============================
        ::::::: Get Channel Key :::::::
        ============================== */
        docData.key = ''
        if (token) {
          docData.key = await getChannelKey(docData.id, token, prevKeys)
          if (docData.isAnonymous) {
            for (const [k, v] of Object.entries(docData.users)) {
              if (k.length < 21) {
                docData.key = '' // Remove invaild key for old anonymous channels
              }
            }
          }
        }
        /* @ANO-UNCOMMENT
        else if (docData.isAnonymous) {
          if (!anonymousChannels[docData.id]) {
            // Get key in pendingAnonymous
            docData.key = await args.getAnonymousChannelKey(docData.id)
          } else {
            // Get key from storage(state)
            docData.key = anonymousChannels[docData.id]
          }
        }
        */
        if (!docData.key) {
          // No key, do not continue
          continue
        }
        /* ==============================
        :: Get isRead :::::::::::::::::
        ============================== */
        docData.isRead = true
        try {
          const realUserId = getChannelCurrentUserId({ channel: docData, realUserId: args.userId })
          const currentUser = docData.users[realUserId]
          const conditionA =
            docData.lastMessage.uid !== realUserId &&
            currentUser.lastViewed &&
            docData.lastMessage.createdOn &&
            docData.lastMessage.createdOn > currentUser.lastViewed
          const conditionB = !currentUser.lastViewed && docData.lastMessage.uid !== realUserId
          docData.isRead = !(conditionA || conditionB)
        } catch ({ message }) {}
        /* ==============================
        :: Notification badge count :::::::::::::::::
        ============================== */
        if (!docData.isRead && docData.key) {
          notificationBadgeCount++
        }
        /* ==============================
        :: Get reference :::::::::::::::::
        ============================== */
        try {
          if (docData.reference) {
            if (docData.reference.helpers && Array.isArray(docData.reference.helpers)) {
              // Format old data (helpers(array) -> from)
              docData.reference.from = docData.reference.helpers[0]
              delete docData.reference.helpers
            }
          }
        } catch ({ message }) {}
        /* ==============================
        :: Get lastMessage date :::::::::
        ============================== */
        try {
          docData.lastMessageDate = docData.lastMessage?.createdOn // Set lastMessageDate
          if (docData.lastMessageDate) {
            const date = moment(docData.lastMessageDate)
            if (nowMoment.format('YYYY') !== nowMoment.format('YYYY')) {
              // Show month name and year : (Diff year)
              docData.lastMessageFromNow = date.format('MMM YYYY')
            } else if (nowMoment.diff(date, 'hours') >= 24) {
              // Show month day and month : (Diff day)
              docData.lastMessageFromNow = date.format('D MMM')
            } else {
              // Show hour and minute : (Today)
              docData.lastMessageFromNow = date.format('HH:mm')
            }
          }
        } catch ({ message }) {}

        /* ==============================
        ::: Get Decrypt lastMessage :::
        ============================== */
        docData.lastMessage = docData.lastMessage?.content
          ? decryptMessage({
              content: docData.lastMessage.content,
              key: docData.key,
            })
          : ''
        /* ==============================
        :: Get Channel Users data ::::::
        ============================== */
        const channelUsers = await getChannelUsers({
          users: docData.users,
          currentUserId: currentUser.uid,
          prevUsers: prevUsers,
          isAnonymous: docData.isAnonymous,
          timeOffset: device.timeOffset,
        })
        /* ==============================
        :: Get Channel Title ::::::::::::
        ============================== */
        docData.title = docData.title || channelUsers.title
        /* ==============================
        :: Get Channel Subtitle :::::::::
        ============================== */
        docData.subtitle = docData.subtitle || channelUsers.subtitle
        /* ==============================
        :: Get Avatar :::::::::::::::::::
        ============================== */
        docData.photoURL = docData.photoURL || channelUsers.photoURL
        /* ==============================
        :: Get Status/Color :::::::::::::
        ============================== */
        docData.status = channelUsers.status
        docData.statusColor = channelUsers.statusColor
        /* ==============================
        :: Check channel disbled ::::::::
        ============================== */
        docData.disabled = checkChannelDisabled(currentUser.uid, docData)

        // Format data safely
        const formattedChannelData = formatChannelData(docData)
        const channelData = {
          ...{
            id: '',
            key: '',
            title: '',
            subtitle: '',
            photoURL: '',
            lastMessage: '',
            lastMessageDate: 0,
            lastMessageFromNow: '',
            status: 'offline',
            statusColor: '',
            isRead: true,
            isAnonymous: false,
            users: {},
            reference: {},
            disabled: false,
          },
          ...formattedChannelData,
        } as IChannel
        // channelData.lastMessageDate = docData.lastMessage?.createdOn || 0
        if (channelData.key) {
          // If key exists, push to store
          channels.push(channelData)
        }
      }

      // Dummy data
      /*
      for (let i = 0; i < 1000; i++) {
        const lastMessageDate = moment().subtract(
          faker.datatype.number({
            min: 1,
            max: 50000,
          }),
          'minutes'
        )
        const data: IChannel = {
          id: `${i}`, // faker.datatype.uuid()
          key: '',
          title: faker.name.findName(),
          lastMessage: faker.lorem.sentences(2),
          lastMessageDate: Number(lastMessageDate.format('x')),
          photoURL: `https://i.pravatar.cc/200?u=${faker.datatype.uuid()}`,
          status: faker.helpers.randomize([
            'online',
            'busy',
            'offline',
            'offline',
            'offline',
            'offline',
          ]),
          isRead: Math.random() > 0.1,
          isAnonymous: Math.random() > 0.9,
          users: {},
        }
        channels.push(data)
      }
      */

      channels.sort((a, b) => {
        return (b.lastMessageDate || 0) - (a.lastMessageDate || 0)
      })
      setNotificationBadge(notificationBadgeCount)
      args.updateChannels(channels, unsubscribe)
    })
  } catch (error) {
    xConsole().error(error as Error, 'getApiChannels.ts (Main)')
  }
}

export default getApiChannels
