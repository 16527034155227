/*
  Format / prepare user data safely
  Params: {} (obj)(required)
  Return: {} (obj)
*/
import { xConsole } from './xConsole'

const formatUserData = (obj: any) => {
  // Default return
  const defaults: { [key: string]: string | number | Array<any> | boolean } = {
    id: '',
    fullName: '',
    title: '',
    description: '',
    phoneNumber: '',
    photoURL: '',
    organizations: [],
    lastSeen: 0,
    status: '',
    statusColor: '',
    customStatus: '',
  }
  // Rules: check types by Object.prototype.toString.call
  interface IRules {
    [key: string]: string
  }
  const rules: IRules = {
    id: '[object String]',
    fullName: '[object String]',
    title: '[object String]',
    description: '[object String]',
    phoneNumber: '[object String]',
    photoURL: '[object String]',
    organizations: '[object Array]',
    lastSeen: '[object Number]',
    status: '[object String]',
    statusColor: '[object String]',
    customStatus: '[object String]',
  }
  try {
    // Convert old Firebase data to new Structure
    if (obj.uid) {
      obj.id = obj.uid
    }
    if (obj.displayName) {
      obj.fullName = obj.displayName
    }
    if (!obj.fullName) {
      obj.fullName = `${obj.firstName || ''} ${obj.lastName || ''}`.trim()
    }
    if (obj.photoURL && obj.photoURL.includes('adorable.io')) {
      obj.photoURL = ''
    }
    if (obj.institutions) {
      obj.organizations = obj.institutions
    }
    if (obj.subtitle) {
      obj.title = obj.subtitle
    }
    if (obj.onlineStatus) {
      obj.customStatus = obj.onlineStatus
    }

    // Verify
    Object.entries(obj).forEach(([key, val]: [string, any]) => {
      if (key in defaults && key in rules) {
        if (Object.prototype.toString.call(val) === rules[key]) {
          defaults[key] = val
        }
      }
    })
    return defaults
  } catch (error) {
    xConsole().error(error as Error, 'formatUserData.ts (Main)')
    return defaults
  }
}

export default formatUserData
