import React from 'react'
import { View } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenTitle from '../../components/ScreenTitle'
import ListContacts from '../../components/contacts/user/List'

const UserContactsScreen = () => {
  return (
    <View style={{ flex: 1 }}>
      <View>
        <ScreenTitle title="Dine tjenester" />
        <Text style={{ marginHorizontal: 20, fontSize: 20 }}>
          Her finner du dine hjelpere. Send oss en melding om du lurer på noe.
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <ListContacts />
      </View>
    </View>
  )
}

export default UserContactsScreen
