import React from 'react'
import { StyleSheet, StyleProp, ImageStyle } from 'react-native'
import { Avatar as RNEUIAvatar, AvatarProps as RNEUIAvatarProps } from '@rneui/themed'

// Types
type AvatarProps = {
  photoURL: string | undefined
  placeholderTitle?: string
  props?: RNEUIAvatarProps
  statusColor?: string
  statusSize?: number
  statusStyle?: StyleProp<ImageStyle>
  isAnonymous?: boolean
}
const Avatar = ({
  photoURL = 'no-image',
  placeholderTitle = '',
  statusColor,
  props,
  statusSize = 10,
  statusStyle,
  isAnonymous = false,
}: AvatarProps) => {
  return (
    <RNEUIAvatar
      title={placeholderTitle.charAt(0).toUpperCase()}
      source={{ uri: photoURL || 'no-image' }}
      size={'medium'}
      rounded
      placeholderStyle={{
        backgroundColor: !isAnonymous ? '#f8f8f8' : '#2b2c2b',
      }}
      titleStyle={{ color: !isAnonymous ? '#2b2c2b' : '#ffffff' }}
      {...props}
    >
      {!!statusColor && (
        <RNEUIAvatar.Accessory
          size={statusSize}
          name=""
          style={StyleSheet.flatten([
            {
              borderRadius: 20,
              backgroundColor: statusColor,
              marginRight: 1,
              marginBottom: 0,
              shadowOpacity: 0,
            },
            statusStyle,
          ])}
        />
      )}
    </RNEUIAvatar>
  )
}

export default Avatar
