import React, { useState, useRef } from 'react'
import { StyleSheet, Platform, View } from 'react-native'
import { Text, Input, CheckBox } from '@rneui/themed'

// Helpers
import formatPhoneNumber, { phoneNumberRegex } from '../../plugins/helpers/formatPhoneNumber'
import { xConsole } from '../../plugins/helpers/xConsole'

// Api
import { firebaseFunction } from '../../api'

// Themes
import theme from '../../config/themes'

// Components
import SubmitButton from './SubmitButton'
import TermsOfService from '../TermsOfService'

// Types
import { TosRef, ILoginScreenAuth } from '../../types'
type PhoneFormProps = {
  onSuccess: (auth: ILoginScreenAuth) => void
}
interface PhoneSubmitArgs {
  ignoreTerms?: boolean
}

function PhoneForm({ onSuccess }: PhoneFormProps) {
  const [phoneNumber, setPhoneNumber] = useState('')
  const [isValid, setIsValid] = useState(false)
  const TermsOfServiceRef = useRef<TosRef>(null)
  const [isAgreedTerms, setIsAgreedTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Validation
  const onChangeText = (num: string) => {
    const formattedPhoneNumber = num.replace(/ +/g, '').replace('+47', '')
    setPhoneNumber(formattedPhoneNumber)
    setIsValid(!!formattedPhoneNumber && phoneNumberRegex.test(formattedPhoneNumber))
  }

  // Submit
  const onSubmit = async (args: PhoneSubmitArgs = { ignoreTerms: false }) => {
    toast.hideAll()
    if (!isValid) {
      toast.show('Skriv inn riktig telefonnummer for å logge inn.', {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
      return
    }
    if (args.ignoreTerms) {
      setIsAgreedTerms(true)
    }
    if (!args.ignoreTerms && !isAgreedTerms) {
      TermsOfServiceRef.current?.setIsActive(true)
      return
    }

    try {
      setIsLoading(true)
      const res = await firebaseFunction({
        endpoint: '/auth',
        payload: { phoneNumber: formatPhoneNumber(phoneNumber) },
      })
      setIsLoading(false)
      if (res.data) {
        if (res.data.success === 'SENT_CODE') {
          toast.show(`Oppgi koden vi sendte til\n${formatPhoneNumber(phoneNumber)}`, {
            type: 'main',
            data: {
              title: 'Topp!',
              icon: 'success',
            },
          })
          onSuccess({ phoneNumber: phoneNumber })
        } else if (res.data.error === 'TOO_MANY_ATTEMPTS') {
          toast.show('Du har prøvd mange ganger, kontakt oss!', {
            type: 'main',
            data: {
              title: 'Obs!',
              icon: 'error',
            },
          })
        }
      } else {
        toast.show('Beklager, noe gikk galt.', {
          type: 'main',
          data: {
            title: 'Obs!',
            icon: 'error',
          },
        })
      }
    } catch (error: any) {
      toast.show(error.message, {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
      xConsole().error(error as Error, 'PhoneForm.tsx (onSubmit)')
    }
  }

  return (
    <View>
      <Input
        placeholder="Fyll inn ditt mobilnummer"
        keyboardType="phone-pad"
        containerStyle={styles.inputContainer}
        inputStyle={styles.input}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeText}
        onSubmitEditing={() => {
          onSubmit()
        }}
        disabledInputStyle={{ opacity: 1 }}
        disabled={isLoading}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
        autoComplete="tel-device"
        textContentType="telephoneNumber"
        value={phoneNumber}
      />
      <SubmitButton title="LOGG INN" onPress={onSubmit} loading={isLoading} valid={isValid} />
      <CheckBox
        title={
          <Text
            style={{
              fontSize: 18,
              paddingLeft: 5,
              paddingRight: 5,
              textDecorationLine: 'underline',
            }}
            onPress={() => {
              TermsOfServiceRef.current?.setIsActive(true)
            }}
          >
            Jeg har lest og godtar vilkår
          </Text>
        }
        checked={isAgreedTerms}
        onPress={() => setIsAgreedTerms(!isAgreedTerms)}
        iconType="ionicon"
        checkedIcon={'checkmark-circle-outline'}
        uncheckedIcon={'radio-button-off'}
        uncheckedColor={theme.colors.black}
        checkedColor={theme.colors.black}
        containerStyle={{
          padding: 0,
          marginTop: -5,
          alignItems: 'center',
        }}
      />
      <TermsOfService
        testID="loginTos"
        ref={TermsOfServiceRef}
        onAgreed={() => {
          setIsAgreedTerms(true)
          onSubmit({ ignoreTerms: true })
        }}
      />
    </View>
  )
}

export default PhoneForm

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
