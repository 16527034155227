import formatOtherOrganizationData from '../../plugins/helpers/formatOtherOrganizationData'
import { xConsole } from '../../plugins/helpers/xConsole'

// Firebase
import { institutionsCol } from '../../api/firebase'
import { getDocs, query, where } from 'firebase/firestore'

// Types
import { IApiResponse, IOrganizationOther } from '../../types'

/**
 * Get all other organizations
 */
async function getApiOtherOrganizations(): Promise<IApiResponse> {
  try {
    // Variables
    const organizations: IOrganizationOther[] = []
    // Init
    const q = query(institutionsCol, where('category', '==', 'Bedrift'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const docData = doc.data()
      const formattedOtherOrganizationData = formatOtherOrganizationData(docData)
      const otherOrganizationData = {
        ...formattedOtherOrganizationData,
        ...{
          type: 'item',
        },
      } as IOrganizationOther
      organizations.push(otherOrganizationData)
    })
    organizations.sort((a, b) => (b.organizationName < a.organizationName ? 1 : -1)) // Sort by ASC
    return { statusText: 'SUCCESS', status: 200, data: organizations } as IApiResponse
  } catch (error) {
    xConsole().error(error as Error, 'getApiOtherOrganizations.ts (Main - API)')
    return { statusText: 'SOMETHING_WRONG', status: 400 } as IApiResponse
  }
}

export default getApiOtherOrganizations
