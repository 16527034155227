import React, { useState } from 'react'
import { StyleSheet, View, TouchableOpacity, ActivityIndicator } from 'react-native'
import { Avatar, Icon, Button } from '@rneui/themed'
import * as ImagePicker from 'expo-image-picker'
import { useActionSheet } from '@expo/react-native-action-sheet'

// Firebase
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from 'firebase/storage'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '../../store/rootReducer'

// Helpers
import { xConsole } from '../../plugins/helpers/xConsole'

// Themes
import theme from '../../config/themes'

// Types
type SettingsAvatarUploadProps = {
  onPress?: () => void
  isOnPressShouldDoUpload?: boolean
  newUrl?: string
  onUpload?: (url: string) => void
}

const SettingsAvatarUpload = ({
  onPress,
  isOnPressShouldDoUpload = false,
  newUrl = '',
  onUpload,
}: SettingsAvatarUploadProps) => {
  const { user } = useSelector((state: RootState) => state.user)
  if (!user) {
    return null
  }
  const [isUploading, setIsUploading] = useState(false)
  const { showActionSheetWithOptions } = useActionSheet()
  const [uploadNewAvatar, setUploadNewAvatar] = useState('')

  const doOnPress = () => {
    if (onPress && !isOnPressShouldDoUpload) {
      onPress()
    } else if (isOnPressShouldDoUpload) {
      showActionSheetWithOptions(
        {
          options: ['Velg bilde', 'Ta bilde', 'Avbryt'],
          cancelButtonIndex: 2,
        },
        (buttonIndex) => {
          if (buttonIndex === 0 || buttonIndex === 1) {
            selectImage({ useCamera: buttonIndex === 1 })
          }
          // Do something here depending on the button index selected
        }
      )
    }
  }

  const selectImage = async ({ useCamera = false }) => {
    try {
      let result: any
      const ImagePickerOptions = {
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
      }
      if (!useCamera) {
        result = await ImagePicker.launchImageLibraryAsync(ImagePickerOptions)
      } else {
        const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
        if (permissionResult.granted === false) {
          alert('You have to allow accessing camera to take a picture')
          return
        }
        result = await ImagePicker.launchCameraAsync(ImagePickerOptions)
      }

      if (!result.cancelled) {
        setIsUploading(true)
        const uploadURL = await uploadImageAsync(result.uri)
        if (uploadURL) {
          const isReady = await thumbImageExists(uploadURL)
          if (isReady && !!onUpload) {
            onUpload(uploadURL)
            setIsUploading(false)
          } else {
            setIsUploading(false)
          }
        } else {
          setIsUploading(false)
        }
      }
    } catch (error) {
      xConsole().error(error as Error, 'AvatarUpload.tsx (selectImage)')
    }
  }

  const uploadImageAsync = async (uri: string) => {
    const blob = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()
      xhr.onload = function () {
        resolve(xhr.response)
      }
      xhr.onerror = function (e) {
        reject(new TypeError('Network request failed'))
      }
      xhr.responseType = 'blob'
      xhr.open('GET', uri, true)
      xhr.send(null)
    })

    const isBase64 = uri.startsWith('data:image')
    const timestamp = Number(new Date())
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const getMimTypeRegex = isBase64 ? /[^:/]\w+(?=;|,)/ : /\.([0-9a-z]+)(?:[\?#]|$)/i
    const mimeType = uri.match(getMimTypeRegex)![0].replace('.', '')

    const fileRef = ref(getStorage(), `${year}/${month}/${user.id}-${timestamp}.${mimeType}`)
    let result: any
    if (!isBase64) {
      result = await uploadBytes(fileRef, blob)
      blob.close()
    } else {
      result = await uploadString(fileRef, uri, 'data_url')
    }

    let downloadURL = await getDownloadURL(fileRef)
    if (downloadURL) {
      downloadURL = downloadURL.replace(user.id, `thumb_${user.id}`) // Thumbnail url
    }

    return downloadURL
  }

  const thumbImageExists = async (url: string) => {
    if (!url) {
      return false
    }

    return new Promise(async (res) => {
      let i = 0
      const interval = setInterval(async () => {
        i++
        const response = await fetch(`${url}?${i}`)
        if (!response.ok && i > 20) {
          clearInterval(interval)
          res(false)
        }
        if (response.ok) {
          clearInterval(interval)
          res(true)
        }
      }, 1000)
    })
  }

  return (
    <View style={styles.mainContainer}>
      {!isUploading ? (
        <Avatar
          Component={TouchableOpacity}
          title={user.fullName.charAt(0).toUpperCase()}
          source={{ uri: (newUrl ? newUrl : user.photoURL) || 'no-image' }}
          size={'large'}
          onPress={doOnPress}
          rounded
        >
          <Icon
            name="camera"
            type="feather"
            size={12}
            color={theme.colors.white}
            containerStyle={styles.uploadIconContainer}
          />
        </Avatar>
      ) : (
        <Avatar renderPlaceholderContent={<ActivityIndicator />} size={'large'} rounded></Avatar>
      )}
      <Button
        containerStyle={{
          width: 200,
        }}
        title="ENDRE PROFILBILDE"
        type="clear"
        titleStyle={styles.buttonTitle}
        onPress={doOnPress}
      />
    </View>
  )
}

export default SettingsAvatarUpload

const styles = StyleSheet.create({
  mainContainer: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  uploadIconContainer: {
    position: 'absolute',
    bottom: -1,
    right: -1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 100,
    backgroundColor: '#c6c6c6',
  },
  buttonTitle: {
    fontFamily: 'FSJoeyMedium',
    color: theme.colors.primary,
  },
})
