import React, { useState } from 'react'
import { SearchBar } from '@rneui/themed'

// Types
type SearchChannelsProps = {
  onSearch: (s: string) => void
}

const SearchChannels = ({ onSearch }: SearchChannelsProps) => {
  const [search, setSearch] = useState('')

  const updateSearch = (text: string): void => {
    setSearch(text)
    onSearch(text)
  }

  return (
    <SearchBar
      placeholder="Søk i dine samtaler"
      onChangeText={updateSearch} //  @TODO This is wack (https://github.com/react-native-elements/react-native-elements/issues/3089)
      value={search}
      containerStyle={{
        margin: 10,
        paddingHorizontal: 10,
      }}
      searchIcon={{ type: 'antdesign', name: 'search1' }}
    />
  )
}

export default SearchChannels
